import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSound } from '../contexts/SoundContext';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../contexts/SocketContext';

const circleRed = { width: '18px', height: '18px', backgroundColor: 'red', borderRadius: '50%' };
const circleBlue = { width: '18px', height: '18px', backgroundColor: 'blue', borderRadius: '50%' };
const circleGreen = { width: '18px', height: '18px', backgroundColor: 'green', borderRadius: '50%' };
const circleYellow = { width: '18px', height: '18px', backgroundColor: 'yellow', borderRadius: '50%' };
const squareRed = { width: '18px', height: '18px', backgroundColor: 'red' };
const squareBlue = { width: '18px', height: '18px', backgroundColor: 'blue' };
const squareGreen = { width: '18px', height: '18px', backgroundColor: 'green' };
const squareYellow = { width: '18px', height: '18px', backgroundColor: 'yellow' };
const rectangularRed = { width: '18px', height: '10px', backgroundColor: 'red' };
const rectangularBlue = { width: '18px', height: '10px', backgroundColor: 'blue' };
const rectangularGreen = { width: '18px', height: '10px', backgroundColor: 'green' };
const rectangularYellow = { width: '18px', height: '10px', backgroundColor: 'yellow' };
const triangleRed = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid red' };
const triangleBlue = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid blue' };
const triangleGreen = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid green' };
const triangleYellow = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid yellow' };
const MoonRed = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 red', rotate: '270deg' };
const MoonBlue = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 blue', rotate: '270deg' };
const MoonGreen = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 green', rotate: '270deg' };
const MoonYellow = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 yellow', rotate: '270deg' };

const shapesArray = [
  circleRed, circleBlue, circleGreen, circleYellow,
  squareRed, squareBlue, squareGreen, squareYellow,
  rectangularRed, rectangularBlue, rectangularGreen, rectangularYellow,
  triangleRed, triangleBlue, triangleGreen, triangleYellow,
  MoonRed, MoonBlue, MoonGreen, MoonYellow
];

// Translation object for colors and shapes
const translations = {
  en: {
    shapes: {
      circle: 'circle',
      square: 'square',
      rectangular: 'rectangular',
      triangle: 'triangle',
      Moon: 'Moon'
    },
    colors: {
      red: 'red',
      blue: 'blue',
      green: 'green',
      yellow: 'yellow'
    }
  },
  ar: {
    shapes: {
      circle: 'دائرة',
      square: 'مربع',
      rectangular: 'مستطيل',
      triangle: 'مثلث',
      Moon: 'قمر'
    },
    colors: {
      red: 'احمر',
      blue: 'ازرق',
      green: 'اخضر',
      yellow: 'اصفر'
    }
  }
};

const MemQuetion = () => {
  const { userId } = useParams();
  const lng = localStorage.getItem('language');

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10);
  const isAutoMode = queryParams.get('isAutoMode') === 'true';
  const timer = parseInt(queryParams.get('timer'), 10);
  const timerAnswer = parseInt(queryParams.get('timerAnswer'), 10);
  const totalShapes = parseInt(queryParams.get('totalShapes'), 10) || 20;
  const currentQuestion = parseInt(queryParams.get('currentQuestion'), 10) || 0;
  const { playSound } = useSound();
  const [comments, setComments] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [timerRemainingAnswer, setTimerRemainingAnswer] = useState(timerAnswer);
  const [timeFinished, setTimeFinished] = useState(false);
  const [timeAnswerFinished, setTimeAnswerFinished] = useState(false);
  const [shapesDisplayed, setShapesDisplayed] = useState([]);
  const [question, setQuestion] = useState(null);
  const [quesAnswer, setQuesAnswer] = useState(null);
  const hashtag = queryParams.get('hashtag');
  const [correctComment, setCorrectComment] = useState('');
  const { t } = useTranslation();
  const { socket } = useSocket();
  const insertOnceRef = useRef(false);
  const storageKey = `mem_${userId}`;


  // ✅ تحويل الأرقام العربية إلى إنجليزية
  const convertArabicToEnglishNumbers = (str) => {
    const arabicToEnglishMap = {
      '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
      '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
    };
    return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
  };


  // ✅ تحديد التعليق الصحيح وتحويله إلى **أحرف صغيرة** + إزالة الفراغات
  useEffect(() => {
    if (hashtag && quesAnswer) {
      const formattedCorrectComment = `${hashtag}${quesAnswer}`.toLowerCase().replace(/\s+/g, '');
      setCorrectComment(formattedCorrectComment);
      console.log("✅ Correct Comment Set:", formattedCorrectComment);
    }
  }, [hashtag, quesAnswer]);

  // ✅ إدخال البيانات إلى `localStorage` مع فلترة صحيحة
  const insertIntoLocalStorage = useCallback(() => {
    if (insertOnceRef.current) return;
    insertOnceRef.current = true;

    if (!correctComment || !quesAnswer) {
      console.error("🚨 Missing correct comment or position!");
      return;
    }

    const existingData = JSON.parse(localStorage.getItem(storageKey) || '{}');

    console.log(`🔍 Saving question ${currentQuestion} with correct comment: ${correctComment}`);

    // ✅ حفظ أول تعليق لكل مستخدم يحتوي على الهاشتاغ
    const commentsData = comments.reduce((acc, comment) => {
      let cleanedComment = convertArabicToEnglishNumbers(comment.comment.toLowerCase().replace(/\s+/g, ''));

      if (cleanedComment.includes(hashtag.toLowerCase()) && !acc[comment.userId]) {
        const isCorrect = cleanedComment === correctComment;

        acc[comment.userId] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: comment.nickname,
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
      }
      return acc;
    }, {});

    const newEntry = {
      userId,
      hashtag,

      currentQuestion,

      quesAnswer,
      correctComment,
      comments: commentsData,
    };

    existingData[currentQuestion] = newEntry;
    localStorage.setItem(storageKey, JSON.stringify(existingData));

    console.log(`✅ Stored Question ${currentQuestion}:`, newEntry);
  }, [comments, quesAnswer, hashtag, userId, storageKey, currentQuestion, correctComment]);


  useEffect(() => {
    setComments([]);
    setTimeRemaining(timer);
    setTimeFinished(false);

    // Display random shapes
    const randomShapes = Array.from({ length: totalShapes }, () => {
      const randomIndex = Math.floor(Math.random() * shapesArray.length);
      return shapesArray[randomIndex];
    });
    setShapesDisplayed(randomShapes);

    // Count shapes
    const shapeCounts = {};
    randomShapes.forEach((shape) => {
      let shapeType = '';
      let color = '';

      if (shape.borderRadius === '50%') {
        if (shape.boxShadow) {
          shapeType = 'Moon';
          color = shape.boxShadow.includes('red') ? 'red' : shape.boxShadow.includes('blue') ? 'blue' : shape.boxShadow.includes('green') ? 'green' : 'yellow';
        } else {
          shapeType = 'circle';
          color = shape.backgroundColor;
        }
      } else if (shape.width === '18px' && shape.height === '18px') {
        shapeType = 'square';
        color = shape.backgroundColor;
      } else if (shape.width === '18px' && shape.height === '10px') {
        shapeType = 'rectangular';
        color = shape.backgroundColor;
      } else if (shape.borderBottom) {
        shapeType = 'triangle';
        color = shape.borderBottom.includes('red') ? 'red' : shape.borderBottom.includes('blue') ? 'blue' : shape.borderBottom.includes('green') ? 'green' : 'yellow';
      }

      const key = `${shapeType}_${color}`;
      shapeCounts[key] = (shapeCounts[key] || 0) + 1;
    });

    // Get translations
    const { shapes, colors } = translations[lng];

    // Generate random question
    const questionKeys = Object.keys(shapeCounts);
    const randomQuestionKey = questionKeys[Math.floor(Math.random() * questionKeys.length)];
    const [shapeType, color] = randomQuestionKey.split('_');

    let questionText;
    if (lng === 'ar') {
      questionText = `${t('how_many')} ${shapes[shapeType]} ${colors[color]} ${t('do_you_Seen')}`;
    } else {
      questionText = `${t('how_many')} ${colors[color]} ${shapes[shapeType]} ${t('do_you_Seen')}`;
    }

    setQuestion(questionText);
    setQuesAnswer(shapeCounts[randomQuestionKey]);
    console.log('Question:', questionText, 'Answer:', shapeCounts[randomQuestionKey]);
  }, [currentQuestion, timer, t, lng, totalShapes]);


  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setTimeFinished(true);
    }
  }, [timeRemaining]);


  useEffect(() => {
    if (timerRemainingAnswer === 10) {
      playSound('timer');
    }
  }, [timerRemainingAnswer, playSound]);

  useEffect(() => {
    if (timeRemaining === timer - 1) {
      playSound('swing')
    }
  }, [timeRemaining, playSound, timer]);

  useEffect(() => {
    if (timeFinished && timerRemainingAnswer > 0 && socket) {
      // console.log("🟢 WebSocket listening for chat messages...");
  
      const handleChat = (data) => {
        if (data.data.uniqueId === "interactivetok.com") {
          console.log(data.data.nickname, '🎉 You got khaled a chat!', data.data.msgId, data.data.comment);
        }
        if (data?.state?.roomId && data?.data?.comment?.includes(hashtag)) {
          setComments((prevComments) => [...prevComments, data.data]);
        }
      };
  
      socket.on('chat', handleChat);
  
      return () => {
        if (timerRemainingAnswer <= 0) {
          console.log("🔴 Stopping WebSocket due to timer expiration...");
          setTimeAnswerFinished(true);
          if (socket) {
            socket.off('chat', handleChat);
            socket.disconnect();
          }
        }
      };
    }
  }, [timeFinished, timerRemainingAnswer, userId, hashtag, socket]);
  

  useEffect(() => {
    if (timeAnswerFinished && socket) {
      // console.log("Disconnecting from WebSocket due to timer end...");
      socket.off('chat');
      socket.disconnect();
    }
  }, [timeAnswerFinished, socket]);


  useEffect(() => {
    if (timeFinished) {
      const intervalId = setInterval(() => {
        setTimerRemainingAnswer((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(intervalId);
            setTimeAnswerFinished(true);
            setTimeout(() => {
              insertIntoLocalStorage();

              setTimeout(() => {
                navigate(`/${userId}/memchallenge/memresult?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=${currentQuestion}&timer=${timer}&timerAnswer=${timerAnswer}&totalShapes=${totalShapes}&isAutoMode=${isAutoMode}`, {
                  state: { comments }
                });
              }, 3000);
            }, 1000);
          }
          return newTime;

        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeFinished, userId, quesAnswer, currentQuestion, timer, totalQuestions, comments, navigate, lng, hashtag, totalShapes, insertIntoLocalStorage, timerAnswer, isAutoMode]);

  return (
    <div className="container mt-2">
      {!timeFinished &&
        <div style={{ display: 'flow-root' }}>
          <div className="ques-box my-2">
            <div className="fancy questime d-flex justify-content-center">
              <span className="numbertime text-center">{timeRemaining}</span>
            </div>
            <div className="fancy quesnum fw-bold d-flex justify-content-center">
              <span className="numberQues text-center">{t('question')} {currentQuestion} / {totalQuestions}</span>
            </div>
          </div>
          <h5 className="fontcolorWhite text-center mb-2 mt-4">{t('remember')}</h5>
        </div>
      }
      {timeFinished &&
        <div style={{ display: 'flow-root' }}>
          <div className="ques-box my-2">
            <div className="fancy questime d-flex justify-content-center">
              <span className="numbertime text-center">{timerRemainingAnswer}</span>
            </div>
            <div className="fancy quesnum fw-bold d-flex justify-content-center">
              <span className="numberQues text-center">{t('question')} {currentQuestion} / {totalQuestions}</span>
            </div>
          </div>
          <h5 className="fontcolorWhite text-center mb-2 mt-4">{t('timer_answer')}</h5>
        </div>
      }

      <div className="fancy ques text-center" id="ques_mem">
        <div className="answerMem row row-cols-7 gap-1">
          {!timeFinished && shapesDisplayed.map((shape, index) => (
            <div key={index} className="col mb-2">
              <div style={shape}></div>
            </div>
          ))}
          {timeFinished && (
            <div className="col-12">
              <h4 className="fontcolorWhite">{question}</h4>
              <h6 className="fontcolorYellow mt-4">{t('remember_answer')}</h6>
            </div>
          )}
        </div>
      </div>

      <div className="bgTransparent rounded-3 mt-2 mb-1 text-center">
        <h6 className="info fontcolorWhite">{t('share_hashtag')} </h6>
        <h6 className="info fontcolorWhite">
          {t('hashtag_today')} : <span className="fontcolorYellow">{hashtag}</span> , {t('example')} :{' '}
          <span className="fontcolorYellow">{hashtag} 123456</span>{' '}
        </h6>
      </div>
    </div>
  );
};

export default MemQuetion;
