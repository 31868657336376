import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EyeStartGame from './EyeStartGame';
import EyeSettings from './EyeSettings';
import EyeQuestion from './EyeQuestion';
import EyeResult from './EyeResult';
import EyeLeaderboard from './EyeLeaderboard';

const EyeChallenge = () => {
  return (
    <Routes>
      <Route path="EyestartGame" element={<EyeStartGame />} />
      <Route path="Eyesettings" element={<EyeSettings />} />
      <Route path="EyeQuestion" element={<EyeQuestion />} />
      <Route path="Eyeresult" element={<EyeResult />} />
      <Route path="Eyeleaderboard" element={<EyeLeaderboard />} />
    </Routes>
  );
};

export default EyeChallenge;
