import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSocket } from "../contexts/SocketContext";
import TeamsJoinPopup from "./TeamsJoinPopup";
import { useTeams } from '../contexts/TeamsContext';


const TeamsSettings = () => {
  const { userId } = useParams();
  const lng = localStorage.getItem('language');
  const [totalQuestions, setTotalQuestions] = useState(15);
  const [timer, setTimer] = useState(25);
  const [hashtag, setHashtag] = useState('');
  const [level, setLevel] = useState(lng === 'en' ? 'secondlevelUp' : 'secondlevelUp');
  const [questionCounts, setQuestionCounts] = useState({ firstlevelUp: 0, secondlevelUp: 0, thirdlevelUp: 0, alllevels: 0 });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [joinRequests, setJoinRequests] = useState([]);
  const { clearMembers } = useTeams();
  const { socket } = useSocket();

  useEffect(() => {
    clearMembers(); // ✅ فقط يمسح الحالة المحلية
    localStorage.setItem('membersTeams', JSON.stringify([])); // ✅ لا تحذف البيانات بالكامل
    window.dispatchEvent(new Event("storageTeamsUpdate")); // ✅ إشعار بحدوث تحديث
}, [clearMembers]);


  useEffect(() => {
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

  useEffect(() => {
    if (!socket) return;

    const handleChat = (data) => {
      const commentText = data.data.comment?.toLowerCase().trim();
      const userFollowRole = data.data.followRole || 0;
      const userId = data.data.userId;
      const nickname = data.data.nickname;
      const profilePictureUrl = data.data.profilePictureUrl || '';

      if (commentText.includes("انضم") && (userFollowRole === 1 || userFollowRole === 2)) {
        console.log(`✅ Received 'hello' from user with followRole: ${userFollowRole}`);

        setJoinRequests((prevRequests) => {
          const alreadyRequested = prevRequests.some(req => req.userId === userId);
          if (!alreadyRequested) {
            return [...prevRequests, { userId, nickname, profilePictureUrl, followRole: userFollowRole }];
          }
          return prevRequests;
        });
      }
    };

    socket.on('chat', handleChat);
    return () => socket.off('chat', handleChat);
  }, [socket]); // ✅ صار عندنا استماع مباشر

  useEffect(() => {
    const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
    const counts = {};

    levels.forEach((level) => {
      const data = JSON.parse(localStorage.getItem(level) || '[]');
      counts[level] = Object.keys(data).length;
    });

    counts.alllevels = Object.keys(counts).reduce((sum, level) => sum + counts[level], 0);
    setQuestionCounts(counts);
  }, []);

  useEffect(() => {
    const storeJSONInLocalStorage = async () => {
      try {
        const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
        const counts = {};

        for (const level of levels) {
          let existingData = localStorage.getItem(level);
          let needsRepopulate = false;

          if (existingData) {
            const parsedData = JSON.parse(existingData);
            if (Array.isArray(parsedData)) {
              const convertedData = parsedData.reduce((acc, question) => {
                acc[question.id] = question;
                return acc;
              }, {});
              localStorage.setItem(level, JSON.stringify(convertedData));
            } else if (Object.keys(parsedData).length <= 25) {
              needsRepopulate = true;
            }
          } else {
            needsRepopulate = true;
          }

          if (needsRepopulate) {
            const response = await axios.get(`/questionsArUp/${level}.json`);
            const data = response.data.reduce((acc, question) => {
              acc[question.id] = question;
              return acc;
            }, {});
            localStorage.setItem(level, JSON.stringify(data));
          }

          const storedData = JSON.parse(localStorage.getItem(level) || '{}');
          counts[level] = Object.keys(storedData).length;
        }

        counts.alllevels = Object.values(counts).reduce((sum, count) => sum + count, 0);
        setQuestionCounts(counts);
      } catch (error) {
        console.error('Error fetching or storing JSON files:', error);
      }
    };

    const storageKey = `quiz_${userId}`;
    localStorage.setItem(storageKey, '');
    storeJSONInLocalStorage();
  }, [userId]);

  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert(t('invalid_questions'));
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert(t('invalid_timer'));
      return;
    }
    navigate(`/${userId}/teamschallenge/teamsquestion?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestionIndex=0&timer=${timer}&level=${level}`);
  };

  return (
    <div className="max-width-300 mt-1">
      <div className="bgTransparent rounded-3 px-3 py-1 mt-1">
        <TeamsJoinPopup
          requests={joinRequests}
          onAccept={(user, team) => {
            setJoinRequests(prev => prev.filter(req => req.userId !== user.userId));
          }}
          onReject={(user) => {
            setJoinRequests(prev => prev.filter(req => req.userId !== user.userId));
          }}
        />
        <h2 className="mb-2 text-center fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('hashtag')}</label>
          <input
            type="text"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="hashtag"
            value={hashtag}
            onChange={e => setHashtag(e.target.value)}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="form-label text-truncate col-5 col-sm-5 align-middle fontcolorWhite">{t('total_questions')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="totalQuestions"
            value={totalQuestions}
            onChange={e => setTotalQuestions(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer" className="form-label text-truncate col-5 col-sm-5 align-middle fontcolorWhite">{t('question_timer')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="timer"
            value={timer}
            min="1"
            onChange={e => setTimer(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="level" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('level')}</label>
          <select
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="level"
            value={level}
            onChange={e => setLevel(e.target.value)}
          >
            <option className='fontcolorPrimary fw-semibold' value="firstlevelUp">
              {t('first_level')} ({questionCounts.firstlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="secondlevelUp">
              {t('second_level')} ({questionCounts.secondlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="thirdlevelUp">
              {t('third_level')} ({questionCounts.thirdlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="alllevels">
              {t('all_levels')} ({questionCounts.alllevels} {t('questions')})
            </option>
          </select>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="str col-12 d-flex justify-content-center my-1">
            <button onClick={handleStartGame} style={{ width: '70px', height: '70px' }} id="start" className="fancy button rotate-right">
              <h4 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>{t('start')}</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsSettings;
