import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TeamsStartGame from './TeamsStartGame';
import TeamsSettings from './TeamsSettings';
import TeamsQuestion from './TeamsQuestion';
import TeamsResult from './TeamsResult';
import TeamsLeaderboard from './TeamsLeaderboard';
import TeamsLayout from './TeamsLayout';
import { TeamsProvider } from '../contexts/TeamsContext';
import { SocketLikesProvider } from '../contexts/SocketLikesContext';
import { SocketGiftsProvider } from '../contexts/SocketGiftsContext';

const TeamsChallenge = () => {
  return (
<Routes>
  {/* ✅ لفّ `TeamsProvider` فقط حول الصفحات المطلوبة */}
  <Route element={
    <SocketLikesProvider>
      <SocketGiftsProvider>
        <TeamsProvider> 
          <TeamsLayout />
        </TeamsProvider>
      </SocketGiftsProvider>
    </SocketLikesProvider>
}>

    <Route path="/teamssettings" element={<TeamsSettings />} />
    <Route path="/teamsquestion" element={<TeamsQuestion />} />
    <Route path="/teamsresult" element={<TeamsResult />} />
    <Route path="/teamsleaderboard" element={<TeamsLeaderboard />} />
  </Route>

  {/* ✅ صفحة البداية بدون `TeamsProvider` */}
  <Route path="/teamsstartgame" element={<TeamsStartGame />} />
</Routes>

  );
};

export default TeamsChallenge;
