import React from 'react';

const crownImages = [
  "/img/crown-gold.gif",   // 🥇 الأول (Gold) - وسط
  "/img/crown-silver.gif", // 🥈 الثاني (Silver) - يسار
  "/img/crown-bronze.gif"  // 🥉 الثالث (Bronze) - يمين
];

const borderStyles = ["gold-border", "silver-border", "bronze-border"];
const top3Colors = ["gold", "silver", "#cd7f32"];

const Top3GiftersRow = ({ showTop3GiftersRow }) => {
  const topGifters = JSON.parse(localStorage.getItem("topGifters") || "[]");

  return (
    <div className="d-flex justify-content-around align-items-center bgTransparent rounded-3">
      {topGifters.length > 0 && (
        <>
          {/* ✅ الثاني 🥈 على اليسار */}
          {topGifters[1] && (
            <div className="gifter-container col-4 second-place">
              <img src={crownImages[1]} alt="Crown" className="crown-img" />
              <img src={topGifters[1]?.profilePictureUrl || "/img/default-avatar.png"} 
                   alt={topGifters[1]?.nickname || "Unknown"} 
                   className={`profile-pic ${borderStyles[1]}`}
                   style={{ boxShadow: `0 0 10px ${top3Colors[1]}` }} /> 
              <p className={`gifter-name ${borderStyles[1]}`}>{topGifters[1]?.nickname || "Unknown"}</p>
            </div>
          )}

          {/* ✅ الأول 🥇 في المنتصف */}
          {topGifters[0] && (
            <div className="gifter-container col-4 first-place">
              <img src={crownImages[0]} alt="Crown" className="crown-img" />
              <img src={topGifters[0]?.profilePictureUrl || "/img/default-avatar.png"} 
                   alt={topGifters[0]?.nickname || "Unknown"} 
                   className={`profile-pic ${borderStyles[0]}`}
                   style={{ boxShadow: `0 0 10px ${top3Colors[0]}` }} /> 
              <p className={`gifter-name ${borderStyles[0]}`}>{topGifters[0]?.nickname || "Unknown"}</p>
            </div>
          )}

          {/* ✅ الثالث 🥉 على اليمين */}
          {topGifters[2] && (
            <div className="gifter-container col-4 third-place">
              <img src={crownImages[2]} alt="Crown" className="crown-img" />
              <img src={topGifters[2]?.profilePictureUrl || "/img/default-avatar.png"} 
                   alt={topGifters[2]?.nickname || "Unknown"} 
                   className={`profile-pic ${borderStyles[2]}`}
                   style={{ boxShadow: `0 0 10px ${top3Colors[2]}` }} /> 
              <p className={`gifter-name ${borderStyles[2]}`}>{topGifters[2]?.nickname || "Unknown"}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Top3GiftersRow;
