import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSound } from '../contexts/SoundContext';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../contexts/SocketContext';


const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const colors = ['#ff008f', '#6e00ff', '#ff9200', '#fff200', '#0000ff', '#00ffd2', '#00b51d', '#ff0000'];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];


const generateAnimationStyle = () => {
  const duration = Math.random() * 3 + 1; // Random duration between 1 and 4 seconds
  const delay = Math.random() * 1; // Random delay up to 1 second
  return {
    animationDuration: `${duration}s`,
    animationDelay: `${delay}s`,
  };
};

const EyeQuestions = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10);
  const timer = parseInt(queryParams.get('timer'), 10);
  const currentQuestion = parseInt(queryParams.get('currentQuestion'), 10) || 0;
  const level = queryParams.get('level'); // Correctly get level from query parameters
  const { playSound } = useSound();
  const isAutoMode = queryParams.get('isAutoMode') === 'true';
  const [comments, setComments] = useState([]);
  const [randomNumber, setRandomNumber] = useState(getRandomNumber(9999, 999999));
  const [timeFinished, setTimeFinished] = useState(false);
  const hashtag = queryParams.get('hashtag');
  const [correctComment, setCorrectComment] = useState('');
  const [animationStyles, setAnimationStyles] = useState([]);
  const { socket } = useSocket();
  const { t } = useTranslation();
  const insertOnceRef = useRef(false);
  const timerIdRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(timer);


  const storageKey = `eye_${userId}`;


  // ✅ تحويل الأرقام العربية إلى إنجليزية
  const convertArabicToEnglishNumbers = (str) => {
    const arabicToEnglishMap = {
      '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
      '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
    };
    return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
  };

  // ✅ استقبال التعليقات من `socket`
  useEffect(() => {
    if (!socket) return;

    const handleChat = (data) => {
      if (data.data.uniqueId === "interactivetok.com") {
        console.log(data.data.nickname, '🎉 You got khaled a chat!', data.data.msgId, data.data.comment);
      }
      const msgId = data?.data?.msgId;
      if (!msgId) return;

      let commentText = data.data.comment?.toLowerCase().trim();
      commentText = convertArabicToEnglishNumbers(commentText.replace(/\s+/g, '')); // ✅ تصفية البيانات

      if (!commentText.includes(hashtag.toLowerCase())) return;

      const newComment = {
        comment: commentText,
        userId: data.data.userId,
        nickname: data.data.nickname,
        profilePictureUrl: data.data.profilePictureUrl || '',
        followRole: data.data.followRole || 0,
      };

      setComments((prev) => [...prev, newComment]); // ✅ تحديث `comments`
    };

    socket.on('chat', handleChat);
    return () => {
      socket.off('chat', handleChat); // ✅ إيقاف الاستماع عند إزالة المكون
    };
  }, [socket, hashtag]);

  // ✅ تحديد التعليق الصحيح وتحويله إلى **أحرف صغيرة** + إزالة الفراغات
  useEffect(() => {
    if (hashtag && randomNumber) {
      const formattedCorrectComment = `${hashtag}${randomNumber}`.toLowerCase().replace(/\s+/g, '');
      setCorrectComment(formattedCorrectComment);
      console.log("✅ Correct Comment Set:", formattedCorrectComment);
    }
  }, [hashtag, randomNumber]);

  // ✅ إدخال البيانات إلى `localStorage` مع فلترة صحيحة
  const insertIntoLocalStorage = useCallback(() => {
    if (insertOnceRef.current) return;
    insertOnceRef.current = true;

    if (!correctComment || !randomNumber) {
      console.error("🚨 Missing correct comment or position!");
      return;
    }

    const existingData = JSON.parse(localStorage.getItem(storageKey) || '{}');

    console.log(`🔍 Saving question ${currentQuestion} with correct comment: ${correctComment}`);

    // ✅ حفظ أول تعليق لكل مستخدم يحتوي على الهاشتاغ
    const commentsData = comments.reduce((acc, comment) => {
      let cleanedComment = convertArabicToEnglishNumbers(comment.comment.toLowerCase().replace(/\s+/g, ''));

      if (cleanedComment.includes(hashtag.toLowerCase()) && !acc[comment.userId]) {
        const isCorrect = cleanedComment === correctComment;

        acc[comment.userId] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: comment.nickname,
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
      }
      return acc;
    }, {});

    const newEntry = {
      userId,
      hashtag,

      currentQuestion,

      randomNumber,
      correctComment,
      comments: commentsData,
    };

    existingData[currentQuestion] = newEntry;
    localStorage.setItem(storageKey, JSON.stringify(existingData));

    console.log(`✅ Stored Question ${currentQuestion}:`, newEntry);
  }, [comments, randomNumber, hashtag, userId, storageKey, currentQuestion, correctComment]);

  useEffect(() => {
    const updateBackgroundColors = () => {
      requestAnimationFrame(() => {
        document.documentElement.style.setProperty('--bg1-color', getRandomColor());
        document.documentElement.style.setProperty('--bg2-color', getRandomColor());
        document.documentElement.style.setProperty('--bg3-color', getRandomColor());
        document.documentElement.style.setProperty('--bg4-color', getRandomColor());
        document.documentElement.style.setProperty('--bg5-color', getRandomColor());
        document.documentElement.style.setProperty('--bg6-color', getRandomColor());
        document.documentElement.style.setProperty('--bg7-color', getRandomColor());
        document.documentElement.style.setProperty('--bg8-color', getRandomColor());
      });
    };
  
    const interval = setInterval(updateBackgroundColors, 2000); // تحديث كل ثانية
    return () => clearInterval(interval);
  }, [userId, hashtag]);
  


  useEffect(() => {
    setComments([]);
    setRandomNumber(getRandomNumber(9999, 999999));
    setTimeFinished(false);
  }, [currentQuestion, timer]);

  useEffect(() => {
    if (level === 'hard') {
      const styles = randomNumber
        .toString()
        .split('')
        .map(() => generateAnimationStyle());
      setAnimationStyles(styles);
    }
  }, [level, randomNumber]);

  const handleQuestionEnd = useCallback(() => {
    if (!correctComment || !randomNumber) {
      console.error('🚨 Missing correct comment or position, skipping storage!');
      return;
    }

    insertIntoLocalStorage();

    setTimeout(() => {
      navigate(
        `/${userId}/eyechallenge/eyeresult?hashtag=${hashtag}&level=${level}&totalQuestions=${totalQuestions}&currentQuestion=${currentQuestion}&timer=${timer}&isAutoMode=${isAutoMode}`
      );
    }, 5000);
  }, [hashtag, insertIntoLocalStorage, navigate, timer, totalQuestions, userId, currentQuestion, isAutoMode, correctComment, randomNumber, level]);

  // ✅ تشغيل المؤقت
  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0) {

        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        setTimeFinished(true);
        handleQuestionEnd();

      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, handleQuestionEnd]);

  useEffect(() => {
    if (timeLeft === 10) {
      playSound('timer');
    }
  }, [timeLeft, playSound]);

  useEffect(() => {
    if (timeLeft === timer - 1) {
      playSound('swing')
    }
  }, [timeLeft, playSound, timer]);

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="ques-box">
          <div className="fancy questime d-flex justify-content-center">
            <span className="numbertime text-center">{timeLeft}</span>
          </div>
          <div className="fancy quesnum fw-bold d-flex justify-content-center">
            <span className="numberQues text-center">{t('question')} {currentQuestion} / {totalQuestions}</span>
          </div>
        </div>
      </div>
      <div className="fancy mt-4 ques" id="ques">
        <div className={`bgm ${timeFinished ? 'hide-bgm' : ''}`} style={{ direction: 'rtl' }}>
          <div className="bgm1">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm2">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm3">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="-60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm4">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="-60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <h1 className={`numhideP m-0 ${timeFinished ? 'show-numhideP' : ''}`}>
            {level === 'hard'
              ? randomNumber.toString().split('').reverse().map((digit, index) => (
                <span
                  key={index}
                  className={`digit ${timeFinished ? '' : 'move'}`}
                  style={timeFinished ? {} : animationStyles[index]}
                >
                  {digit}
                </span>
              ))
              : randomNumber
            }
          </h1>
        </div>
      </div>
      <div className="bgTransparent rounded-3 mt-2 mb-1 text-center">
        <h6 className="info fontcolorWhite">{t('share_hashtag')} </h6>
        <h6 className="info fontcolorWhite">
          {t('hashtag_today')} : <span className="fontcolorYellow">{hashtag}</span> , {t('example')} :{' '}
          <span className="fontcolorYellow">{hashtag} 123456</span>{' '}
        </h6>
      </div>
    </div>
  );
};

export default EyeQuestions;
