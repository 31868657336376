import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { FaSignInAlt, FaHome } from '../../utils/icons';
import { useTranslation } from 'react-i18next'; // ✅ استيراد useTranslation
import LanguageSwitcher from './LanguageSwitcher';

const MainHeader = () => {
  const { i18n } = useTranslation(); // ✅ الآن يعمل بدون خطأ
  const lng = i18n.language === 'ar' ? 'ar' : 'en';

  const navigate = useNavigate();
  
  const handleLogin = () => {
    navigate(`/login`);
  };

  const handleHome = () => {
    navigate(`/`);
  };

  return (
    <header>
      <nav className="navbar bgTransparent">
      <div className="container-fluid">
          {/* أزرار التنقل */}
          <div className="d-flex">
            <LanguageSwitcher lng={lng} />
          </div>

          {/* أزرار الاتصال */}
          <div className="d-flex">
          <button onClick={handleLogin} className="button-icon rotate-right mx-2">
              <span className="span-icon"><FaSignInAlt /></span>
            </button>
            <button onClick={handleHome} className="button-icon rotate-right">
              <span className="span-icon"><FaHome /></span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
