// BrainChallenge.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DifferenceStartGame from './DifferenceStartGame';
import DifferenceSettings from './DifferenceSettings';
import DifferenceQuestion from './DifferenceQuestion';
import DifferenceResult from './DifferenceResult';
import DifferenceLeaderboard from './DifferenceLeaderboard';

const DifferenceChallenge = () => {
  return (
    <Routes>
      <Route path="differencestartGame" element={<DifferenceStartGame />} />
      <Route path="differencesettings" element={<DifferenceSettings />} />
      <Route path="differencequestion" element={<DifferenceQuestion />} />
      <Route path="differenceresult" element={<DifferenceResult />} />
      <Route path="differenceleaderboard" element={<DifferenceLeaderboard />} />
    </Routes>
  );
};

export default DifferenceChallenge;
