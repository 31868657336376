import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSound } from '../contexts/SoundContext';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Leaderboard = () => {
  const [leaders, setLeaders] = useState([]);
  const { userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const timer = parseInt(queryParams.get('timer'), 10) || 0;
  const hashtag = queryParams.get('hashtag') || '';
  const level = queryParams.get('level');
  const navigate = useNavigate();
  const { playSound } = useSound();
  const { t } = useTranslation();
  const animationContainer = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const isAutoMode = queryParams.get('isAutoMode') === 'true';

  const startNewRound = useCallback(() => {
    const storageKey = `quiz_${userId}`;
    localStorage.setItem(storageKey, '');
  
    // ✅ تأجيل عملية `navigate` بعد انتهاء `render`
    setTimeout(() => {
      navigate(
        `/${userId}/brainchallenge/question?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&currentQuestionIndex=0&level=${level}&isAutoMode=${isAutoMode}`
      );
    }, 0);
  }, [userId, navigate, hashtag, totalQuestions, timer, level, isAutoMode]);
  

  useEffect(() => {
    if (showPopup) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            // ✅ تأجيل `startNewRound` بعد انتهاء `render`
            setTimeout(() => {
              startNewRound();
            }, 0);
          }
          return prev - 1;
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [showPopup, startNewRound]);
  
  

  const scrollRestLeaders = useCallback(() => {
    const listElement = document.querySelector('.leaderboard-list');
    if (!listElement) return;
  
    const scrollStep = 5;
    let scrollPosition = 0;
  
    const interval = setInterval(() => {
      scrollPosition += scrollStep;
      listElement.scrollTo(0, scrollPosition);
  
      if (scrollPosition >= listElement.scrollHeight) {
        clearInterval(interval);
        // ✅ تأجيل `setShowPopup` بعد انتهاء `render`
        setTimeout(() => {
          setShowPopup(true);
        }, 0);
      }
    }, 100);
  }, []);
  
  
  useEffect(() => {
    if (isAutoMode) {
      const timer = setTimeout(() => {
        scrollRestLeaders();
      }, 20000); // ✅ الانتظار 20 ثانية
  
      return () => clearTimeout(timer);
    }
  }, [isAutoMode, scrollRestLeaders]);
  

  useEffect(() => {
    const fetchLeaderboard = () => {
      const storageKey = `quiz_${userId}`;
      const rawData = localStorage.getItem(storageKey);

      if (!rawData) {
        console.warn(`No data found for key: ${storageKey}`);
        setLeaders([]);
        return;
      }

      let data;
      try {
        data = JSON.parse(rawData);
      } catch (error) {
        console.error(`Error parsing JSON from localStorage for key: ${storageKey}`, error);
        setLeaders([]);
        return;
      }

      const userIdMap = new Map();
      const orderCount = new Map();

      // Filter keys that represent questions only
      const questionKeys = Object.keys(data).filter((key) => !isNaN(key));
      const totalQuestions = questionKeys.length;

      questionKeys.forEach((key, questionIndex) => {
        const questionData = data[key];
        if (!questionData.comments) return;

        const { comments } = questionData;

        Object.values(comments).forEach((comment, commentIndex) => {
          if (comment.answer === 'true') {
            if (!userIdMap.has(comment.userId)) {
              userIdMap.set(comment.userId, {
                tik_pic: comment.profilePictureUrl,
                tik_nickname: comment.nickname,
                correct_answers: 1,
              });
              orderCount.set(comment.userId, questionIndex * 100 + commentIndex);
            } else {
              const existing = userIdMap.get(comment.userId);
              existing.correct_answers += 1;
              userIdMap.set(comment.userId, existing);
            }
          }
        });
      });

      let leaderboardData = Array.from(userIdMap.entries()).map(([userId, data]) => ({
        ...data,
        total_questions: totalQuestions,
        userId,
      }));

      leaderboardData.sort((a, b) => {
        const correctDiff = b.correct_answers - a.correct_answers;
        if (correctDiff !== 0) {
          return correctDiff;
        } else {
          return orderCount.get(a.userId) - orderCount.get(b.userId);
        }
      });

      setLeaders(leaderboardData);
      playSound('cheering');
    };

    fetchLeaderboard();
  }, [userId, playSound]);

  const handleNext = () => {
    navigate(`/${userId}/brainchallenge/settings`);
  };

  const crownImages = [
    "/img/crown-gold.gif",   // 🥇 الأول (Gold) - وسط
    "/img/crown-silver.gif", // 🥈 الثاني (Silver) - يسار
    "/img/crown-bronze.gif"  // 🥉 الثالث (Bronze) - يمين
  ];

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await axios.get('/images/Animation.json', { responseType: 'json' });
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: response.data,
        });
      } catch (error) {
        console.error('Error loading Lottie animation:', error);
      }
    };

    fetchAnimationData();

    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <div className="container">
      <div className="mb-1">
        <div className="text-center row justify-content-around align-items-center">
          <button
            className="col-6 fancy button"
            onClick={handleNext}
            style={{ width: '40%' }}
          >
            <h6 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
              {t('start_new_challenge')}
            </h6>
          </button>
          <h4 className="col-6">{t('leaders')}</h4>
        </div>
      </div>
      <div className="celebration" ref={animationContainer}></div>
      <TopThreeLeaders leaders={leaders.slice(0, 3)} crownImages={crownImages} />
      <RemainingLeaders leaders={leaders.slice(3)} />
      {showPopup && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders">
            <h4>{t('new_round_starts_in')}</h4>
            <h1>{countdown}</h1>
          </div>
        </div>
      )}
    </div>
  );
};

const TopThreeLeaders = ({ leaders, crownImages }) => {
  const [showTop3, setShowTop3] = useState(false);
  const borderStyles = ["gold-border", "silver-border", "bronze-border"];
  const top3Colors = ["gold", "silver", "#cd7f32"];

  useEffect(() => {
    setShowTop3(true);
  }, []);

  return (
    <div className={`row justify-content-center mb-2 podium ${showTop3 ? 'show' : ''}`}>
      {leaders.map((leader, index) => (
        <div
          key={index}
          className={`p-1 col-md-4 col-sm-4 col-4 text-center position-relative podium-position position-${index + 1}`}
        >
          <img className="crown" src={crownImages[index]} alt={`Crown ${index + 1}`} />
          <img src={leader.tik_pic} alt="Profile" className={`profile-pic mb-1 ${borderStyles[index]}`} style={{ boxShadow: `0 0 10px ${top3Colors[index]}` }} /> 

          <h6 className='mb-0' style={{ color: top3Colors[index] }}>{leader.tik_nickname}</h6>
          <h6 className='fontcolorWhite'>{leader.correct_answers}/{leader.total_questions}</h6>
        </div>
      ))}
    </div>
  );
};

const RemainingLeaders = ({ leaders }) => {
  const [showRest, setShowRest] = useState(false);
  const [lng, setLng] = useState('ar'); // ✅ تعيين قيمة افتراضية

  useEffect(() => {
    setLng(localStorage.getItem('language') || 'ar'); // ✅ جلب اللغة من localStorage
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRest(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  return (
    showRest && (
              <ul className="bgTransparent leaderboard-list row justify-content-center slow-motion custom-scrollbar rounded-3 p-1">
              {leaders.map((leader, index) => (
                <li key={index + 3} className="d-flex align-items-center mb-1 px-1 pb-1 border-bottom border-white">
                  <h5 className={`col-md-1 col-sm-1 col-1 fw-bold fontcolorWhite mx-1 mb-0 ${lng === 'ar' ? 'text-end' : 'text-start'}`}>{index + 4}</h5>
                  <img src={leader.tik_pic} alt="Profile" className="col-md-2 col-sm-2 col-2 rest-profile-pic" />
                  <h6 className="col-md-7 col-sm-7 col-7 text-center mb-0 flex-grow-1">{leader.tik_nickname}</h6>
                  <h5 className="col-md-2 col-sm-2 col-2 mb-0 fontcolorWhite">{leader.correct_answers}/{leader.total_questions}</h5>
    
                </li>
              ))}
            </ul>
    )
  );
};

export default Leaderboard;
