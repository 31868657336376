import React, { useState, useEffect } from 'react';
import { useSound } from '../contexts/SoundContext';
import { useCamera } from '../contexts/CameraContext';
import ColorPicker from './ColorPicker';
import { useSocketLikes } from '../contexts/SocketLikesContext';
import { useSocketGifts } from '../contexts/SocketGiftsContext';
import { useSocketFollows } from '../contexts/SocketFollowsContext';

const SettingsPopup = () => {
  const { 
    bgVolume, setBgVolume, 
    fxVolume, setFxVolume, 
    selectedMusic, setSelectedMusic, 
    playBackground, pauseBackground, 
    activateBgAudio, activateFxAudio 
  } = useSound();
  
  const { cameraMode, setCameraMode } = useCamera();
  const { showLikes, setShowLikes } = useSocketLikes();
  const { showGifters, setShowGifters } = useSocketGifts();
  const { showFollows, setShowFollows } = useSocketFollows();

  const [showtop20Likers, setShowtop20Likers] = useState(() => {
    return JSON.parse(localStorage.getItem('showtop20Likers')) ?? true;
  });
  const [showTop3GiftersRow, setShowTop3GiftersRow] = useState(() => {
    return JSON.parse(localStorage.getItem('showTop3GiftersRow')) ?? true;
  });
  const [enableEffects, setEnableEffects] = useState(() => {
    return JSON.parse(localStorage.getItem("enableEffects")) ?? true;
  });

  useEffect(() => {
    localStorage.setItem('showtop20Likers', JSON.stringify(showtop20Likers));
    localStorage.setItem('showTop3GiftersRow', JSON.stringify(showTop3GiftersRow));
    // إرسال حدث لتحديث المكونات المتأثرة (مثل Footer)
    window.dispatchEvent(new Event('storageShowtop20Likers'));
    window.dispatchEvent(new Event('storageShowTop3GiftersRow'));
  }, [showtop20Likers, showTop3GiftersRow]);

  useEffect(() => {
    const leaderboardElement = document.querySelector('.leaderboard-list-teams');
    const customscrollbar = document.querySelector('.custom-scrollbar');
    const customscrollbarresult = document.querySelector('.custom-scrollbar-result');
    const leaderboardlist = document.querySelector('.leaderboard-list');

    if (leaderboardElement) {
      leaderboardElement.style.maxHeight = !showTop3GiftersRow ? '300px' : '240px';
    }
    if (customscrollbar) {
      customscrollbar.style.maxHeight = !showTop3GiftersRow ? '420px' : '350px';
    }
    if (customscrollbarresult) {
      customscrollbarresult.style.maxHeight = !showTop3GiftersRow ? '360px' : '280px';
    }
    if (leaderboardlist) {
      leaderboardlist.style.maxHeight = !showTop3GiftersRow ? '300px' : '240px';
    }
  }, [showTop3GiftersRow]);

  // تغيير مستوى صوت الخلفيات
  const handleBgVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    if (bgVolume === 0 && newVolume > 0) {
      activateBgAudio();
    }
    setBgVolume(newVolume);
  };

  // تغيير مستوى صوت التأثيرات
  const handleFxVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    if (fxVolume === 0 && newVolume > 0) {
      activateFxAudio();
    }
    setFxVolume(newVolume);
  };

  useEffect(() => {
    localStorage.setItem("enableEffects", JSON.stringify(enableEffects));
    document.body.style.setProperty("--enable-animation", enableEffects ? "running" : "paused");
  }, [enableEffects]);

  return (
    <div className="popup mx-auto bgTransparent rounded-3 px-3 py-2">
      <div className="d-flex flex-wrap justify-content-between">
        <h5 className="text-white text-center mb-2">إعدادات الصوت والمظهر</h5>
      </div>

      {/* التحكم بمستوى صوت الخلفيات */}

        <div className="mt-3 d-flex justify-content-between align-items-center">
        <div className="mb-3">
        <label className="text-white d-block">🔊 صوت الخلفيات</label>
        <input
          type="range"
          className="form-range"
          min="0"
          max="100"
          value={bgVolume}
          onChange={handleBgVolumeChange}
        />
      </div>

      {/* التحكم بمستوى صوت التأثيرات */}
      <div className="mb-3">
        <label className="text-white d-block">🔊 صوت التأثيرات</label>
        <input
          type="range"
          className="form-range"
          min="0"
          max="100"
          value={fxVolume}
          onChange={handleFxVolumeChange}
        />
      </div>
      </div>

      {/* اختيار الموسيقى الخلفية */}
      <div className="mb-3">
        <label className="text-white d-block">اختيار الموسيقى الخلفية</label>
        <div className="d-flex flex-wrap justify-content-around">
          {[
            'backgroundAudio',
            'backgroundAudio2',
            'backgroundAudio3',
            'backgroundAudio4',
            'backgroundAudio5',
            'backgroundAudio6',
          ].map((track) => (
            <div key={track} className="text-white">
              <input
                type="radio"
                name="music"
                id={track}
                checked={selectedMusic === track}
                onChange={() => {
                  pauseBackground(); // إيقاف الموسيقى الحالية
                  setSelectedMusic(track);
                  playBackground(track);
                }}
              />
              <label className="form-check-label" htmlFor={track}>
                🎧
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* اختيار وضع الكاميرا */}
      <div className="mb-3">
        <label className="text-white d-block">📷 وضع الكاميرا</label>
        <div className="d-flex justify-content-around">
          <div className="form-check text-white">
            <input
              className="form-check-input"
              type="radio"
              name="cameraMode"
              id="single"
              checked={cameraMode === 'single'}
              onChange={() => setCameraMode('single')}
            />
            <label className="form-check-label" htmlFor="single">📷</label>
          </div>
          <div className="form-check text-white">
            <input
              className="form-check-input"
              type="radio"
              name="cameraMode"
              id="double"
              checked={cameraMode === 'double'}
              onChange={() => setCameraMode('double')}
            />
            <label className="form-check-label" htmlFor="double">📷📷</label>
          </div>
        </div>
      </div>

      {/* تخصيص الألوان */}
      <div className="mb-3">
        <label className="text-white d-block">🎨 تخصيص الألوان</label>
        <ColorPicker />
      </div>

      {/* إشعارات الأحداث */}
      <div className="mb-3">
        <label className="text-white d-block">ظهور الاشعارات</label>
        <div className="d-flex justify-content-around">
          <div className="col-4">
            <label className="text-white d-block col">لايكات</label>
            <input
              type="checkbox"
              id="showLikes"
              className="form-check-input col-1 mx-1"
              checked={showLikes}
              onChange={() => setShowLikes(!showLikes)}
            />
            <label className="form-check-label text-white col-1" htmlFor="showLikes">
              {showLikes ? "✅" : "❌"}
            </label>
          </div>
          <div className="col-4">
            <label className="text-white d-block col">متابعين</label>
            <input
              type="checkbox"
              id="showFollows"
              className="form-check-input col-1 mx-1"
              checked={showFollows}
              onChange={() => setShowFollows(!showFollows)}
            />
            <label className="form-check-label text-white col-1" htmlFor="showFollows">
              {showFollows ? "✅" : "❌"}
            </label>
          </div>
          <div className="col-4">
            <label className="text-white d-block col">داعمين</label>
            <input
              type="checkbox"
              id="showGifters"
              className="form-check-input col-1 mx-1"
              checked={showGifters}
              onChange={() => setShowGifters(!showGifters)}
            />
            <label className="form-check-label text-white col-1" htmlFor="showGifters">
              {showGifters ? "✅" : "❌"}
            </label>
          </div>
        </div>
      </div>

      {/* طبقات (سلايدر لايكات وتوب جيفتر) */}
      <div className="mb-3">
        <label className="text-white d-block">طبقات</label>
        <div className="d-flex justify-content-around">
          <div className="col-4">
            <label className="text-white d-block col">سلايدر لايكات</label>
            <input
              type="checkbox"
              id="showtop20Likers"
              className="form-check-input col-1 mx-1"
              checked={showtop20Likers}
              onChange={() => setShowtop20Likers(!showtop20Likers)}
            />
            <label className="form-check-label text-white col-1" htmlFor="showtop20Likers">
              {showtop20Likers ? "✅" : "❌"}
            </label>
          </div>
          <div className="col-4">
            <label className="text-white d-block col">داعمين</label>
            <input
              type="checkbox"
              id="showTop3GiftersRow"
              className="form-check-input col-1 mx-1"
              checked={showTop3GiftersRow}
              onChange={() => setShowTop3GiftersRow(!showTop3GiftersRow)}
            />
            <label className="form-check-label text-white col-1" htmlFor="showTop3GiftersRow">
              {showTop3GiftersRow ? "✅" : "❌"}
            </label>
          </div>
          <div className="col-4">
            <label className="text-white d-block col">انيميشن</label>
            <input
              type="checkbox"
              id="enableEffects"
              className="form-check-input col-1 mx-1"
              checked={enableEffects}
              onChange={() => setEnableEffects(!enableEffects)}
            />
            <label className="form-check-label text-white col-1" htmlFor="enableEffects">
              {enableEffects ? "✅" : "❌"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
