import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';
import { useTeams } from '../contexts/TeamsContext';

const SocketLikesContext = createContext({
  userLikes: {},
  topLikers: [],
  top20Likers: [],
  resetTopLikers: () => { },
  showLikes: true,
  setShowLikes: () => { }
});

export const useSocketLikes = () => {
  const context = useContext(SocketLikesContext);
  if (!context) {
    console.warn("⚠️ useSocketLikes must be used within a SocketLikesProvider");
    return {
      userLikes: {},
      topLikers: [],
      top20Likers: [],
      resetTopLikers: () => { },
      showLikes: true,
      setShowLikes: () => { }
    };
  }
  return context;
};

export const SocketLikesProvider = ({ children }) => {
  const { socket } = useSocket();
  const { updateLikePoints } = useTeams();
  const [showLikes, setShowLikes] = useState(() => JSON.parse(localStorage.getItem('showLikes')) ?? true);

  useEffect(() => {
    localStorage.setItem('showLikes', JSON.stringify(showLikes));
  }, [showLikes]);

  useEffect(() => {
    if (!socket) return;
    const processedLikes = new Set();

    socket.on('like', (data) => {
      if (data.uniqueId === "interactivetok.com") {
        console.log('🎉 You got a like!', data.likeMsgId, data.likeCount);
      }
      if (processedLikes.has(data.likeMsgId)) return;
      processedLikes.add(data.likeMsgId);

      updateLikePoints(data.userLikeId, data.likeCount);

      let storedLikes;
      try {
        const storedData = localStorage.getItem('userLikes');
        storedLikes = storedData ? JSON.parse(storedData) : {}; // ✅ لا يحاول `JSON.parse` قراءة `null`
      } catch (error) {
        console.warn("⚠️ خطأ أثناء تحليل JSON من localStorage:", error);
        storedLikes = {};
      }      
      const previousTotal = storedLikes[data.uniqueId]?.totalLikeCount || 0;
      const newLikeCount = previousTotal + data.likeCount;

      storedLikes[data.uniqueId] = {
        nickname: data.nickname,
        profilePictureUrl: data.profilePictureUrl,
        totalLikeCount: newLikeCount,
        likesSinceLastPopup: (storedLikes[data.uniqueId]?.likesSinceLastPopup || 0) + data.likeCount,
      };

      localStorage.setItem('userLikes', JSON.stringify(storedLikes));

      const sortedLikers = Object.values(storedLikes).sort((a, b) => b.totalLikeCount - a.totalLikeCount);

      localStorage.setItem('topLikers', JSON.stringify(sortedLikers.slice(0, 10)));
      window.dispatchEvent(new Event("storageTopLikers"));

      localStorage.setItem('top20Likers', JSON.stringify(sortedLikers.slice(0, 20)));
      window.dispatchEvent(new Event("storageTop20Likers"));

      // ✅ **إدارة إشعارات اللايكات مع التأكد من أنها فقط كل 20 لايك**
      if (showLikes && storedLikes[data.uniqueId].likesSinceLastPopup >= 200) {
        const notificationQueue = JSON.parse(localStorage.getItem('notificationQueue')) || [];

        notificationQueue.push({
          nickname: data.nickname,
          profilePictureUrl: data.profilePictureUrl,
          type: 'likes',
        });

        localStorage.setItem('notificationQueue', JSON.stringify(notificationQueue));
        window.dispatchEvent(new Event("storageNotificationQueue"));

        storedLikes[data.uniqueId].likesSinceLastPopup = 0;
      }

      localStorage.setItem('userLikes', JSON.stringify(storedLikes));
    });

    return () => {
      socket.off('like');
    };
  }, [socket, showLikes, updateLikePoints]);

  const resetTopLikers = () => {
    localStorage.setItem('top20Likers', JSON.stringify({}));
    localStorage.setItem('topLikers', JSON.stringify({}));
    localStorage.setItem('userLikes', JSON.stringify({}));    
    console.log("🔄 Reset top likers.");
  };

  return (
    <SocketLikesContext.Provider value={{
      showLikes,
      setShowLikes,
      resetTopLikers
    }}>
      {children}
    </SocketLikesContext.Provider>
  );
};

export default SocketLikesContext;
