import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DifferenceStartGame = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const username = localStorage.getItem('username') || "غير معروف";

  return (
    <div className="container text-center">
      <h2 className="mb-4 fontcolorYellow fw-bold">{t('start_challenge')}</h2>
      <h3 className="fontcolorWhite">{t('streamer')} <span className="fontcolorYellow">{username}</span></h3>
      <div className="games text-center mt-2">
        <div className="row justify-content-around p-2 ">
          <Link
            to={`/${userId}/differencechallenge/differencesettings`}
            state={{ username: username }}
            className="col-md-auto bgTransparent fontcolorWhite p-3 rounded-3 text-center"
            style={{ width: '18rem', textDecoration: 'none', color: 'inherit' }}
          >
            <div className="">
              <h5 className=" fontcolorYellow fw-bold">{t('difference_challenge')}</h5>
              <p className="card-text" dangerouslySetInnerHTML={{ __html: t('difference_challenge_desc') }}></p>
              <h6 className="fontcolorYellow">{t('difference_add_hashtag')}</h6>
              <p className="fw-bold">{t('example_q')}<span className='fontcolorYellow'>{t('difference_example_q')}</span></p>
            </div>
            <button className='fancy rotate-right' style={{ width: '80px' }}><span className="span-icon">{t('start')}</span></button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DifferenceStartGame;
