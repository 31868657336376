import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const SettingsMillion = () => {
  const { userId } = useParams();
  const [totalQuestions] = useState(15); // Total questions set to 15
  const [questionCounts, setQuestionCounts] = useState({ firstlevelUp: 0, secondlevelUp: 0, thirdlevelUp: 0, alllevels: 0 });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
    const counts = {};

    levels.forEach((level) => {
      const data = JSON.parse(localStorage.getItem(level) || '[]');
      counts[level] = Object.keys(data).length;
    });

    counts.alllevels = Object.keys(counts).reduce((sum, level) => sum + counts[level], 0);
    setQuestionCounts(counts);
  }, []);

  useEffect(() => {
    const storeJSONInLocalStorage = async () => {
      try {
        const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
        const counts = {};

        for (const level of levels) {
          let existingData = localStorage.getItem(level);
          let needsRepopulate = false;

          if (existingData) {
            const parsedData = JSON.parse(existingData);

            if (Array.isArray(parsedData)) {
              const convertedData = parsedData.reduce((acc, question) => {
                acc[question.id] = question;
                return acc;
              }, {});
              localStorage.setItem(level, JSON.stringify(convertedData));
            } else if (Object.keys(parsedData).length <= 25) {
              needsRepopulate = true;
            }
          } else {
            needsRepopulate = true;
          }

          if (needsRepopulate) {
            const response = await axios.get(`/questionsArUp/${level}.json`);
            const data = response.data.reduce((acc, question) => {
              acc[question.id] = question;
              return acc;
            }, {});
            localStorage.setItem(level, JSON.stringify(data));
          }

          const storedData = JSON.parse(localStorage.getItem(level) || '{}');
          counts[level] = Object.keys(storedData).length;
        }

        counts.alllevels = Object.values(counts).reduce((sum, count) => sum + count, 0);
        setQuestionCounts(counts);
      } catch (error) {
        console.error('Error fetching or storing JSON files:', error);
      }
    };

    const storageKey = `quiz_${userId}`;
    localStorage.setItem(storageKey, '');
    storeJSONInLocalStorage();
  }, [userId]);

  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert(t('invalid_questions'));
      return;
    }

    navigate(`/${userId}/millionchallenge/questionmillion?totalQuestions=${totalQuestions}&currentQuestionIndex=0`);
  };

  return (
    <div className="container mt-1">
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="fw-semibold col-5 col-sm-5 align-middle fontcolorWhite">{t('total_questions')}</label>
          <input
            className="col-2 col-sm-2 fontcolorYellow fw-semibold text-center bgTransparent"
            id="totalQuestions"
            value={totalQuestions}
            disabled // Disabling the input since it's fixed to 15
          />
          <div className="col-5 col-sm-5 fontcolorWhite fw-semibold">{questionCounts.alllevels} {t('questions')}</div>
        </div>
        <div className="row d-flex justify-content-center" style={{ margin: '1em auto' }}>
          <div className="str col-12 d-flex justify-content-center mt-3">
            <button onClick={handleStartGame} style={{ width: '70px', height: '70px' }} id="start" className="fancy button">
              <h4 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>{t('start')}</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsMillion;
