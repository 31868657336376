import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Top20Likers = ({ showtop20Likers }) => {
  const { i18n } = useTranslation();
  const [top20Likers, setTop20Likers] = useState(() => {
    try {
      const data = JSON.parse(localStorage.getItem("top20Likers"));
      return Array.isArray(data) ? data : []; // ✅ التحقق من أن البيانات مصفوفة
    } catch (error) {
      console.error("⚠️ خطأ أثناء قراءة `top20Likers` من `localStorage`:", error);
      return [];
    }
  });
  
  const [currentIndex, setCurrentIndex] = useState(0);

  // ✅ تحديث البيانات عند تغيير `localStorage`
  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const data = JSON.parse(localStorage.getItem("top20Likers"));
        setTop20Likers(Array.isArray(data) ? data : []); // ✅ التحقق من صحة البيانات
      } catch (error) {
        console.error("⚠️ خطأ أثناء تحديث `top20Likers`:", error);
        setTop20Likers([]);
      }
    };
    

    window.addEventListener("storageTop20Likers", handleStorageChange);
    return () => {
      window.removeEventListener("storageTop20Likers", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (top20Likers.length <= 6) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 > top20Likers.length - 6 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [top20Likers]);

  const getBorderStyle = (index) => {
    if (index === 0) return "gold-border";
    if (index === 1) return "silver-border";
    if (index === 2) return "bronze-border";
    return "";
  };

  if (!showtop20Likers) return null;

  return (
    <div className="likers-container">
      {top20Likers.length === 0 ? (
        <p className="text-center fontcolorWhite">🔄 لا يوجد بيانات بعد!</p>
      ) : (
        <div
          className="likers-slider"
          style={{
            transform: `translateX(${i18n.language === 'ar' ? currentIndex * (100 / 6) : -currentIndex * (100 / 6)}%)`
          }}
        >
          {top20Likers.map((liker, index) => (
            <div key={index} className="liker-card col-2">
              <span className="likes">
                <span className="fontcolorYellow">{liker.totalLikeCount}</span> &hearts;
              </span>
              <img
                src={liker.profilePictureUrl}
                alt={liker.nickname}
                className={`profile-pic ${getBorderStyle(index)}`}
              />
              <span className="fontcolorWhite nickname">{liker.nickname}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Top20Likers;
