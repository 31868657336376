import React from 'react';
import { Outlet } from 'react-router-dom';
import { SocketLikesProvider } from '../contexts/SocketLikesContext';
import { SocketGiftsProvider } from '../contexts/SocketGiftsContext';
import TeamsSidebar from './TeamsSidebar';

const TeamsLayout = () => {
  return (
  <SocketLikesProvider>
    <SocketGiftsProvider>
      <div className="d-flex">
        <TeamsSidebar teamColor="red" teamName="احمر" />
        <div className="flex-grow-1">
          <Outlet />
        </div>
        <TeamsSidebar teamColor="blue" teamName="ازرق" />
      </div>
    </SocketGiftsProvider>
  </SocketLikesProvider>

  );
};

export default TeamsLayout;
