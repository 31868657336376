import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';

const SocketFollowsContext = createContext({
  userFollowers: {}, 
  showPopupFollowers: false, 
  popupDataFollowers: {}, 
  showFollows: true, 
  setShowFollows: () => {} 
});

// ✅ حل مشكلة undefined عند استخدام `useContext`
export const useSocketFollows = () => {
  const context = useContext(SocketFollowsContext);
  if (!context) {
    console.warn("⚠️ useSocketFollows must be used within a SocketFollowsProvider");
    return { userFollowers: {}, showPopupFollowers: false, popupDataFollowers: {}, showFollows: true, setShowFollows: () => {} };
  }
  return context;
};

export const SocketFollowsProvider = ({ children }) => {
  const { socket } = useSocket();
  const [userFollowers, setUserFollowers] = useState({});
  const [showPopupFollowers, setShowPopupFollowers] = useState(false);
  const [popupDataFollowers, setPopupDataFollowers] = useState({});
  const [showFollows, setShowFollows] = useState(() => JSON.parse(localStorage.getItem('showFollows')) ?? true);
  
  useEffect(() => {
    localStorage.setItem('showFollows', JSON.stringify(showFollows));
  }, [showFollows]);

  useEffect(() => {
    if (!socket) return;

    socket.on('follow', (data) => {
      // console.log('Follow received:', data);
      if (showFollows) {
        setUserFollowers((prevFollowers) => {
          const newFollowers = {
            ...prevFollowers,
            [data.uniqueId]: {
              nickname: data.nickname,
              profilePictureUrl: data.profilePictureUrl,
            },
          };

          setPopupDataFollowers({
            nickname: data.nickname,
            profilePictureUrl: data.profilePictureUrl,
          });


            const notificationQueue = JSON.parse(localStorage.getItem('notificationQueue')) || [];
    
            notificationQueue.push({
              nickname: data.nickname,
              profilePictureUrl: data.profilePictureUrl,
              type: 'follow',
            });
    
            localStorage.setItem('notificationQueue', JSON.stringify(notificationQueue));
            window.dispatchEvent(new Event("storageNotificationQueue"));
          

          setShowPopupFollowers(true);

          setTimeout(() => {
            setShowPopupFollowers(false);
          }, 10000);

          return newFollowers;
        });
      }
    });

    return () => {
      socket.off('follow');
    };
  }, [socket, showFollows]);

  return (
    <SocketFollowsContext.Provider value={{
      userFollowers,
      showPopupFollowers,
      popupDataFollowers,
      showFollows,
      setShowFollows
    }}>
      {children}
    </SocketFollowsContext.Provider>
  );
};

export default SocketFollowsContext;
