import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // تحميل ملفات الترجمة من مجلد `public/locales`
  .use(LanguageDetector) // كشف لغة المستخدم تلقائيًا
  .use(initReactI18next) // التكامل مع React
  .init({
    supportedLngs: ['en', 'ar'], // اللغات المدعومة
    fallbackLng: 'ar', // اللغة الافتراضية
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // مسار ملفات الترجمة
    },
    interpolation: {
      escapeValue: false, // لمنع مشاكل XSS عند استخدام React
    },
  });

export default i18n;
