import React from 'react';
import { useSocketGifts } from '../contexts/SocketGiftsContext';
import { HiRefresh } from '../../utils/icons';


const Top3Gifters = () => {
  const { resetTopGifters } = useSocketGifts();
  let  topGifters = JSON.parse(localStorage.getItem("topGifters") || "[]");
  
    // ✅ تأكد أن `topGifters` مصفوفة
    if (!Array.isArray(topGifters)) {
      topGifters = [];
    }

  return (
    <div className="popup mx-auto bg-success rounded-3 px-3 py-2 mb-1">
      <div className="position-relative d-flex align-items-center mb-2">
        <button
          className="button-icon rotate-left"
          onClick={resetTopGifters}
        >
          <span className="span-icon"><HiRefresh /></span>
        </button>
        <h5
          className="fontcolorYellow text-center position-absolute w-100"
          style={{ top: '0', left: '0', zIndex: 0 }}
        >
          توب 3 داعمين
        </h5>
      </div>
      <ul className="fontcolorWhite list-unstyled text-center p-0">
        {topGifters.map((gifter, index) => (
          <li key={index} className="d-flex mb-2">
            <span className="col-1 align-self-center">{index + 1}.</span>
            <img src={gifter.profilePictureUrl} alt={gifter.nickname} className="rounded-circle align-self-center" width="45" height="45" />
            <span className="col-7 align-self-center">{gifter.nickname}</span>
            <span className="col-2 align-self-center text-danger">
              <span className='fontcolorYellow'>{gifter.totalGiftCount}</span> &#x1F48E;
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Top3Gifters;
