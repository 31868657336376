import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import MainHeader from './components/Main/MainHeader';
import GameHeader from './components/Game/GameHeader';
import GameWrapper from './components/Game/GameWrapper';
import Home from './components/Main/Home';
import Login from './components/Main/Login';
import AdminDashboard from './components/Admin/AdminDashboard';
import EarnDashboard from './components/Admin/EarnDashboard';
import Dashboard from './components/Game/Dashboard';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import InstallPrompt from './components/InstallPrompt';
import BrainChallenge from './components/BrainChallenge/BrainChallenge';
import TeamsChallenge from './components/TeamsChallenge/TeamsChallenge';
import MillionChallenge from './components/MillionChallenge/MillionChallenge';
import DifferenceChallenge from './components/DifferenceChallenge/DifferenceChallenge';
import EyeChallenge from './components/EyeChallenge/EyeChallenge';
import MemChallenge from './components/MemChallenge/MemChallenge';

const API_URL = process.env.REACT_APP_API_URL;

const App = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const language = localStorage.getItem('language') || 'ar';
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [newVersion, setNewVersion] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin") {
      document.body.setAttribute("data-page", "admin");
    } else {
      document.body.removeAttribute("data-page");
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const res = await axios.get("/version.json", { cache: "no-cache" });
        setCurrentVersion(res.data.version);
        setNewVersion(res.data.version);
      } catch (error) {
        console.error("⚠ خطأ أثناء جلب الإصدار الجديد:", error);
      }
    };

    fetchVersion(); // جلب الإصدار عند تحميل الصفحة
    const interval = setInterval(fetchVersion, 21600000); // تحديث كل 6 ساعات

    return () => clearInterval(interval); // تنظيف التايمر عند تفكيك المكون
  }, []); // ✅ لا يوجد شرط، وسيتم استدعاؤه دائمًا بنفس الترتيب


  useEffect(() => {
    const beforeInstallHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallHandler);
    window.addEventListener('appinstalled', () => {
      console.log('تم تثبيت التطبيق');
      setShowInstallButton(false);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallHandler);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "ar";
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);


  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);

    // تحديث اتجاه النص في الصفحة
    document.body.classList.remove('rtl', 'ltr');
    document.body.classList.add(language === 'ar' ? 'rtl' : 'ltr');
  }, [language, i18n]);

  useEffect(() => {
    const updateUserData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const storedUserId = localStorage.getItem("userId");

      if (token && storedUserId) {
        setIsLoggedIn(true);
        try {
          const response = await axios.get(`${API_URL}/api/users/${storedUserId}`);
          setUserData(response.data);
        } catch (error) {
          console.error("❌ خطأ أثناء جلب بيانات المستخدم:", error);
        }
      }
      setLoading(false);
    };

    updateUserData();
    window.addEventListener("storage", updateUserData);
    return () => window.removeEventListener("storage", updateUserData);
  }, []);

  const handleLogin = (id, username, is_admin) => {
    if (!id) {
      console.error("⚠ خطأ: لم يتم استلام userId صحيح!");
      return;
    }
    localStorage.setItem("userId", id);
    localStorage.setItem("username", username);
    localStorage.setItem("is_admin", is_admin);
    setIsLoggedIn(true);
    window.dispatchEvent(new Event("storage"));
  };

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
  
    // ✅ التأكد من إعادة التوجيه إلى صفحة تسجيل الدخول بعد تسجيل الخروج
    navigate('/', { replace: true });
  }, [navigate]);
  

  if (loading) {
    return <p>جارٍ تحميل البيانات...</p>;
  }

  const userId = localStorage.getItem('userId'); // قراءة userId من localStorage

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`قرار المستخدم: ${outcome}`);
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  return (
    <div className={isLoggedIn ? 'container-game' : ''}>

      {!isLoggedIn && <MainHeader />}

      {/* عرض إشعار التثبيت إذا كان متاح */}
      {!isLoggedIn && showInstallButton && (
        <InstallPrompt
          onInstall={handleInstallClick}
          onClose={() => setShowInstallButton(false)}
        />
      )}

      {newVersion && currentVersion && newVersion !== currentVersion && (
        <div style={{ backgroundColor: "#ffcc00", padding: "10px", textAlign: "center" }}>
          <p>إصدار جديد متوفر: {newVersion}</p>
          <button
            onClick={async () => {
              if ('caches' in window) {
                const cacheNames = await caches.keys();
                await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
              }
              window.location.reload(true);
            }}
          >
            تحديث الآن
          </button>
        </div>
      )}

      <Routes>
        {/* إعادة التوجيه من الصفحة الرئيسية إلى الداشبورد إذا كان المستخدم مسجل الدخول */}
        <Route
          path="/"
          element={
            isLoggedIn
              ? <Navigate to={`/${userId}/dashboard`} />
              : <Home />
          }
        />

        {/* مسار تسجيل الدخول */}
        <Route
          path="/login"
          element={
            isLoggedIn
              ? <Navigate to={`/${userId}/dashboard`} />
              : <Login onLogin={handleLogin} />
          }
        />

        {/* مسارات المستخدمين المسجلين */}
        {isLoggedIn && (
          <Route path={`/:userId`} element={<GameWrapper handleLogout={handleLogout} />}>
            <Route path="dashboard" element={<Dashboard />} />
            {userData?.active ? (
              <>
                <Route path="brainchallenge/*" element={<BrainChallenge />} />
                <Route path="teamschallenge/*" element={<TeamsChallenge />} />
                <Route path="millionchallenge/*" element={<MillionChallenge />} />
                <Route path="differencechallenge/*" element={<DifferenceChallenge />} />
                <Route path="eyechallenge/*" element={<EyeChallenge />} />
                <Route path="memchallenge/*" element={<MemChallenge />} />
                {/* يمكن إضافة ألعاب أخرى بنفس النمط */}
              </>
            ) : (
              <Route path="*" element={<Navigate to={`/${userId}/dashboard`} />} />
            )}
          </Route>
        )}

  {/* مسار صفحة الإدارة مع GameHeader */}
  <Route
    path="/admin"
    element={
      <>
        <GameHeader handleLogout={handleLogout} />
        {localStorage.getItem("is_admin") === "1" ? <AdminDashboard /> : <Navigate to="/earn" />}
      </>
    }
  />

  {/* مسار صفحة الأرباح (EarnDashboard) */}
  <Route
    path="/earn"
    element={
      <>
        <GameHeader handleLogout={handleLogout} />
        <EarnDashboard />
      </>
    }
  />

        {/* إعادة توجيه المستخدم غير المسجل إلى تسجيل الدخول */}
        {!isLoggedIn && (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>

    </div>
  );
};

export default App;
