import React, { useState, useEffect, useRef } from 'react';
import { useSocket } from '../contexts/SocketContext';
import { FaTimes } from '../../utils/icons';

const AskAudience = ({ onFinish, currentQuestion = '', questionOptions = [] }) => {
  const { socket } = useSocket();
  const [votes, setVotes] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(30);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const timerSound = useRef(new Audio('/sounds/timer-sound.mp3'));

  useEffect(() => {
    timerSound.current.volume = 0.5;
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      timerSound.current.play().catch(error => console.warn('Error playing sound:', error));
      const timer = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowCloseButton(true);
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (!socket) return;

    const handleChat = (data) => {
      let commentText = data?.data?.comment?.toLowerCase().trim();
      const userId = data?.data?.userId;
      const nickname = data?.data?.nickname;
      const profilePictureUrl = data?.data?.profilePictureUrl || '';

      if (!commentText) return;

      // ✅ تحويل الأرقام العربية إلى إنجليزية
      const convertArabicNumbers = (str) => {
        const arabicToEnglishMap = {
          '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
          '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
        };
        return str.replace(/[٠-٩]/g, match => arabicToEnglishMap[match]);
      };

      commentText = convertArabicNumbers(commentText);

      // ✅ استخراج الرقم إذا كانت الرسالة تبدأ بكلمة "جواب"
      const match = commentText.match(/^جواب\s?(\d)$/);
      if (match) {
        const digit = parseInt(match[1], 10);

        setVotes((prevVotes) => {
          const updatedVotes = { ...prevVotes };

          // ✅ منع التعديل: إذا كان المستخدم قد صوّت بالفعل، لا تغيّر تصويته
          const hasVoted = Object.values(updatedVotes).some(voters =>
            voters.some(voter => voter.userId === userId)
          );

          if (hasVoted) {
            console.log(`🚫 المستخدم ${nickname} صوّت مسبقًا، لن يتم قبول تصويته الجديد.`);
            return prevVotes;
          }

          // ✅ إضافة التصويت الأول فقط
          if (!updatedVotes[digit]) {
            updatedVotes[digit] = [];
          }

          updatedVotes[digit].push({ userId, nickname, profilePictureUrl });
          return updatedVotes;
        });
      }
    };

    socket.on('chat', handleChat);
    return () => socket.off('chat', handleChat);
  }, [socket]);

  return (
    <div className="popup-overlay">
      <div className="ask_audience">
        {/* الشريط العلوي */}
        <div className="row py-2 px-3">
          <h6 className="col-7 fontcolorWhite fw-bold m-auto">
            اكتب <span className="fontcolorYellow fw-bold">جواب</span> <span className="fontcolorYellow fw-bold">1,2,3,4</span>
          </h6>
          <h2 className="col bgtransparent text-center fw-bold m-auto">
            {timeRemaining}
          </h2>
          {showCloseButton && (
            <div className="col-2 text-center">
              <button onClick={() => onFinish(votes)} className="button-icon rotate-right mx-2">
                <span className="span-icon"><FaTimes /></span>
              </button>
            </div>
          )}
        </div>

        {/* نص السؤال */}
        <h6 className="fontcolorWhite fw-bold px-3 py-1">{currentQuestion}</h6>

        {/* عرض خيارات التصويت */}
        <div className="custom-scrollbar slow-motion px-1" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {questionOptions.map((option, index) => {
            // index يبدأ من 0 → المفتاح في votes سيكون index+1
            const digitKey = index + 1;
            const votersList = votes[digitKey] || [];

            return (
              <div key={digitKey} className="vote-group mb-4 borderYellow border border-2 rounded">
                <div className="row p-1 mb-2 mx-auto border-bottom border-1">
                  <h5 className="col-2 m-0 fontcolorWhite">
                    {digitKey} <span className="fontcolorYellow fw-bold">|</span>
                  </h5>
                  <h5 className="col m-0 text-center fontcolorWhite">
                    {option || `Option ${digitKey}`}
                  </h5>
                  <h5 className="col-2 m-0 fontcolorWhite">
                    <span className="fontcolorYellow fw-bold">|</span> {votersList.length}
                  </h5>
                </div>
                {/* عرض المصوتين */}
                <div className="d-flex flex-nowrap overflow-auto custom-scrollbar" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                  {votersList.map((vote) => (
                    <div key={vote.userId} className="col-3 col-sm-3 col-md-3 d-flex flex-column align-items-center text-center">
                      <img
                        src={vote.profilePictureUrl || 'default-avatar.png'}
                        alt={vote.nickname}
                        className="avatar img-fluid rounded-circle mb-2"
                        style={{ maxWidth: '30px', height: '30px' }}
                      />
                      <span className='nickname fontcolorWhite'>{vote.nickname}</span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AskAudience;
