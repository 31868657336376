import React, { useState, useEffect } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await axios.get(`${API_URL}/api/users`);
      setUsers(res.data);
    } catch (error) {
      console.error("❌ خطأ أثناء جلب المستخدمين:", error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      await axios.post(`${API_URL}/api/users/create`, newUser);
      setMessage({ type: "success", text: "✅ تم إنشاء المستخدم بنجاح!" });
      setTimeout(() => setMessage(null), 3000);
      setNewUser({ username: "", password: "" });
      fetchUsers();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setMessage({ type: "error", text: "⚠ اسم المستخدم مستخدم بالفعل، اختر اسمًا آخر" });
        setTimeout(() => setMessage(null), 3000);
      } else {
        setMessage({ type: "error", text: "❌ فشل في إنشاء المستخدم" });
        setTimeout(() => setMessage(null), 3000);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // ✅ تنفيذ الدفع وتصفير الرصيد
  const handlePayment = async (id) => {
    if (!window.confirm("هل أنت متأكد من دفع الرصيد لهذا المستخدم؟")) return;

    try {
      await axios.put(`${API_URL}/api/users/pay/${id}`);
      setMessage({ type: "success", text: "✅ تم الدفع بنجاح!" });
      setTimeout(() => setMessage(null), 3000);
      fetchUsers(); // تحديث البيانات بعد الدفع
    } catch (error) {
      setMessage({ type: "error", text: "❌ فشل في الدفع" });
      setTimeout(() => setMessage(null), 3000);
      console.error(error);
    }
  };

  const handleUpdateUser = async (id, field, value) => {
    if (field === "password" && !value) return;

    try {
      await axios.put(`${API_URL}/api/users/${id}`, { [field]: value });
      setUsers(users.map(user => (user.id === id ? { ...user, [field]: value } : user)));
    } catch (error) {
      console.error(`❌ خطأ أثناء تحديث ${field} للمستخدم:`, error);
    }
  };

  const handleDeleteUser = async (id) => {
    if (!window.confirm("هل أنت متأكد من حذف هذا المستخدم؟")) return;
    try {
      await axios.delete(`${API_URL}/api/users/${id}`);
      setUsers(users.filter(user => user.id !== id));
    } catch (error) {
      console.error("❌ خطأ أثناء حذف المستخدم:", error);
    }
  };

  return (
    <div className="mx-4 mt-3">
      <h2 className="text-center mb-2">لوحة تحكم المشرف</h2>

      {message && (
        <div className={`alert ${message.type === "success" ? "alert-success" : "alert-danger"}`}>
          {message.text}
        </div>
      )}

      <form onSubmit={handleCreateUser} className="mb-4">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <label className="col-3 form-label fontcolorWhite">اسم المستخدم:</label>
          <input
            type="text"
            className="form-control"
            value={newUser.username} // يجب أن يبقى فارغًا
            placeholder="أدخل اسم المستخدم"
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            required
          />
        </div>

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <label className="col-3 form-label fontcolorWhite">كلمة المرور:</label>
          <input
            type="password"
            className="form-control"
            value={newUser.password} // يجب أن يبقى فارغًا
            placeholder="أدخل كلمة المرور"
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? "جارٍ الإنشاء..." : "إنشاء"}
        </button>
      </form>


      <h3 className="text-center mb-2">المستخدمون</h3>
      <div className="custom-scrollbar" style={{ maxHeight: "350px", overflowY: "auto", display: "block" }}>
        <table className="table table-striped text-center">
          <thead style={{ position: "sticky", top: "0" }}>
            <tr>
              <th>تيكتوك</th>
              <th>اسم</th>
              <th>كلمة</th>
              <th>حالة</th>
              <th>نشط</th>
              <th>اشترى؟</th>
              <th>رصيد</th>
              <th>الدفع؟</th>
              <th>حذف</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>
                  <a
                    className={`btn btn-success btn-sm`}
                    href={`https://www.tiktok.com/@${user.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TIK
                  </a>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    style={{ minWidth: "100px" }}
                    value={user.username}
                    onChange={(e) => handleUpdateUser(user.id, "username", e.target.value)}
                  />
                </td>

                <td>
                  <input
                    type="password"
                    className="form-control form-control-sm"
                    placeholder="••••••"
                    onChange={(e) => handleUpdateUser(user.id, "password", e.target.value)}
                  />
                </td>

                <td>
                  <button
                    className={`btn ${user.active ? "btn-warning" : "btn-warning"} btn-sm`}
                    onClick={() => handleUpdateUser(user.id, "active", user.active ? 0 : 1)}
                  >
                    {user.active ? "✅" : "❌"}
                  </button>
                </td>

                <td>
                  <input
                    type="date"
                    className="form-control form-control-sm"
                    style={{ maxWidth: "120px" }}
                    value={user.active_until ? user.active_until.split("T")[0] : ""}
                    onChange={(e) => handleUpdateUser(user.id, "active_until", e.target.value)}
                  />
                </td>

                {/* زر تحديث has_purchased */}
                <td>
                  <button
                    className={`btn ${user.has_purchased ? "btn-warning" : "btn-warning"} btn-sm`}
                    onClick={() => handleUpdateUser(user.id, "has_purchased", user.has_purchased ? 0 : 1)}
                  >
                    {user.has_purchased ? "✅" : "❌"}
                  </button>
                </td>
                <td>${user.balance.toFixed(0)}</td>
                <td>
                  {user.balance > 0 ? (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handlePayment(user.id)}
                    >
                      ادفع
                    </button>
                  ) : (
                    <span className="text-success fw-bold">مدفوع</span>
                  )}
                </td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteUser(user.id)}>🗑</button>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
