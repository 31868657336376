import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MemSettings = () => {
  const { userId } = useParams();
  const [totalQuestions, settotalQuestions] = useState(10);
  const [timer, setTimer] = useState(15);
  const [timerAnswer, setTimerAnswer] = useState(15);
  const navigate = useNavigate();
  const [hashtag, setHashtag] = useState('');
  const [totalShapes, setTotalShapes] = useState(18);
  const { t } = useTranslation();
    const [isAutoMode, setIsAutoMode] = useState(false);
  

  useEffect(() => {
    // Set the translated value when the component mounts
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

      useEffect(() => {
        const storageKey = `mem_${userId}`;
        // Empty the storage key but not delete it
        localStorage.setItem(storageKey, '');
      }, [userId]);


  const handleTotalShapes = (e) => {
    setTotalShapes(parseInt(e.target.value));
  };

  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert('Please enter a valid number of rounds.');
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert('Please enter a valid timer value.');
      return;
    }
    if (timerAnswer <= 0 || isNaN(timerAnswer)) {
      alert('Please enter a valid timer value.');
      return;
    }

    navigate(`/${userId}/memchallenge/memquestion?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=1&timer=${timer}&timerAnswer=${timerAnswer}&totalShapes=${totalShapes}&isAutoMode=${isAutoMode}`);
  };

  return (
    <div className="container mt-1">
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('hashtag')}</label>
          <input 
            type="text" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="hashtag" 
            value={hashtag} 
            onChange={e => setHashtag(e.target.value)} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('total_rounds')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="totalQuestions" 
            value={totalQuestions} 
            onChange={e => settotalQuestions(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('round_time')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="timer" 
            value={timer} 
            onChange={e => setTimer(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timerAnswer" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('round_time_answer')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="timerAnswer" 
            value={timerAnswer} 
            onChange={e => setTimerAnswer(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-1 row align-items-between">
          <label htmlFor="totalShapes" className="form-label col-5 col-sm-5  align-middle fontcolorWhite"> {t('totalShapes')} ({totalShapes})</label>
          <input
            type="range"
            className="px-0 col-7 col-sm-7"
            id="totalShapes"
            min="1"
            max="24"
            value={totalShapes}
            onChange={handleTotalShapes}
          />
        </div>
        <div className="mb-1">
          <label htmlFor="autoMode" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('auto_mode')}</label>
          <input
            type="checkbox"
            id="autoMode"
            checked={isAutoMode}
            onChange={(e) => setIsAutoMode(e.target.checked)}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="str col-12 d-flex justify-content-center mt-1">
            <button onClick={handleStartGame} style={{width: '70px', height: '70px'}} id="start" className="fancy button">
              <h5 className="m-0" style={{ position: 'relative', zIndex: '1'}}>{t('start')}</h5>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemSettings;

