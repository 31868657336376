import io from 'socket.io-client';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
let socket = null;

export const getSocket = () => {
  if (!socket || socket.disconnected) {
    console.log("🟢 إنشاء اتصال WebSocket جديد...");
    socket = io(API_URL, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 3000,
    });

    socket.on('connect', async () => {
      console.log("✅ تم الاتصال بنجاح!");
    
      sessionStorage.setItem("socketActive", "true");
      const userId = localStorage.getItem('userId');
    
      if (!userId) {
        console.warn("⚠️ لا يوجد userId في localStorage!");
        return;
      }
    
      try {
        const response = await axios.get(`${API_URL}/api/tiktok/status/${userId}`, {
          timeout: 5000, // ⏳ تحديد مهلة للتأكد من أن الطلب لا يظل معلقًا
        });
    
        if (!response.data) {
          throw new Error("⚠️ الرد على الطلب كان فارغًا!");
        }
    
        const { connected, roomId } = response.data;
    
        if (connected && roomId) {
          console.log(`📡 الانضمام إلى الغرفة: room-${userId} (TikTok RoomID: ${roomId})`);
          socket.emit('joinRoom', { userId });
        } else {
          console.warn("⚠️ لم يتم العثور على RoomID صالح.");
        }
    
        localStorage.setItem('connectionStatus', connected ? 'connected' : 'disconnected');
        window.dispatchEvent(new Event("tiktokStatusUpdate"));
    
      } catch (error) {
        console.error("⚠️ خطأ أثناء جلب حالة TikTok:", error?.message || error);
        localStorage.setItem('connectionStatus', 'error');
        window.dispatchEvent(new Event("tiktokStatusUpdate"));
      }
    });
    socket.on('ownerInfo', (data) => {
      // console.log("📡 استقبال بيانات المضيف:", data);
    
      if (data.ownerAvatarUrl) {
        console.log("📸 تحديث صورة المضيف:", data.ownerAvatarUrl);
        localStorage.setItem('hostPic', data.ownerAvatarUrl);
        window.dispatchEvent(new Event("storagehostPic")); // إشعار المكونات الأخرى
      }
    
      if (data.ownerNickname) {
        console.log("👤 تحديث اسم المضيف:", data.ownerNickname);
        localStorage.setItem('hostName', data.ownerNickname);
        window.dispatchEvent(new Event("storageOwnerNickname")); // إشعار المكونات الأخرى
      }
    });
    

    socket.on('disconnect', () => {
      console.log("❌ تم قطع الاتصال.");
      sessionStorage.removeItem("socketActive");
      localStorage.setItem('connectionStatus', 'disconnected');
      window.dispatchEvent(new Event("tiktokStatusUpdate"));
    });

    socket.on('tiktokConnectionStatus', (data) => {
      // console.log("📡 تحديث حالة TikTok:", data);
      localStorage.setItem('connectionStatus', data.connected ? 'connected' : 'disconnected');
      window.dispatchEvent(new Event("tiktokStatusUpdate"));
    });
  } else {
    console.log("🔵 استخدام اتصال WebSocket الموجود بالفعل...");
  }

  return socket;
};

// ✅ تحقق من حالة TikTok كل 5 ثوانٍ
export const pollTikTokStatus = () => {
  const userId = localStorage.getItem('userId');
  let failedAttempts = 0;
  const maxAttempts = 3; // عدد المحاولات قبل التوقف
  const retryDelay = 30000; // انتظر 30 ثانية بعد استنفاد المحاولات

  const checkStatus = async () => {
    if (!userId) return;

    try {
      const response = await axios.get(`${API_URL}/api/tiktok/status/${userId}`);
      const { connected } = response.data;
      const newStatus = connected ? 'connected' : 'disconnected';

      if (localStorage.getItem('connectionStatus') !== newStatus) {
        console.log(`🔄 تغيرت حالة TikTok إلى: ${newStatus}`);
        localStorage.setItem('connectionStatus', newStatus);
        window.dispatchEvent(new Event("tiktokStatusUpdate"));
      }

      failedAttempts = 0; // إعادة تعيين المحاولات الفاشلة بعد نجاح الاتصال

    } catch (error) {
      failedAttempts++;
      console.error(`⚠️ محاولة ${failedAttempts} فشلت:`, error.message || error);

      if (failedAttempts >= maxAttempts) {
        console.warn(`🚨 استنفدت جميع المحاولات (${maxAttempts}). سيتم إيقاف المحاولات لفترة.`);
        setTimeout(() => {
          console.log("♻️ إعادة المحاولة بعد انتظار...");
          failedAttempts = 0; // إعادة تعيين العداد بعد التأخير
          checkStatus(); // إعادة المحاولة
        }, retryDelay);
        return;
      }
    }

    setTimeout(checkStatus, 5000); // إعادة الفحص كل 5 ثواني
  };

  checkStatus();
};

