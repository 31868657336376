import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';

const TeamsContext = createContext({
  members: [],
  redTeam: [],
  blueTeam: [],
  addMember: () => {},
  removeMember: () => {},
  isInAnyTeam: () => false,
  updateLikePoints: () => {},
  updateGiftPoints: () => {},
});

export const useTeams = () => useContext(TeamsContext);

export const TeamsProvider = ({ children }) => {
  const [members, setMembers] = useState([]);

  // ✅ تحميل البيانات من `localStorage` عند بدء التطبيق
  useEffect(() => {
    const storedMembers = JSON.parse(localStorage.getItem("membersTeams") || "[]");
    setMembers(storedMembers);
  }, []);

  useEffect(() => {
    const handleStorageUpdate = () => {
        const updatedMembers = JSON.parse(localStorage.getItem("membersTeams") || "[]");
        setMembers(updatedMembers);
    };

    // ✅ تحديث البيانات عند أي تغيير في `localStorage`
    window.addEventListener("storageTeamsUpdate", handleStorageUpdate);

    return () => {
        window.removeEventListener("storageTeamsUpdate", handleStorageUpdate);
    };
}, []);


  // ✅ تحديث `members` عند أي تغيير في `localStorage`
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedMembers = JSON.parse(localStorage.getItem("membersTeams") || "[]");
      setMembers(prevMembers => updatedMembers.map(member => {
        const existingMember = prevMembers.find(m => m.userId === member.userId);
    
        return {
            ...member,
            correctQuestions: Array.isArray(member.correctQuestions) && member.correctQuestions.length > 0
                ? [...member.correctQuestions] 
                : (existingMember?.correctQuestions?.length > 0 
                    ? [...existingMember.correctQuestions] 
                    : new Array(member.totalQuestions || 10).fill("")),
        };
    }));
    
        };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  // ✅ وظيفة جديدة لتصفير `members` مباشرة
  const clearMembers = useCallback(() => {
    setMembers([]); // ✅ تصفير القائمة محليًا
    // localStorage.removeItem("membersTeams"); // ✅ حذف البيانات من `localStorage`
  }, []);

  // ✅ تحديث `redTeam` و `blueTeam` عند تغيير `members` مع ترتيبهم حسب `totalPoints`
  const redTeam = useMemo(() => 
    [...members.filter(member => member.team === "red")].sort((a, b) => b.totalPoints - a.totalPoints), 
    [members]
  );

  const blueTeam = useMemo(() => 
    [...members.filter(member => member.team === "blue")].sort((a, b) => b.totalPoints - a.totalPoints), 
    [members]
  );

  // ✅ إضافة عضو جديد إلى الفريق
  const addMember = (user, teamColor) => {
    const newMember = { 
      ...user, 
      team: teamColor, 
      totalPoints: 0, 
      giftPoints: 0, 
      likePoints: 0, 
      questionPoints: 0,
      correctQuestions: [],
      totalQuestions: 0
    };

    setMembers(prev => {
      const updatedMembers = [...prev.filter(m => m.userId !== user.userId), newMember];
      localStorage.setItem("membersTeams", JSON.stringify(updatedMembers));
      return updatedMembers;
    });
  };

  // ✅ حذف عضو من الفريق
  const removeMember = (userId) => {
    setMembers(prev => {
      const updatedMembers = prev.filter(m => m.userId !== userId);
      localStorage.setItem("membersTeams", JSON.stringify(updatedMembers));
      return updatedMembers;
    });
  };

  // ✅ التحقق إذا كان المستخدم ضمن أي فريق
  const isInAnyTeam = (userId) => members.some(member => member.userId === userId);

  // ✅ تحديث نقاط الإعجابات (`likePoints`) و `totalPoints`
  const updateLikePoints = (userId, likeCount) => {
    setMembers(prev => {
      const updatedMembers = prev.map(member =>
        member.userId === userId
          ? { ...member, likePoints: member.likePoints + likeCount, totalPoints: member.totalPoints + likeCount }
          : member
      );

      localStorage.setItem("membersTeams", JSON.stringify(updatedMembers));
      return updatedMembers;
    });
  };

  // ✅ تحديث نقاط الهدايا (`giftPoints`) و `totalPoints`
  const updateGiftPoints = (userId, giftPoints) => {
    setMembers(prev => {
      const updatedMembers = prev.map(member =>
        member.userId === userId
          ? { ...member, giftPoints: member.giftPoints + giftPoints, totalPoints: member.totalPoints + giftPoints }
          : member
      );

      localStorage.setItem("membersTeams", JSON.stringify(updatedMembers));
      return updatedMembers;
    });
  };


  return (
    <TeamsContext.Provider value={{
      members,
      redTeam,  // ✅ الفريق الأحمر مرتب حسب `totalPoints`
      blueTeam, // ✅ الفريق الأزرق مرتب حسب `totalPoints`
      addMember,
      removeMember,
      isInAnyTeam,
      updateLikePoints,
      updateGiftPoints,
      clearMembers
    }}>
      {children}
    </TeamsContext.Provider>
  );
};
