import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const TeamsStartGame = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const username = localStorage.getItem('username') || "غير معروف";

  return (
    <div className="container text-center">
      <h2 className="mb-1 fontcolorYellow fw-bold">{t('start_challenge')}</h2>
      <h3 className="fontcolorWhite">{t('streamer')} <span className="fontcolorYellow">{username}</span></h3>
      <div className="games text-center mt-1">
        <div className="row justify-content-around p-2 ">
          <Link
            to={`/${userId}/teamschallenge/teamssettings`}
            state={{ username: username }}
            className="col-md-auto bgTransparent fontcolorWhite p-3 rounded-3 text-center"
            style={{ width: '18rem', textDecoration: 'none', color: 'inherit' }}
          >
            <div className="">
              <h5 className=" fontcolorYellow fw-bold">{t('brain_challenge')}</h5>
              <p className="card-text mb-1" dangerouslySetInnerHTML={{ __html: t('description_teams') }}></p>
              <h6 className="fontcolorYellow">{t('add_hashtag')}</h6>
              <p className="fw-bold mb-1">{t('example_q')}<span className='fontcolorYellow'>{t('example_q_2')}</span></p>
            </div>
            <button className='fancy rotate-right'><span className="span-icon" style={{ width: '100px' }}>{t('start')}</span></button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TeamsStartGame;
