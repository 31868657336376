import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { SocketProvider } from '../contexts/SocketContext';
import GameHeader from './GameHeader';
import { CameraProvider, CameraView } from '../contexts/CameraContext';
import Footer from './Footer';
import { SoundProvider } from '../contexts/SoundContext';

const GameWrapper = memo(({ handleLogout }) => {
  // console.log("✅ GameWrapper تم تحميله");

  return (
    <SocketProvider>
      <SoundProvider>
        <CameraProvider>
          <div className="game-wrapper d-flex flex-column align-items-center">
            {/* الشريط العلوي */}
            <GameHeader handleLogout={handleLogout} />
            {/* عرض الكاميرا */}
            <CameraView />
            {/* المحتوى المتغير (المسارات الفرعية) */}
            <div className="game-content col-12 mt-1">
              <Outlet />
            </div>
            {/* الفوتر */}
            <Footer />
          </div>
        </CameraProvider>
      </SoundProvider>
    </SocketProvider>
  );
});

export default GameWrapper;
