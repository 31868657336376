import React, { useEffect, useState } from 'react';
import { HiRefresh } from '../../utils/icons';

const Top10Likers = () => {
  const [topLikers, setTopLikers] = useState(() => 
    JSON.parse(localStorage.getItem('topLikers') || "[]")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      console.log("🔄 topLikers updated from localStorage!");
      setTopLikers(JSON.parse(localStorage.getItem("topLikers") || "[]"));
    };

    window.addEventListener("storageTopLikers", handleStorageChange);
    return () => {
      window.removeEventListener("storageTopLikers", handleStorageChange);
    };
  }, []);

  const resetTopLikers = () => {
    localStorage.setItem('top20Likers', JSON.stringify({}));
    localStorage.setItem('topLikers', JSON.stringify({}));
    localStorage.setItem('userLikes', JSON.stringify({}));    
    console.log("🔄 Reset top likers.");
    setTopLikers([]); // لتحديث الواجهة فورًا
  };

  const getBorderStyle = (index) => {
    if (index === 0) return "gold-border";
    if (index === 1) return "silver-border";
    if (index === 2) return "bronze-border";
    return "";
  };

  return (
    <div className="popup mx-auto bg-success rounded-3 px-3 py-2 mb-1">
      <div className="position-relative d-flex align-items-center mb-2">
        <button className="button-icon rotate-left" onClick={resetTopLikers}>
          <span className="span-icon"><HiRefresh /></span>
        </button>
        <h5 className="fontcolorYellow text-center position-absolute w-100"
            style={{ top: '0', left: '0', zIndex: 0 }}>
          اعلى 10 تكبيس
        </h5>
      </div>
      <ul className="fontcolorWhite list-unstyled text-center p-0">
        {topLikers.map((liker, index) => (
          <li key={index} className="d-flex mb-2">
            <span className="col-1 align-self-center">{index + 1}.</span>
            <img
              src={liker.profilePictureUrl}
              alt={liker.nickname}
              className={`profile-pic ${getBorderStyle(index)}`}
            />
            <span className="col-7 align-self-center">{liker.nickname}</span>
            <span className="col-2 align-self-center text-danger ">
              <span className='fontcolorYellow'>{liker.totalLikeCount}</span> &hearts;
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Top10Likers;
