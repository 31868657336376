import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSound } from '../contexts/SoundContext';
import { useSocket } from '../contexts/SocketContext';

const TeamsQuestion = () => {
  const { userId } = useParams();
  const lng = localStorage.getItem('language');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'));
  const timer = parseInt(queryParams.get('timer'));
  const currentQuestionIndex = parseInt(queryParams.get('currentQuestionIndex')) || 0;
  const hashtag = queryParams.get('hashtag');
  const [questions, setQuestions] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [filteredComments, setFilteredComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timerRunning, setTimerRunning] = useState(true);
  const [correctAnswerNum, setCorrectAnswerNum] = useState(null);
  const [correctComment, setCorrectComment] = useState('');
  const level = queryParams.get('level');
  const timerIdRef = useRef(null);
  const { t } = useTranslation();
  const { playSound } = useSound();
  const [comments, setComments] = useState([]);
  const { socket } = useSocket();
  const receivedMessages = useRef(new Set()); // ✅ منع التكرار
  const storageKey = `quiz_${userId}`;
  const membersKey = "membersTeams";
  // ✅ تحويل الأرقام العربية إلى إنجليزية
  const convertArabicToEnglishNumbers = (str) => {
    const arabicToEnglishMap = {
      '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
      '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
    };
    return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
  };

  const getCorrectAnswerNum = (question) => {
    if (!question || !question.correct) return null;
    if (question.correct === question.first) return 1;
    if (question.correct === question.second) return 2;
    if (question.correct === question.third) return 3;
    if (question.correct === question.fourth) return 4;
    return null;
  };

  useEffect(() => {
    if (hashtag && correctAnswerNum !== null) {
      setCorrectComment(`${hashtag}${correctAnswerNum}`.toLowerCase());
      console.log("✅ Correct Comment Set:", `${hashtag}${correctAnswerNum}`.toLowerCase());
    }
  }, [hashtag, correctAnswerNum]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex >= 0) {
      setCorrectAnswerNum(getCorrectAnswerNum(questions[currentQuestionIndex]));
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    if (!socket) return;

    const handleChat = (data) => {
      if (data.data.uniqueId === "interactivetok.com") {
        console.log(data.data.nickname, '🎉 You got khaled a chat!', data.data.msgId, data.data.comment);
      }
      const msgId = data?.data?.msgId;
      if (!msgId || receivedMessages.current.has(msgId)) return;
      receivedMessages.current.add(msgId); // ✅ منع التكرار

      let commentText = data.data.comment?.toLowerCase().trim();
      commentText = convertArabicToEnglishNumbers(commentText.replace(/\s+/g, ''));

      if (!commentText.includes(hashtag.toLowerCase())) return;

      const newComment = {
        comment: commentText,
        userId: data.data.userId,
        nickname: data.data.nickname,
        profilePictureUrl: data.data.profilePictureUrl || '',
        followRole: data.data.followRole || 0,
        currentQuestionIndex: currentQuestionIndex
      };

      setComments((prev) => [...prev, newComment]);
    };

    socket.on('chat', handleChat);
    return () => {
      socket.off('chat', handleChat);
    };
  }, [socket, hashtag, currentQuestionIndex]);


  const insertIntoLocalStorage = useCallback(() => {
    const currentQuestionData = questions[currentQuestionIndex];
    if (!currentQuestionData) return;

    const storedData = JSON.parse(localStorage.getItem(storageKey) || '{}');
    const members = JSON.parse(localStorage.getItem(membersKey) || "[]");

    // ✅ التأكد من أن كل عضو لديه correctQuestions موجود ومعبأ
    members.forEach(member => {
        if (!Array.isArray(member.correctQuestions) || member.correctQuestions.length === 0) {
            member.correctQuestions = new Array(totalQuestions).fill(""); // ✅ ضبط القيم الافتراضية
        }

        if (!member.totalQuestions || member.totalQuestions === 0) {
            member.totalQuestions = totalQuestions; // ✅ ضبط `totalQuestions`
        }

        // ✅ ضمان أن correctQuestions تحتوي على العدد الكافي من الأسئلة
        while (member.correctQuestions.length < totalQuestions) {
            member.correctQuestions.push(""); // ✅ ملء الفراغات فقط بدلاً من إعادة تعيين البيانات
        }
    });

    // ✅ تتبع المستخدمين الذين أجابوا
    const userAnswered = new Set();

    comments.forEach(comment => {
        if (!comment.comment.includes(hashtag.toLowerCase())) return;

        const memberIndex = members.findIndex(member => member.userId === comment.userId);
        if (memberIndex !== -1) {
            userAnswered.add(comment.userId);
            const member = members[memberIndex];

            const isCorrect = comment.comment.toLowerCase() === correctComment;
            const answer = isCorrect ? "true" : "false";

            // ✅ تحديث إجابة السؤال الحالي فقط دون حذف الإجابات السابقة
            if (!member.correctQuestions[currentQuestionIndex] || member.correctQuestions[currentQuestionIndex] === "") {
                member.correctQuestions[currentQuestionIndex] = answer;
                member.currentQuestion = answer;
            }
        }
    });

    // ✅ تحويل إجابات الذين لم يجيبوا إلى "not"
    members.forEach(member => {
        if (!userAnswered.has(member.userId) && member.correctQuestions[currentQuestionIndex] === "") {
            member.correctQuestions[currentQuestionIndex] = "not";
            member.currentQuestion = "not";
        }
    });

    // ✅ تحديث النقاط بناءً على الإجابة
    members.forEach(member => {
        let pointsChange = 0;
        if (member.currentQuestion === "true") {
            pointsChange = 500;
        } else if (member.currentQuestion === "false" || member.currentQuestion === "not") {
            pointsChange = -500;
        }

        member.questionPoints = (member.questionPoints || 0) + pointsChange;
        member.totalPoints = (member.totalPoints || 0) + pointsChange;
    });

    // ✅ تحديث `localStorage`
    storedData[currentQuestionData.id] = {
        userId,
        quesId: currentQuestionData.id,
        hashtag,
        totalQuestions,
        timer,
        question: currentQuestionData.question,
        currentQuestionIndex,
        correctAnswer: currentQuestionData.correct,
        correctAnswerNum,
        correctComment,
        comments: comments,
    };

    localStorage.setItem(storageKey, JSON.stringify(storedData));
    localStorage.setItem(membersKey, JSON.stringify(members)); // ✅ حفظ بيانات الفرق بعد التعديل
    window.dispatchEvent(new Event("storageTeamsUpdate"));

}, [comments, correctAnswerNum, correctComment, currentQuestionIndex, hashtag, questions, timer, totalQuestions, userId, storageKey]);


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // console.log('Fetching questions for level:', level);

        const levels = {
          firstlevelUp: 'firstlevelUp',
          secondlevelUp: 'secondlevelUp',
          thirdlevelUp: 'thirdlevelUp',
          alllevels: ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'],
        };

        let currentLevelQuestions = {};

        // Fetch questions based on the level
        if (level === 'alllevels') {
          levels.alllevels.forEach((key) => {
            const levelQuestions = JSON.parse(localStorage.getItem(key) || '{}');
            currentLevelQuestions = { ...currentLevelQuestions, ...levelQuestions };
          });

        } else {
          const key = levels[level];
          currentLevelQuestions = JSON.parse(localStorage.getItem(key) || '{}');

        }

        // Convert the object to an array
        const questionArray = Object.values(currentLevelQuestions);

        if (!questionArray || questionArray.length === 0) {
          console.error(`No questions available for level: ${level}`);
          setQuestions([]);
          setLoading(false);
          return;
        }

        // Shuffle questions and limit to totalQuestions
        const shuffledQuestions = questionArray.sort(() => Math.random() - 0.5);
        const sessionQuestions = shuffledQuestions.slice(
          0,
          Math.min(totalQuestions, questionArray.length)
        );

        if (sessionQuestions.length < totalQuestions) {
          console.warn(
            `totalQuestions (${totalQuestions}) exceeds available questions (${questionArray.length}). Adjusting.`
          );
        }

        // console.log('Selected session questions:', sessionQuestions);
        setQuestions(sessionQuestions);

        // Handle current question's removal from localStorage
        const currentQuestionIndexObj = sessionQuestions[currentQuestionIndex];
        if (currentQuestionIndexObj) {
          const { id, question } = currentQuestionIndexObj;

          if (level === 'alllevels') {
            levels.alllevels.forEach((key) => {
              const levelQuestions = JSON.parse(localStorage.getItem(key) || '{}');
              const updatedLevelQuestions = Object.fromEntries(
                Object.entries(levelQuestions).filter(
                  ([, value]) => !(value.id === id && value.question === question)
                )
              );
              // console.log(`Remaining questions for ${key}:`, updatedLevelQuestions);
              localStorage.setItem(key, JSON.stringify(updatedLevelQuestions));
            });
          } else {
            const key = levels[level];
            const updatedLevelQuestions = Object.fromEntries(
              Object.entries(currentLevelQuestions).filter(
                ([, value]) => !(value.id === id && value.question === question)
              )
            );
            // console.log(`Remaining questions for ${key}:`, updatedLevelQuestions);
            localStorage.setItem(key, JSON.stringify(updatedLevelQuestions));
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [level, totalQuestions, currentQuestionIndex]);


  const handleQuestionEnd = useCallback(() => {
    if (questions.length === 0 || !questions[currentQuestionIndex]) {
      console.error('Current question data is undefined');
      return;
    }

    insertIntoLocalStorage();

    setTimeout(() => {
      navigate(
        `/${userId}/teamschallenge/teamsresult?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestionIndex=${currentQuestionIndex}&timer=${timer}&level=${level}`,
        { state: { comments: filteredComments } }
      );
    }, 3000);
  }, [currentQuestionIndex, filteredComments, hashtag, insertIntoLocalStorage, navigate, questions, timer, totalQuestions, userId, level]);

  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0 && timerRunning) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else if (timeLeft === 0 && timerRunning) {
        setTimerRunning(false);
        setTimeout(() => {
          handleQuestionEnd();
        }, 1000);
      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, timerRunning, handleQuestionEnd]);


  useEffect(() => {
    const regex = new RegExp(`^${hashtag.toLowerCase()}\\s?(\\d|[١٢٣٤])$`, 'i'); // تحويل الهاشتاغ إلى أحرف صغيرة
    const matchedComments = comments.filter((comment) =>
      regex.test(comment.comment.toLowerCase()) // تحويل التعليق إلى أحرف صغيرة قبل التحقق
    );

    const enhancedComments = matchedComments.map((comment) => {
      const match = comment.comment.toLowerCase().match(regex);
      if (match) {
        const answerNumber = match[1];
        let answer = '';
        if (questions[currentQuestionIndex]) { // ✅ تحقق من أن السؤال موجود قبل محاولة الوصول إليه
          if (answerNumber === '1' || answerNumber === '١') answer = questions[currentQuestionIndex]?.first || '';
          if (answerNumber === '2' || answerNumber === '٢') answer = questions[currentQuestionIndex]?.second || '';
          if (answerNumber === '3' || answerNumber === '٣') answer = questions[currentQuestionIndex]?.third || '';
          if (answerNumber === '4' || answerNumber === '٤') answer = questions[currentQuestionIndex]?.fourth || '';
        }

        return { ...comment, answer };
      }
      return comment;
    });

    setFilteredComments(enhancedComments);
  }, [comments, hashtag, questions, currentQuestionIndex]);



  useEffect(() => {
    setTimeLeft(timer);
    setShowCorrectAnswer(false);
    setFilteredComments([]);
    setTimerRunning(true);
  }, [currentQuestionIndex, timer]);

  useEffect(() => {
    if (timeLeft === 10) {
      playSound('timer')
    }
  }, [timeLeft, playSound]);

  useEffect(() => {
    if (timeLeft === timer - 1) {
      playSound('swing')
    }
  }, [timeLeft, playSound, timer]);

  useEffect(() => {
    if (timeLeft < 1) {
      setShowCorrectAnswer(true);
    }
  }, [timeLeft]);

  if (loading) return <p>{t('loading')}</p>;
  if (questions.length === 0) return <p>No questions available.</p>;
  if (!questions[currentQuestionIndex]) return <p>Loading question...</p>;

  return (
    <div className="max-width-300 mt-1">
      <div>
        <div className="fancy rotate-right ques" id="ques">
          {lng === 'en' ? (
            <h5 className="quesText">{questions[currentQuestionIndex].question}</h5>
          ) : (
            <h4 className="quesText">{questions[currentQuestionIndex].question}</h4>
          )}
        </div>
        <div className="ques-box">
          <div className="fancy questime d-flex justify-content-center">
            <span className="numbertime text-center">{timeLeft}</span>
          </div>
          <div className="fancy quesnum fw-bold d-flex justify-content-center">
            <span className="numberQues text-center">{t('question')} : {currentQuestionIndex + 1} / {totalQuestions}</span>
          </div>
        </div>
      </div>

      <div className={`list-group ${lng === 'ar' ? 'rtl' : 'ltr'}`}>
        <ul className="p-0 mb-1 mt-3">
          <li className={`answer fade-in d-flex align-items-center ${showCorrectAnswer && correctAnswerNum === 1 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className={`fw-bold mb-0 fontcolorWhite ${lng === 'ar' ? 'text-end me-2 ms-1' : 'text-start ms-2 me-1'}`}>1</h5>
            <h5 className="mb-0">|</h5>
            <h6 className="text-center mb-0 fontcolorWhite flex-grow-1">{questions[currentQuestionIndex].first}</h6>
          </li>
          <li className={`answer fade-out d-flex align-items-center ${showCorrectAnswer && correctAnswerNum === 2 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className={`fw-bold mb-0 fontcolorWhite ${lng === 'ar' ? 'text-end me-2 ms-1' : 'text-start ms-2 me-1'}`}>2</h5>
            <h5 className="mb-0">|</h5>
            <h6 className="text-center mb-0 fontcolorWhite flex-grow-1">{questions[currentQuestionIndex].second}</h6>
          </li>
          <li className={`answer fade-in d-flex align-items-center ${showCorrectAnswer && correctAnswerNum === 3 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className={`fw-bold mb-0 fontcolorWhite ${lng === 'ar' ? 'text-end me-2 ms-1' : 'text-start ms-2 me-1'}`}>3</h5>
            <h5 className="mb-0">|</h5>
            <h6 className="text-center mb-0 fontcolorWhite flex-grow-1">{questions[currentQuestionIndex].third}</h6>
          </li>
          <li className={`answer mb-0 fade-out d-flex align-items-center ${showCorrectAnswer && correctAnswerNum === 4 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className={`fw-bold mb-0 fontcolorWhite ${lng === 'ar' ? 'text-end me-2 ms-1' : 'text-start ms-2 me-1'}`}>4</h5>
            <h5 className="mb-0">|</h5>
            <h6 className="text-center mb-0 fontcolorWhite flex-grow-1">{questions[currentQuestionIndex].fourth}</h6>
          </li>
        </ul>
      </div>
      <div className="bgTransparent max-width-300 text-center rounded-4 mt-1 py-1">
        <h6 className="info">{t('add_hashtag')}</h6>
        <h6 className="info">{t('hashtag_today')} : <span className="fontcolorWhite">{hashtag}</span>, {t('example_q')} : <span className="fontcolorWhite">{hashtag} 1</span> </h6>
      </div>


    </div>
  );
};

export default TeamsQuestion;
