import React from 'react';
import { useTeams } from '../contexts/TeamsContext';

const TeamsSidebar = ({ teamColor, teamName }) => {
  const { redTeam, blueTeam } = useTeams();
  const teamMembers = teamColor === 'red' ? redTeam : blueTeam;

  return (
    <div 
      className="sidebar bgTransparent text-center mx-1 rounded-3"
      style={{ maxWidth: '70px', minWidth: '60px', border: `1px solid ${teamColor}`, color: teamColor }}
    >
      <h6 className="mb-1 row justify-content-center">{teamName} ({teamMembers.length})</h6>
      <ul className="list-unstyled p-0 w-100 custom-scrollbar" style={{ scrollbarWidth:'none' }}>
        {teamMembers.map((member, index) => (
          <li key={member.userId} className="d-flex flex-column align-items-center text-truncate mb-2"
          style={{
            paddingTop: index === 0 ? '8px' : 'none'
          }}
          >
            <img 
              src={member.profilePictureUrl} 
              alt={member.nickname} 
              className="rounded-circle" 
              width="30" 
              height="30" 
              style={{
                border: index === 0 ? '2px solid gold' : 'none', // ✅ بوردر ذهبي للأول
                padding: '0px',
                boxShadow: index === 0 ? '0 0 10px gold' : 'none', // ✅ إضافة تأثير التوهج
                width: index === 0 ? '40px' : 'none',
                height: index === 0 ? '40px' : 'none'
              }} 
            />
            <div className="text-white text-truncate text-center" style={{ fontSize: '10px' }}>
              <strong className='nickname' style={{ fontSize: '10px' }}>{member.nickname}</strong>
              <p className="mb-0 fontSize-12-10 fontcolorYellow fw-bold">{member.totalPoints}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeamsSidebar;
