import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MemStartGame from './MemStartGame';
import MemSettings from './MemSettings';
import MemQuestion from './MemQuestion';
import MemResult from './MemResult';
import MemLeaderboard from './MemLeaderboard';

const EyeChallenge = () => {
  return (
    <Routes>
      <Route path="MemStartGame" element={<MemStartGame />} />
      <Route path="MemSettings" element={<MemSettings />} />
      <Route path="MemQuestion" element={<MemQuestion />} />
      <Route path="MemResult" element={<MemResult />} />
      <Route path="MemLeaderboard" element={<MemLeaderboard />} />
    </Routes>
  );
};

export default EyeChallenge;
