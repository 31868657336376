import React, { useState, useEffect } from 'react';
import { useSocket } from '../contexts/SocketContext'; // ✅ استخدام WebSocket مباشرة
import { FaTimes } from '../../utils/icons';

const VotingPopup = ({ onClose }) => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState('');
    const [votingStarted, setVotingStarted] = useState(false);
    const { socket } = useSocket();
    const [votes, setVotes] = useState({});

    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options, newOption]);
            setNewOption('');
        }
    };

    const handleStartVoting = () => {
        if (question.trim() && options.length > 0) {
            setVotingStarted(true);
            setVotes({}); // ✅ إعادة ضبط التصويتات إلى كائن فارغ عند بدء التصويت
        }
    };

    const handleClose = () => {
        setVotingStarted(false);
        onClose();
    };

    // ✅ دالة تحويل الأرقام العربية إلى إنجليزية
    const convertArabicNumbers = (str) => {
        const arabicToEnglishMap = {
            '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
            '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
        };
        return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
    };

    useEffect(() => {
        if (!socket) return;

        const handleChat = (data) => {
            let commentText = data?.data?.comment?.toLowerCase().trim();
            const userId = data?.data?.userId;
            const nickname = data?.data?.nickname;
            const profilePictureUrl = data?.data?.profilePictureUrl || '';

            if (!commentText) return;

            // ✅ تحويل الأرقام العربية إلى إنجليزية قبل التحقق
            commentText = convertArabicNumbers(commentText);

            // ✅ استخراج الرقم من التعليق
            const match = commentText.match(/^\d+$/);
            if (match) {
                const digit = parseInt(match[0], 10);

                setVotes((prevVotes) => {
                    const updatedVotes = { ...prevVotes };

                    // إزالة المستخدم من جميع الخيارات السابقة
                    Object.keys(updatedVotes).forEach((key) => {
                        updatedVotes[key] = updatedVotes[key].filter((voter) => voter.userId !== userId);
                    });

                    // إضافة المستخدم إلى الخيار الجديد
                    if (!updatedVotes[digit]) {
                        updatedVotes[digit] = [];
                    }

                    updatedVotes[digit].push({ userId, nickname, profilePictureUrl });
                    return updatedVotes;
                });
            }
        };

        socket.on('chat', handleChat);
        return () => socket.off('chat', handleChat);
    }, [socket]);


    return (
        <div className="d-flex flex-column align-items-center">
            <div className="ask_audience p-2 d-flex flex-column">
                {!votingStarted ? (
                    <>
                        <h5 className="mb-3 text-center fontcolorYellow">انشئ تصويت</h5>
                        <div className="mb-3">
                            <label className="form-label fontcolorWhite">السؤال</label>
                            <input
                                type="text"
                                className="form-control"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder="اكتب سؤال"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label fontcolorWhite">الخيارات</label>
                            <div className="d-flex mb-2">
                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    value={newOption}
                                    onChange={(e) => setNewOption(e.target.value)}
                                    placeholder="اضف خيار"
                                />
                                <button className="btn btn-primary" onClick={handleAddOption}>
                                    اضف
                                </button>
                            </div>
                            <ul className="list-group p-0">
                                {options.map((option, index) => (
                                    <li key={index} className="list-group-item fontcolorYellow fw-bold">
                                        {index + 1}. {option}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={handleClose}>
                                إغلاق
                            </button>
                            <button className="btn btn-success" onClick={handleStartVoting}>
                                بدء التصويت
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="d-flex justify-content-between px-2">
                            <h5 className="border-bottom mb-1 pb-2">{question}</h5>
                            <button onClick={handleClose} className="button-icon rotate-right mx-2">
                                <span className="span-icon"><FaTimes /></span>
                            </button>
                        </div>
                        <h6 className="fontcolorWhite px-2">قم بالتصويت عبر كتابة الأرقام فقط <span className="fontcolorYellow">1,2,3</span> الخ...</h6>
                        <ul className="list-group p-0">
                            {options.map((option, index) => (
                                <li key={index} className="list-group-item bgTransparent">
                                    <div className="d-flex justify-content-between border-bottom pb-1">
                                        <span className="fontcolorYellow fw-bold">{index + 1}. {option}</span>
                                        <span className="fontcolorWhite fw-bold">{votes[index + 1]?.length || 0} صوت</span>
                                    </div>

                                    {/* ✅ إضافة شريط تمرير إذا كان هناك أكثر من 4 مصوتين */}
                                    <div className="d-flex flex-nowrap overflow-auto custom-scrollbar" style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                                        {votes[index + 1]?.map((voter) => (
                                            <div key={voter.userId} className="col-auto text-center m-1" style={{ minWidth: "60px" }}>
                                                <img
                                                    src={voter.profilePictureUrl || 'default-avatar.png'}
                                                    alt={voter.nickname}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                                <p className="fontcolorYellow m-0" style={{ fontSize: '0.8rem', maxWidth: '60px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {voter.nickname}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>

                    </>
                )}
            </div>
        </div>
    );
};

export default VotingPopup;
