import React, { createContext, useContext, useEffect, useState } from 'react';
import { getSocket, pollTikTokStatus } from '../../utils/socketInstance';
import { SocketLikesProvider } from './SocketLikesContext';
import { SocketFollowsProvider } from './SocketFollowsContext';
import { SocketGiftsProvider } from './SocketGiftsContext';

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = getSocket();
    setSocket(newSocket);

    // بدء فحص حالة TikTok
    pollTikTokStatus();

    console.log("📡 SocketContext loaded!");

    return () => {
      newSocket.disconnect();
      console.log("🔄 Cleaned up SocketContext.");
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {/* ✅ لف كل السياقات الفرعية داخل `SocketProvider` */}
      <SocketLikesProvider>
        <SocketFollowsProvider>
          <SocketGiftsProvider>
              {children}
          </SocketGiftsProvider>
        </SocketFollowsProvider>
      </SocketLikesProvider>
    </SocketContext.Provider>
  );
};

export default SocketContext;
