import React, { useState, useEffect } from 'react';
import SettingsPopup from './SettingsPopup';
import { FaPollH, IoMdSettings, BsHearts, IoGift } from '../../utils/icons';
import VotingPopup from './VotingPopup';
import Top10Likers from './Top10Likers';
import Top3Gifters from './Top3Gifters';
import Top20Likers  from './Top20Likers';


const Footer = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [showTop10Likers, setShowTop10Likers] = useState(false);
  const [showTop3Gifters, setShowTop3Gifters] = useState(false);
  const [showVotingPopup, setShowVotingPopup] = useState(false);
  const [showtop20Likers, setShowtop20Likers] = useState(() => {
    return JSON.parse(localStorage.getItem('showtop20Likers')) ?? true;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setShowtop20Likers(JSON.parse(localStorage.getItem('showtop20Likers')) ?? true);
    };

    window.addEventListener('storageShowtop20Likers', handleStorageChange);
    return () => window.removeEventListener('storageShowtop20Likers', handleStorageChange);
  }, []);

  return (
    <>
      <nav className="navbar bgTransparent position-absolute bottom-0 start-0 w-100 py-1">
      {showtop20Likers && (
        <div className="w-100 mb-1">
          <Top20Likers showtop20Likers={showtop20Likers} />
        </div>
      )}
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            
            {/* زر Top 3 Gifters */}
            <button
              className="button-icon rotate-left mx-2"
              onClick={() => {
                setShowTop3Gifters((prev) => !prev); // ✅ تفعيل التبديل (Toggle)
                setShowSettings(false);
                setShowTop10Likers(false);
                setShowVotingPopup(false);
              }}
            >
              <span className="span-icon"><IoGift /></span>
            </button>

            {/* زر Top 10 Likers */}
            <button
              className="button-icon rotate-left mx-2"
              onClick={() => {
                setShowTop10Likers((prev) => !prev); // ✅ تفعيل التبديل (Toggle)
                setShowSettings(false);
                setShowTop3Gifters(false);
                setShowVotingPopup(false);
              }}
            >
              <span className="span-icon"><BsHearts /></span>
            </button>

          </div>

          <div className="d-flex justify-content-center">
          <div><span className="waterName water hide-text m-0 p-0">❤ InteractiveTok ❤</span><span className="waterName water hide-text m-0 p-0">❤ InteractiveTok ❤</span></div>
          </div>

          <div className="d-flex justify-content-between">

                        {/* زر التصويت */}
                        <button
              className="button-icon rotate-right mx-2"
              onClick={() => {
                setShowVotingPopup((prev) => !prev); // ✅ تفعيل التبديل (Toggle)
                setShowSettings(false);
                setShowTop10Likers(false);
                setShowTop3Gifters(false);
              }}
            >
              <span className="span-icon"><FaPollH /></span>
            </button>

            {/* زر الإعدادات */}
            <button
              className="button-icon rotate-right mx-2"
              onClick={() => {
                setShowSettings((prev) => !prev); // ✅ تفعيل التبديل (Toggle)
                setShowTop10Likers(false);
                setShowTop3Gifters(false);
                setShowVotingPopup(false);
              }}
            >
              <span className="span-icon"><IoMdSettings /></span>
            </button>

          </div>
        </div>
      </nav>

      {/* النوافذ المنبثقة */}
      {showSettings && <SettingsPopup onClose={() => setShowSettings(false)} />}
      {showTop10Likers && <Top10Likers onClose={() => setShowTop10Likers(false)} />}
      {showTop3Gifters && <Top3Gifters onClose={() => setShowTop3Gifters(false)} />}
      {showVotingPopup && <VotingPopup onClose={() => setShowVotingPopup(false)} />}
    </>
  );
};

export default Footer;
