import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSound } from '../contexts/SoundContext';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';
import { useTeams } from '../contexts/TeamsContext';

const TeamsLeaderboard = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const { playSound } = useSound();
  const { t } = useTranslation();
  const animationContainer = useRef(null);
  const [winningTeam, setWinningTeam] = useState(null);
  const [topThree, setTopThree] = useState([]);
  const [remainingLeaders, setRemainingLeaders] = useState([]);

  useEffect(() => {
    const fetchLeaderboard = () => {
      const storedMembers = JSON.parse(localStorage.getItem("membersTeams")) || [];

      if (!storedMembers.length) {
        console.warn("⚠️ No data found in localStorage for membersTeams!");
        return;
      }

      let redTeamCorrect = 0, blueTeamCorrect = 0;
      const redTeam = [], blueTeam = [];

      storedMembers.forEach(member => {
        const correctAnswers = member.correctQuestions ? member.correctQuestions.filter(q => q === "true").length : 0;

        if (member.team === "red") {
          redTeamCorrect += correctAnswers;
          redTeam.push({ ...member, correctAnswers });
        } else if (member.team === "blue") {
          blueTeamCorrect += correctAnswers;
          blueTeam.push({ ...member, correctAnswers });
        }
      });

      // تحديد الفريق الفائز
      setWinningTeam(redTeamCorrect > blueTeamCorrect ? "red" : "blue");

      // ترتيب الأعضاء حسب عدد الإجابات الصحيحة
      const sortedWinners = (winningTeam === "red" ? redTeam : blueTeam).sort((a, b) => b.correctAnswers - a.correctAnswers);

      setTopThree(sortedWinners.slice(0, 3));
      setRemainingLeaders(sortedWinners.slice(3));

      playSound("cheering");
    };

    fetchLeaderboard();
  }, [playSound, winningTeam]);

  const crownImages = [
    "/img/crown-gold.gif",   // 🥇 الأول (Gold) - وسط
    "/img/crown-silver.gif", // 🥈 الثاني (Silver) - يسار
    "/img/crown-bronze.gif"  // 🥉 الثالث (Bronze) - يمين
  ];

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch('/images/Animation.json');
        const animationData = await response.json();

        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData,
        });
      } catch (error) {
        console.error("⚠️ Error loading Lottie animation:", error);
      }
    };

    fetchAnimationData();

    return () => {
      lottie.destroy();
    };
  }, []);



  const { clearMembers } = useTeams(); // ✅ جلب دالة التصفير
  
  const handleNext = () => {
    const storageKey = `quiz_${userId}`;
  
    localStorage.setItem('membersTeams', '');
    localStorage.setItem(storageKey, '');
  
    // ✅ تحديث حالة الفريقين فورًا بدون Refresh
    clearMembers();
  
    // ✅ الانتقال إلى صفحة الإعدادات
    navigate(`/${userId}/teamschallenge/teamssettings`);
  };
  
  
  

  return (
    <div className="max-width-300">
      {/* ✅ معلومات الفريق الفائز */}
      <div className="mb-1 d-flex justify-content-around text-center align-items-center">
        {winningTeam ? (
          <h5 className={`fw-bold mb-0 ${winningTeam === "red" ? "text-danger" : "text-primary"}`}>
            {winningTeam === "red" ? "الفريق الأحمر" : "الفريق الأزرق"}
          </h5>
        ) : (
          <h3 className="text-warning">{t('no_team_won')}</h3>
        )}
        <button
            className="fancy button"
            onClick={handleNext}
            style={{ width: '40%', height: '30px' }}
          >
            <h6 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
              {t('start_new_challenge')}
            </h6>
          </button>
      </div>

      {/* ✅ أنيميشن الاحتفال */}
      <div className="celebration" ref={animationContainer}></div>

      {/* ✅ عرض أفضل 3 لاعبين */}
      <TopThreeLeaders leaders={topThree} crownImages={crownImages} totalQuestions={totalQuestions} />

      {/* ✅ عرض بقية اللاعبين مع إمكانية التمرير */}
      <RemainingLeaders leaders={remainingLeaders} totalQuestions={totalQuestions} />

    </div>
  );
};

const TopThreeLeaders = ({ leaders, crownImages, totalQuestions }) => {
  const [showTop3, setShowTop3] = useState(false);
  const borderStyles = ["gold-border", "silver-border", "bronze-border"];
  const top3Colors = ["gold", "silver", "#cd7f32"];

  useEffect(() => {
    setShowTop3(true);
  }, []);

  return (
    <div className={`row justify-content-center mb-3 podium ${showTop3 ? "show" : ""}`}>
      {leaders.map((leader, index) => (
        <div key={index} className={`p-1 col-md-4 col-sm-4 col-4 text-center position-relative podium-position position-${index + 1}`}>
          <img className="crown" src={crownImages[index]} alt={`Crown ${index + 1}`} />
          <img src={leader.profilePictureUrl} alt="Profile" className={`profile-pic mb-1 ${borderStyles[index]}`} style={{ boxShadow: `0 0 10px ${top3Colors[index]}` }} /> 
          <h6 className='mb-0' style={{ color: top3Colors[index] }}>{leader.nickname}</h6>
          <h6 className='mb-0 fontcolorWhite'>{leader.correctAnswers}/{totalQuestions}</h6>
        </div>
      ))}
    </div>
  );
};

const RemainingLeaders = ({ leaders, totalQuestions }) => {
  const [showRest, setShowRest] = useState(false);
  const [lng, setLng] = useState('ar'); // ✅ تعيين قيمة افتراضية

  useEffect(() => {
    setLng(localStorage.getItem('language') || 'ar'); // ✅ جلب اللغة من localStorage
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRest(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  return (
    showRest && (
        <ul className="bgTransparent leaderboard-list-teams row justify-content-center slow-motion custom-scrollbar rounded-3 p-1">
          {leaders.map((leader, index) => (
            <li key={index + 3} className="d-flex align-items-center mb-1 px-1 pb-1 border-bottom border-white">
              <h5 className={`col-md-1 col-sm-1 col-1 fw-bold mb-0 ${lng === 'ar' ? 'text-end' : 'text-start'}`}>{index + 4}</h5>
              <img src={leader.profilePictureUrl} alt="Profile" className="col-md-2 col-sm-2 col-2 rest-profile-pic" />
              <h6 className="col-md-7 col-sm-7 col-7 text-center mb-0 flex-grow-1">{leader.nickname}</h6>
              <h5 className="col-md-2 col-sm-2 col-2 mb-0 fontcolorWhite">{leader.correctAnswers}/{totalQuestions}</h5>

            </li>
          ))}
        </ul>
    )
  );
};

export default TeamsLeaderboard;
