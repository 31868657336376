// BrainChallenge.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StartGame from './StartGame';
import Settings from './Settings';
import Question from './Question';
import Result from './Result';
import Leaderboard from './Leaderboard';

const BrainChallenge = () => {
  return (
    <Routes>
      <Route path="startgame" element={<StartGame />} />
      <Route path="settings" element={<Settings />} />
      <Route path="question" element={<Question />} />
      <Route path="result" element={<Result />} />
      <Route path="leaderboard" element={<Leaderboard />} />
    </Routes>
  );
};

export default BrainChallenge;
