import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';


const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/users/${userId}`); // ✅ تمرير userId داخل الـ URL
        setUserData(response.data);
      } catch (error) {
        console.error("❌ خطأ أثناء جلب بيانات المستخدم:", error);
      }
    };

    if (userId) { // ✅ التحقق من وجود userId قبل تنفيذ الطلب
      fetchUserData();
    }
  }, [userId]); // ✅ التأكد من تنفيذ الطلب عند تغير userId


  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    return format(date, ' yy.MM.dd'); // Customize the format as needed
  };

  return (
    <div className="container" style={{ maxWidth: '450px' }}>
      {userData ? (
        <>
          <div id="dash-table" className="container text-center bgTransparent rounded-3 p-1 mb-1" >
            <div className="row p-0">
              <h5 className="col p-0 fontcolorWhite">{t('username')}</h5>
              <h5 className="col p-0 fontcolorYellow">{userData.username}</h5>
            </div>
          </div>
          <div className="container text-center bgTransparent rounded-3 p-1">
            <div className="row align-middle">
              <h6 className="col p-0 fontcolorWhite">{t('status')}</h6>
              <h6 className="col p-0 fontcolorYellow">
                <span className={`badge ${userData.active ? 'bg-success px-3 py-1' : 'bg-warning px-3 py-1'}`}>
                  {userData.active ? t('active') : t('inactive')}
                </span>
              </h6>
              <h6 className="col p-0 fontcolorWhite">{t('active_until')}</h6>
              <h6 className="col p-0 fontcolorYellow">{formatDateTime(userData.active_until)}</h6>
            </div>
          </div>
          {userData.active ? (
            <div className="games text-center mt-3">
              <div className="row justify-content-around gap-2 p-2">
                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/brainchallenge/startgame`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('brain_challenge')}</h5>
                    </div>
                  </Link>
                </div>

                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/teamschallenge/teamsstartgame`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('brain_challenge_teams')}</h5>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row justify-content-around gap-2 p-2">
                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/millionchallenge/startgamemillion`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('brain_challenge_million')}</h5>
                    </div>
                  </Link>
                </div>

                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/eyechallenge/eyestartgame`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('eye_challenge')}</h5>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row justify-content-around gap-2 p-2">
                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/memchallenge/memstartgame`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('memory_challenge')}</h5>
                    </div>
                  </Link>
                </div>

                <div className="col bgTransparent shadow rounded-3 py-1 borderYellow">
                  <Link
                    to={`/${userId}/differencechallenge/differencestartgame`}
                    state={{ username: userData.username }}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="py-1 px-0">
                      <h5 className="fontcolorYellow">{t('difference_challenge')}</h5>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="games text-center mt-3">
              <div className="subscribe text-center bg-warning p-2 fontcolorWhite col-10 col.sm-10 col-md-6 mx-auto rounded-3">
                <span>{t('subscribe_message')}</span>
              </div>
              <div className="mt-5 tooltip-container animate__animated animate__jackInTheBox animate__delay-1s">
                <div className="tooltip">
                  <div className="profile">
                    <div className="user">
                      <div className="imgTelegram"></div>
                      <div className="details">
                        <div className="name">InteractiveTok.com</div>
                        <div className="username">@minds121</div>
                      </div>
                    </div>
                    <div className="about">تواصل معنا على تيليجرام</div>
                  </div>
                </div>
                <div className="text">
                  <a
                    className="icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/minds121"
                  >
                    <div className="layer">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span className="telegramSVG d-flex justify-content-center align-items-center">
                        <svg
                          fill="white"
                          className="svgIcon"
                          viewBox="0 0 496 512"
                          height="1.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM361.8,163.9,310,369.5c-3.7,14.8-13.4,18.4-27.2,11.5L203,328.5l-31.4,30.2c-3.5,3.5-6.5,6.5-13.4,6.5l4.8-69.1L317.5,191c5.3-4.7-1.2-7.3-8.1-2.6L145.9,284.6l-63-19.7c-13.7-4.3-14.1-13.3,2.9-19.6L344.6,133C356.9,128.5,367.7,136.7,361.8,163.9Z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div className="text">Telegram</div>
                  </a>
                </div>
                {/* <div className="tooltip">
                  <div className="profile">
                    <div className="user">
                      <div className="imgTik"></div>
                      <div className="details">
                        <div className="name">InteractiveTok</div>
                        <div className="username">interactivetok.com@</div>
                      </div>
                    </div>
                    <div className="about">Followers +10 K</div>
                  </div>
                </div>
                <div className="text">
                  <a className="icon" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@interactivetok.com">
                    <div className="layer">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span className="tiktokSVG">
                        <svg
                          fill="white"
                          className="svgIcon"
                          viewBox="0 0 448 512"
                          height="1.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div className="text">Tiktok</div>
                  </a>
                </div> */}
              </div>
            </div>
          )}
        </>
      ) : (
        <p>جار التحميل</p>
      )}
    </div>
  );
};

export default Dashboard;
