// src/components/InstallPrompt.js
import React from 'react';

const InstallPrompt = ({ onInstall, onClose }) => {
  return (
    <div className='button-download p-1 d-flex align-items-center'>

      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        <button 
          onClick={onInstall}
          style={{
            padding: '2px 4px',
            background: '#4CAF50',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            fontSize: '14px',
            cursor: 'pointer'
          }}>
          تثبيت التطبيق
        </button>
        <button 
          onClick={onClose}
          style={{
            padding: '2px 4px',
            background: '#f44336',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            fontSize: '14px',
            cursor: 'pointer'
          }}>
          إغلاق
        </button>
      </div>
    </div>
  );
};

export default InstallPrompt;
