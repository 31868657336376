// BrainChallenge.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import StartGameMillion from './StartGameMillion';
import SettingsMillion from './SettingsMillion';
import QuestionMillion from './QuestionMillion';

const MillionChallenge = () => {
  return (
    <Routes>
      <Route path='/startgamemillion' element={<StartGameMillion />} />
      <Route path='/settingsmillion' element={<SettingsMillion />} />
      <Route path='/questionmillion' element={<QuestionMillion />} />
    </Routes>
  );
};

export default MillionChallenge;
