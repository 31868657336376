import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSound } from './SoundContext';
import Top3GiftersRow from '../Game/Top3GiftersRow';
import { getSocket } from '../../utils/socketInstance';


const defaultAvatarUrl = [
  "/images/brain.jpg",
];

const CameraContext = createContext({
  cameraMode: 'single',
  setCameraMode: () => {},
  activeNotification: null,
  showTop3GiftersRow: true,
  ownerAvatar: ''
});

export const useCamera = () => {
  const context = useContext(CameraContext);
  if (!context) {
    console.warn("⚠️ useCamera must be used within a CameraProvider");
    return {
      cameraMode: 'single',
      setCameraMode: () => {},
      activeNotification: null,
      showTop3GiftersRow: true,
      ownerAvatar: ''
    };
  }
  return context;
};

export const CameraProvider = ({ children }) => {
  const [cameraMode, setCameraMode] = useState(() => localStorage.getItem('cameraMode') || 'single');
  const [ownerAvatar, setOwnerAvatar] = useState(localStorage.getItem('hostPic') || defaultAvatarUrl);
  const [showTop3GiftersRow, setShowTop3GiftersRow] = useState(() => JSON.parse(localStorage.getItem('showTop3GiftersRow')) ?? true);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [activeNotification, setActiveNotification] = useState(null);
  const { playSound } = useSound();

  
  useEffect(() => {
    getSocket(); // التأكد من أن WebSocket متصل

    // تحديث `hostPic` عند التغيير
    const updateHostPic = () => {
      setOwnerAvatar(localStorage.getItem('hostPic') || '');
    };

    window.addEventListener("storagehostPic", updateHostPic);

    return () => {
      window.removeEventListener("storagehostPic", updateHostPic);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setShowTop3GiftersRow(JSON.parse(localStorage.getItem('showTop3GiftersRow')) ?? true);
    };

    window.addEventListener('storageShowTop3GiftersRow', handleStorageChange);
    return () => window.removeEventListener('storageShowTop3GiftersRow', handleStorageChange);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const queue = JSON.parse(localStorage.getItem('notificationQueue')) || [];
      setNotificationQueue(queue);
    };

    window.addEventListener("storageNotificationQueue", handleStorageChange);
    return () => window.removeEventListener("storageNotificationQueue", handleStorageChange);
  }, []);

  useEffect(() => {
    if (!activeNotification && notificationQueue.length > 0) {
      const nextNotification = notificationQueue.shift();
      setActiveNotification(nextNotification);
      localStorage.setItem('notificationQueue', JSON.stringify(notificationQueue));

      setTimeout(() => {
        setActiveNotification(null);
      }, 5000);

      // play nextNotification.type sound ''
      setTimeout(() => {
        playSound(nextNotification.type);
      }, 50); // تأخير بسيط للصوت بعد البوب أب
    }
  }, [notificationQueue, activeNotification, playSound]);

  return (
    <CameraContext.Provider value={{
      cameraMode,
      setCameraMode,
      ownerAvatar,
      showTop3GiftersRow,
      activeNotification
    }}>
      {children}
    </CameraContext.Provider>
  );
};

export const CameraView = () => {
  const { cameraMode, ownerAvatar, activeNotification, showTop3GiftersRow } = useCamera();

  return (
    <div className="mt-2 col-12 row">
      {cameraMode === 'single' ? (
        <div id="cam1" className="fancycam1 col-8 mx-auto"
          style={{
            backgroundImage: `url(${ownerAvatar})`,
            backgroundSize: '80px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}>
          {activeNotification && (
            <div className="popup-likes-overlay cam1-popup">
              <div className="popup-likes-content">
                <div className="d-flex justify-content-around align-items-center w-100">
                  <img src={activeNotification.profilePictureUrl} alt={activeNotification.nickname} className="popup-likes-image" />
                  <div>
                    <h6>{activeNotification.nickname}</h6>
                    <p className="m-0">
                      {activeNotification.type === 'likes' && 'شكرا على 200 لايك'}
                      {activeNotification.type === 'follow' && 'الكبير بلش يتابعنا'}
                      {activeNotification.type === 'gift' && 'شكرا يا كبير'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center gap-2 position-relative">
          <div className="fancycam2 position-relative col-6"></div>
          <div id="cam2" className="fancycam2 position-relative col-6"
            style={{
              backgroundImage: `url(${ownerAvatar})`,
              backgroundSize: '80px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}>
            {activeNotification && (
              <div className="popup-likes-overlay cam2-popup">
                <div className="popup-likes-content">
                  <div className="d-flex justify-content-around align-items-center w-100">
                    <img src={activeNotification.profilePictureUrl} alt={activeNotification.nickname} className="popup-likes-image" />
                    <div>
                      <h6>{activeNotification.nickname}</h6>
                      <p className="m-0">
                        {activeNotification.type === 'likes' && 'شكرا على 200 لايك'}
                        {activeNotification.type === 'follow' && 'الكبير بلش يتابعنا'}
                        {activeNotification.type === 'gift' && 'شكرا يا كبير'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* ✅ رجّعت الداعمين الثلاثة، يا عيني عليك 😂 */}
      {showTop3GiftersRow && (
        <div className="w-100 mb-1">
          <Top3GiftersRow showTop3GiftersRow={showTop3GiftersRow} />
        </div>
      )}
    </div>
  );
};
