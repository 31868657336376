import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { format } from 'date-fns';


const API_URL = process.env.REACT_APP_API_URL;

const EarnDashboard = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [balance, setBalance] = useState(0);
  const userId = localStorage.getItem("userId");

  const fetchUsers = useCallback(async () => {
    try {
      const res = await axios.get(`${API_URL}/api/users/referrals/${userId}`);
      
      // console.log("📌 بيانات الإحالات القادمة من API:", res.data);
      
      setUsers(res.data.referrals);  // ✅ تحديث قائمة الإحالات
      setBalance(res.data.balance);  // ✅ تحديث الرصيد الصحيح
  
    } catch (error) {
      console.error("❌ خطأ أثناء جلب المستخدمين:", error);
    }
  }, [userId]);  
  

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      await axios.post(`${API_URL}/api/users/createWithMonth`, { ...newUser, referrer_id: userId });
      setMessage({ type: "success", text: "✅ تم إنشاء المستخدم بشهر اكتيف!" });
      setTimeout(() => setMessage(null), 3000);
      setNewUser({ username: "", password: "" });
      fetchUsers();
    } catch (error) {
      setMessage({ type: "error", text: "❌ فشل في إنشاء المستخدم" });
      setTimeout(() => setMessage(null), 3000);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateActive = async (userId, action) => {
    try {
      const res = await axios.put(`${API_URL}/api/users/updateActive/${userId}`, { action });
      setMessage({ type: "success", text: res.data.message });
      setTimeout(() => setMessage(null), 3000);

      fetchUsers();
    } catch (error) {
      setMessage({ type: "error", text: "❌ فشل في تحديث الاكتيف" });
      setTimeout(() => setMessage(null), 3000);

      console.error(error);
    }
  };

    const formatDateTime = (isoString) => {
      const date = new Date(isoString);
      return format(date, ' yyyy.MM.dd'); // Customize the format as needed
    };

  return (
    <div className="container mt-3" style={{ maxWidth: "500px", margin: "0 auto" }}>
      <h4 className="text-center mb-3">رصيدك: ${balance.toFixed(2)}</h4>

      {message && (
        <div className={`alert ${message.type === "success" ? "alert-success" : "alert-danger"}`}>
          {message.text}
        </div>
      )}

      <form onSubmit={handleCreateUser} className="mb-4">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <label className="col-4 form-label fontcolorWhite">اسم المستخدم:</label>
          <input type="text" className="form-control" value={newUser.username} onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} required />
        </div>

        <div className="mb-3 d-flex align-items-center justify-content-between">
          <label className="col-4 form-label fontcolorWhite">كلمة المرور:</label>
          <input type="password" className="form-control" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} required />
        </div>

        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? "جارٍ الإنشاء..." : "إنشاء"}
        </button>
      </form>

      <h3 className="text-center mb-2">إحالاتي</h3>
      <div className="custom-scrollbar" style={{ maxHeight: "350px", overflowY: "auto", display: "block" }}>
        <table className="table table-striped text-center">
          <thead style={{ position: "sticky", top: "0" }}>
            <tr>
              <th>اسم المستخدم</th>
              <th>الحالة</th>
              <th>نشط حتى</th>
              <th>التحكم (شهر)</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.active ? "✅" : "❌"}</td>
                <td>{user.active_until ? formatDateTime(user.active_until) : "غير مفعل"}</td>
                <td className="d-flex justify-content-center align-items-center gap-2">
                  <button 
                    onClick={() => handleUpdateActive(user.id, "add")}
                    className="btn btn-success btn-sm"
                  >
                    ➕
                  </button>
                  <button 
                    onClick={() => handleUpdateActive(user.id, "remove")}
                    className="btn btn-danger btn-sm"
                  >
                    ➖
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EarnDashboard;
