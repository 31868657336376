import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbPlayerTrackNextFilled, MdLeaderboard } from '../../utils/icons';

const TeamsResult = () => {
  const { userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentQuestionIndex = parseInt(queryParams.get('currentQuestionIndex'), 10);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const timer = parseInt(queryParams.get('timer'), 10) || 0;
  const hashtag = queryParams.get('hashtag') || '';
  const level = queryParams.get('level');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [redTeam, setRedTeam] = useState([]);
  const [blueTeam, setBlueTeam] = useState([]);
  const [activeTeam, setActiveTeam] = useState('red'); // ✅ بدء العرض على الفريق الأحمر
  const [activeTab, setActiveTab] = useState('correct'); // ✅ بدء العرض على الصحيحة
  const [topRed, setTopRed] = useState(null);
  const [topBlue, setTopBlue] = useState(null);

  const handleNext = useCallback(() => {
    if (currentQuestionIndex + 1 < totalQuestions) {
      navigate(`/${userId}/teamschallenge/teamsquestion?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&currentQuestionIndex=${currentQuestionIndex + 1}&level=${level}`);
    } else {
      navigate(`/${userId}/teamschallenge/teamsleaderboard?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&level=${level}`);
    }
  }, [navigate, userId, hashtag, totalQuestions, timer, currentQuestionIndex, level]);

  useEffect(() => {
    const fetchTeamsData = () => {
      const storedMembers = JSON.parse(localStorage.getItem('membersTeams')) || [];
      const sortedRed = storedMembers.filter(member => member.team === 'red').sort((a, b) => b.totalPoints - a.totalPoints);
      const sortedBlue = storedMembers.filter(member => member.team === 'blue').sort((a, b) => b.totalPoints - a.totalPoints);

      setRedTeam(sortedRed);
      setBlueTeam(sortedBlue);

      setTopRed(sortedRed.length > 0 ? sortedRed[0] : null);
      setTopBlue(sortedBlue.length > 0 ? sortedBlue[0] : null);
    };

    fetchTeamsData();
  }, []);

  const renderTeamResults = (teamMembers) => {
    const correctAnswers = teamMembers.filter(member => member.currentQuestion === "true");
    const wrongAnswers = teamMembers.filter(member => member.currentQuestion === "false");


    return (
      <div className="">
        <div className={`d-flex justify-content-around p-1 rounded border ${activeTeam === 'red' ? 'border-danger' : 'border-primary'}`}>
          {/* ✅ أزرار الفرق */}
          <button
            className={`btn p-1 ${activeTeam === 'red' ? 'btn-danger' : 'btn-outline-danger'}`}
            onClick={() => setActiveTeam('red')}
          >
            <div className="text-center">
              {topRed && (
                <>
                  <img src={topRed.profilePictureUrl} alt="Top Red" className="rounded-circle" width="30" />
                </>
              )}
            </div>
          </button>

          {/* ✅ أزرار الإجابات */}
          <button
            className={`btn p-1 fw-bold ${activeTab === 'correct' ? 'btn-success' : 'btn-outline-success'}`}
            onClick={() => setActiveTab('correct')}
          >
            ✔ ({correctAnswers.length})
          </button>
          <button
            className={`btn p-1 fw-bold ${activeTab === 'wrong' ? 'btn-danger' : 'btn-outline-danger'}`}
            onClick={() => setActiveTab('wrong')}
          >
            X ({wrongAnswers.length})
          </button>

          <button
            className={`btn p-1 ${activeTeam === 'blue' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => setActiveTeam('blue')}
          >
            <div className="text-center">
              {topBlue && (
                <>
                  <img src={topBlue.profilePictureUrl} alt="Top Blue" className="rounded-circle" width="30" />
                </>
              )}
            </div>
          </button>
        </div>
        {/* ✅ زر الانتقال للسؤال التالي */}
        <div className="d-flex justify-content-around my-1 p-1 bgTransparent rounded-3 mx-auto">
          <p className="m-0 align-self-center fontcolorYellow fw-bold">
            {t('question')} : {currentQuestionIndex + 1} / {totalQuestions}
          </p>
          <button onClick={handleNext} className="button-icon rotate-left" style={{ width: '50px' }}>
            <span className="span-icon">{currentQuestionIndex + 1 < totalQuestions ? <TbPlayerTrackNextFilled /> : <MdLeaderboard />}
            </span>
          </button>
        </div>
        {/* ✅ محتوى الـ Tabs مع Scroll */}
        <div className="tab-content mt-1 custom-scrollbar-result slow-motion" >
          {activeTab === 'correct' && (
            <div className="p-1 rounded border border-success slow-motion">
              <div className="row mx-0">
                {correctAnswers.map((member, index) => (
                  <div key={index} className="col-4 col-sm-4 col-md-4 p-1">
                    <div className="text-center mb-1">
                      <img src={member.profilePictureUrl} alt="Profile" className="rounded-circle mb-1" width="40" />
                      <h6 className='fontSize-12-10 mb-1'>{member.nickname}</h6>
                      <h6 className="fontSize-12-10 d-flex justify-content-center">
                        <span className="rounded text-white" style={{ backgroundColor: "green", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "true").length || 0}
                        </span>
                        <span className="rounded text-white mx-1" style={{ backgroundColor: "gray", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "not").length || 0}
                        </span>
                        <span className="rounded text-white" style={{ backgroundColor: "red", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "false").length || 0}
                        </span>
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'wrong' && (
            <div className="p-1 rounded border border-danger slow-motion">
              <div className="row mx-0">
                {wrongAnswers.map((member, index) => (
                  <div key={index} className="col-4 col-sm-4 col-md-4 p-1">
                    <div className="text-center mb-1">
                      <img src={member.profilePictureUrl} alt="Profile" className="rounded-circle mb-1" width="40" />
                      <h6 className='fontSize-12-10 mb-1'>{member.nickname}</h6>
                      <h6 className="fontSize-12-10 d-flex justify-content-between">
                        <span className="rounded text-white" style={{ backgroundColor: "green", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "true").length || 0}
                        </span>
                        <span className="rounded text-white" style={{ backgroundColor: "gray", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "not").length || 0}
                        </span>
                        <span className="rounded text-white" style={{ backgroundColor: "red", padding: '1px 5px' }}>
                          {member.correctQuestions?.filter(q => q === "false").length || 0}
                        </span>
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="">

      {/* ✅ تبديل بين نتائج الفرق */}
      <div className="tab-content mt-1">
        {activeTeam === 'red' && renderTeamResults(redTeam)}
        {activeTeam === 'blue' && renderTeamResults(blueTeam)}
      </div>
    </div>
  );
};

export default TeamsResult;
