import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ParticlesBackground from "./ParticlesBackground";

const API_URL = process.env.REACT_APP_API_URL;

const defaultAvatarUrl = "/images/brain.jpg";

const sliderGame = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false
};

const sliderGames = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 9000,
  responsive: [
    { breakpoint: 1024, settings: { slidesToShow: 2 } },
    { breakpoint: 600, settings: { slidesToShow: 1 } }
  ]
};

const Home = () => {
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/tiktok/getAllUsersStatus`);
        if (Array.isArray(response.data) && Array.isArray(response.data[0])) {
          const rawUsers = response.data[0];
          const activeUsers = rawUsers.filter(user => user.active === 1);
  
          // ✅ ترتيب المستخدمين بحيث يكون المتصلون أولًا
          const sortedUsers = activeUsers.sort((a, b) => b.tik_conn - a.tik_conn);
  
          setUsers(sortedUsers);
        }
      } catch (error) {
        console.error("❌ خطأ في جلب المستخدمين:", error);
      }
    };
  
    fetchUsers();
    const interval = setInterval(fetchUsers, 5000);
    return () => clearInterval(interval);
  }, []);
  

  return (
    <div className="bg-gray-900 text-white min-h-screen mt-2">
      <ParticlesBackground />
      {/* تعريف الموقع */}
      <section className="hero-section d-flex align-items-center justify-content-center text-center position-relative"
      style={{ minHeight: "50vh" }}>
      
      {/* تأثير الإضاءة المتوهجة */}
      <div className="glow-background"></div>

      {/* المحتوى */}
      <motion.div 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.2 }}
        className="container position-relative z-2"
      >
        <motion.h1 
          className="display-3 fw-bold text-white text-glow mb-3 animate__animated animate__fadeInDown"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          {t("home_welcome")}
        </motion.h1>

        <motion.h2 
          className="display-4 fw-bold text-warning text-glow animate__animated animate__pulse mb-3"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          {t("home_welcome_2")}
        </motion.h2>

        <motion.p 
          className="lead mx-auto animate__animated animate__fadeInUp"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {t("home_description")}
        </motion.p>
      </motion.div>
    </section>

      {/* سلايدر الألعاب */}
      <section className="container mx-auto mb-4 mt-1 px-5 borderYellow">
        <Slider {...sliderGames}>
          {challenges.map((challenge, index) => (
            <div key={index} className="px-4 py-2 text-center">
              <h2 className="text-xl font-semibold text-yellow-400 text-center">{t(challenge.title)}</h2>
              <p className="text-center mt-2">{t(challenge.description)}</p>
              <Slider {...sliderGame}>
                {challenge.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={t(challenge.title)} className="w-75 h-60 object-cover rounded-lg" />
                ))}
              </Slider>

            </div>
          ))}
        </Slider>
      </section>

{/* قسم الربح */}
{/* <section className="px-4 py-5 bgTransparent">
  <div className="row align-items-center justify-content-center">
    <div className="col-auto text-center mb-4 mb-md-0">
      <h2 className="text-warning fw-bold display-5">{t("home_earn")}</h2>
      <p className="lead mt-3">{t("home_earn_details")}</p>
    </div>

    <div className="col-auto text-center">
      <img
        src="/images/earn.webp"
        alt="Earn"
        className="img-fluid rounded shadow"
        style={{ maxWidth: "200px" }}
      />
    </div>
  </div>
</section> */}

      {/* قسم المستخدمين النشطين */}
      <section className="container mx-auto px-2 my-3"> 
        <h2 className="text-center text-yellow-400 text-2xl font-bold mb-2">{t("home_active_users")}</h2>
        {users.length === 0 ? (
          <p className="text-center mt-4 text-gray-300">{t("home_no_active_users")}</p>
        ) : (
          <div className="bgTransparent container-game" style={{ height: "460px", minHeight: "460px", maxWidth: "100%" }}>
            <div className="row custom-scrollbar-result slow-motion p-2 m-auto" style={{ maxHeight: "450px" }}>
              {users.map((user, index) => (
                <div key={user.username || index} className="col-md-2 col-sm-6 col-6 mb-2 p-1">
                  <div className="text-center shadow bgTransparent rounded-3 p-2">
                    <a href={`https://www.tiktok.com/@${user.username}/live`} target="_blank" rel="noopener noreferrer">
                      <img
                        src={user.avatarUrl || defaultAvatarUrl}
                        className="card-img-top img-fluid"
                        alt={user.username}
                        style={{ height: "70px", width: "70px", objectFit: "cover", borderRadius: "50%" }}
                      />
                      <div className="card-body">
                        <h5 className="card-title text-truncate">{user.nickname || user.username}</h5>
                        <p className={`fw-bold mb-0 ${user.tik_conn === 1 ? "text-success" : "text-danger"}`}>
                          {user.tik_conn === 1 ? "🟢 متصل" : "🔴 غير متصل"}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>

      {/* قسم الشراء */}
      {/* <section className="text-center py-10 bg-gray-800 mt-3">
        <h2 className="text-2xl font-bold text-yellow-400">{t("home_subscription")}</h2>
        <p className="mt-2 text-lg">{t("home_subscription_details")}</p>
        <div className={`tooltip-container animate__animated`}>
          <div className="tooltip">
            <div className="profile">
              <div className="user">
                <div className="imgTik"></div>
                <div className="details">
                  <div className="name">InteractiveTok</div>
                  <div className="username">interactivetok.com@</div>
                </div>
              </div>
              <div className="about">Followers +10 K</div>
            </div>
          </div>
          <div className="text">
            <a className="icon" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@interactivetok.com">
              <div className="layer">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className="tiktokSVG">
                  <svg
                    fill="white"
                    className="svgIcon"
                    viewBox="0 0 448 512"
                    height="1.5em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="text">Tiktok</div>
            </a>
          </div>
        </div>
        <p>{t("home_contact_tiktok")}</p>
      </section> */}

      {/* قسم الشراء */}
<section className="text-center py-10 bg-gray-800 mt-3">
  <h2 className="text-2xl font-bold text-yellow-400">{t("home_subscription")}</h2>
  
  <div className="tooltip-container animate__animated">
    <div className="tooltip">
      <div className="profile">
        <div className="user">
          <div className="imgTelegram"></div>
          <div className="details">
            <div className="name">InteractiveTok.com</div>
            <div className="username">@minds121</div>
          </div>
        </div>
        <div className="about">تواصل معنا على تيليجرام</div>
      </div>
    </div>
    <div className="text">
      <a 
        className="icon" 
        target="_blank" 
        rel="noopener noreferrer" 
        href="https://t.me/minds121"
      >
        <div className="layer">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span className="telegramSVG d-flex justify-content-center align-items-center">
            <svg
              fill="white"
              className="svgIcon"
              viewBox="0 0 496 512"
              height="1.5em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM361.8,163.9,310,369.5c-3.7,14.8-13.4,18.4-27.2,11.5L203,328.5l-31.4,30.2c-3.5,3.5-6.5,6.5-13.4,6.5l4.8-69.1L317.5,191c5.3-4.7-1.2-7.3-8.1-2.6L145.9,284.6l-63-19.7c-13.7-4.3-14.1-13.3,2.9-19.6L344.6,133C356.9,128.5,367.7,136.7,361.8,163.9Z"
              ></path>
            </svg>
          </span>
        </div>
        <div className="text">Telegram</div>
      </a>
    </div>
  </div>
  
  <p>{t("home_contact_telegram")}</p>
</section>



      {/* قسم التذييل */}
      <footer className="footer py-4 bgTransparent">
        <div className="container text-center">
          <p className="mb-0">{t("all_rights_reserved")} <a href="https://interactivetok.com" className="fontcolorYellow">© 2025 InteractiveTok</a>  </p>
        </div>
      </footer>
    </div>
  );
};

const challenges = [
  { title: "home_brain_challenge", description: "home_brain_challenge_desc", images: ["/images/bc1.webp", "/images/bc2.webp", "/images/bc3.webp"] },
  { title: "home_teams_challenge", description: "home_teams_challenge_desc", images: ["/images/bct1.webp", "/images/bct2.webp", "/images/bct3.webp"] },
  { title: "home_million_challenge", description: "home_million_challenge_desc", images: ["/images/bc1.webp", "/images/bc2.webp", "/images/bc3.webp"] },
  { title: "home_vision_challenge", description: "home_vision_challenge_desc", images: ["/images/bc1.webp", "/images/bc2.webp", "/images/bc3.webp"] },
  { title: "home_memory_challenge", description: "home_memory_challenge_desc", images: ["/images/bc1.webp", "/images/bc2.webp", "/images/bc3.webp"] },
  { title: "home_difference_challenge", description: "home_difference_challenge_desc", images: ["/images/bc1.webp", "/images/bc2.webp", "/images/bc3.webp"] }
];

export default Home;
