import React, { 
  createContext, 
  useContext, 
  useState, 
  useEffect, 
  useCallback, 
  useMemo 
} from 'react';

// القيمة الافتراضية للـ Context
const SoundContext = createContext({
  selectedMusic: 'backgroundAudio',
  setSelectedMusic: () => {},
  playBackground: () => {},
  pauseBackground: () => {},
  playSound: () => {},
  activateBgAudio: () => {},
  activateFxAudio: () => {},
  bgVolume: 100,
  setBgVolume: () => {},
  fxVolume: 100,
  setFxVolume: () => {},
});

// Hook للوصول للـ SoundContext
export const useSound = () => {
  const context = useContext(SoundContext);
  if (!context) {
    console.warn("⚠️ useSound must be used within a SoundProvider");
    return {
      selectedMusic: 'backgroundAudio',
      setSelectedMusic: () => {},
      playBackground: () => {},
      pauseBackground: () => {},
      playSound: () => {},
      activateBgAudio: () => {},
      activateFxAudio: () => {},
      bgVolume: 100,
      setBgVolume: () => {},
      fxVolume: 100,
      setFxVolume: () => {},
    };
  }
  return context;
};

export const SoundProvider = ({ children }) => {
  // حجم الصوت للخلفيات والتأثيرات
  const [bgVolume, setBgVolume] = useState(() => parseInt(localStorage.getItem('bgVolume')) || 0);
  const [fxVolume, setFxVolume] = useState(() => parseInt(localStorage.getItem('fxVolume')) || 0);  
  
  const [selectedMusic, setSelectedMusic] = useState('backgroundAudio');
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  // كائن لتحديد عوامل التقسيم لكل ملف صوتي
  const volumeScaling = useMemo(() => ({
    backgroundAudio: 100,
    backgroundAudio2: 400,
    backgroundAudio3: 100,
    backgroundAudio4: 100,
    backgroundAudio5: 100,
    backgroundAudio6: 100,
    cheering: 100,
    timer: 100,
    swing: 100,
    likes: 400,
    follow: 100,
    gift: 100,
  }), []);

  // إنشاء ملفات الصوت باستخدام useMemo
  const audioFiles = useMemo(() => ({
    backgroundAudio: new Audio('/sounds/million.mp3'),
    backgroundAudio2: new Audio('/sounds/alex.mp3'),
    backgroundAudio3: new Audio('/sounds/adventure.mp3'),
    backgroundAudio4: new Audio('/sounds/cooking.mp3'),
    backgroundAudio5: new Audio('/sounds/monkeys.mp3'),
    backgroundAudio6: new Audio('/sounds/wallpaper.mp3'),
    cheering: new Audio('/sounds/cheering.mp3'),
    timer: new Audio('/sounds/timer.mp3'),
    swing: new Audio('/sounds/swing.mp3'),
    likes: new Audio('/sounds/likes.wav'),
    follow: new Audio('/sounds/follow.wav'),
    gift: new Audio('/sounds/gift.mp3'),
  }), []);

  // تحديث مستويات الصوت عند تغيير bgVolume أو fxVolume
  useEffect(() => {
    localStorage.setItem('bgVolume', bgVolume);
    localStorage.setItem('fxVolume', fxVolume);
  
    if (!audioFiles) return;
    
    // تحديث أصوات الخلفية
    audioFiles.backgroundAudio.volume   = bgVolume / volumeScaling.backgroundAudio;
    audioFiles.backgroundAudio2.volume  = bgVolume / volumeScaling.backgroundAudio2;
    audioFiles.backgroundAudio3.volume  = bgVolume / volumeScaling.backgroundAudio3;
    audioFiles.backgroundAudio4.volume  = bgVolume / volumeScaling.backgroundAudio4;
    audioFiles.backgroundAudio5.volume  = bgVolume / volumeScaling.backgroundAudio5;
    audioFiles.backgroundAudio6.volume  = bgVolume / volumeScaling.backgroundAudio6;
    
    // تحديث أصوات التأثيرات
    audioFiles.cheering.volume  = fxVolume / volumeScaling.cheering;
    audioFiles.timer.volume     = fxVolume / volumeScaling.timer;
    audioFiles.swing.volume     = fxVolume / volumeScaling.swing;
    audioFiles.likes.volume     = fxVolume / volumeScaling.likes;
    audioFiles.follow.volume    = fxVolume / volumeScaling.follow;
    audioFiles.gift.volume      = fxVolume / volumeScaling.gift;
  }, [bgVolume, fxVolume, audioFiles, volumeScaling]);
  
  // دالتين لتفعيل الصوت (واحدة للخلفيات وأخرى للتأثيرات)
  const activateBgAudio = useCallback(() => {
    setIsUserInteracted(true);
  }, []);

  const activateFxAudio = useCallback(() => {
    setIsUserInteracted(true);
  }, []);

  // تشغيل أي صوت عند الطلب
  const playSound = useCallback((soundKey) => {
    if (!isUserInteracted || !audioFiles[soundKey]) return;
  
    try {
      const sound = audioFiles[soundKey];
      const backgroundSounds = [
        'backgroundAudio', 
        'backgroundAudio2', 
        'backgroundAudio3', 
        'backgroundAudio4', 
        'backgroundAudio5', 
        'backgroundAudio6'
      ];
      if (backgroundSounds.includes(soundKey) && currentAudio === sound && !sound.paused) {
        console.log(`🔄 الخلفية الصوتية (${soundKey}) تعمل بالفعل.`);
        return;
      }
  
      // استخدام bgVolume إذا كان الصوت خلفية، أو fxVolume إذا كان تأثير
      const volumeValue = backgroundSounds.includes(soundKey) ? bgVolume : fxVolume;
      sound.volume = volumeValue / volumeScaling[soundKey];
      sound.currentTime = 0; // إعادة تشغيل الصوت من البداية
      sound.play();
    } catch (error) {
      console.error(`🔴 خطأ في تشغيل الصوت (${soundKey}):`, error);
    }
  }, [isUserInteracted, audioFiles, bgVolume, fxVolume, currentAudio, volumeScaling]);

  // تشغيل الصوت الخلفي (loop)
  const playBackground = useCallback((audioKey) => {
    if (!isUserInteracted || !audioFiles[audioKey]) return;
  
    if (currentAudio === audioFiles[audioKey] && !currentAudio.paused) {
      console.log(`🔄 الخلفية الصوتية (${audioKey}) تعمل بالفعل.`);
      return;
    }
  
    if (currentAudio && currentAudio !== audioFiles[audioKey]) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
  
    setCurrentAudio(audioFiles[audioKey]);
  
    try {
      audioFiles[audioKey].loop = true;
      audioFiles[audioKey].volume = bgVolume / volumeScaling[audioKey];
      audioFiles[audioKey].play();
    } catch (error) {
      console.error('🔴 خطأ في تشغيل الصوت:', error);
    }
  }, [isUserInteracted, currentAudio, audioFiles, bgVolume, volumeScaling]);

  // إيقاف الصوت الخلفي
  const pauseBackground = useCallback(() => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }
  }, [currentAudio]);

  // مراقبة تغييرات bgVolume للتحكم بتشغيل الصوت الخلفي
  useEffect(() => {
    if (currentAudio) {
      // تحديث مستوى الصوت باستخدام العامل المناسب؛ إذا لم يوجد عامل نستخدم 100 كافتراضي
      currentAudio.volume = bgVolume / (volumeScaling[selectedMusic] || 100);
    }
  
    if (bgVolume > 0 && !currentAudio) {
      playBackground(selectedMusic);
    } else if (bgVolume === 0) {
      pauseBackground();
    }
  }, [bgVolume, currentAudio, playBackground, pauseBackground, selectedMusic, volumeScaling]);

  return (
    <SoundContext.Provider
      value={{
        selectedMusic,
        setSelectedMusic,
        playBackground,
        pauseBackground,
        playSound,
        activateBgAudio,
        activateFxAudio,
        bgVolume,
        setBgVolume,
        fxVolume,
        setFxVolume,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export default SoundContext;
