import React from 'react';
import { useTeams } from '../contexts/TeamsContext';

const TeamsJoinPopup = ({ requests, onAccept, onReject }) => {
  const { addMember, removeMember } = useTeams();

  // console.log("📢 TeamsJoinPopup received requests:", requests); // ✅ تحقق من استقبال الطلبات

  if (requests.length === 0) {
    // console.log("⚠️ No join requests, hiding popup.");
    return null;
  }

  return (
    <div className="popup-overlay">
      {requests.map(user => (
        <div key={user.userId} className="popup-content-wrapper py-2 px-3">
          <img src={user.profilePictureUrl} alt={user.nickname} width="50" height="50" style={{ borderRadius: '50%' }} />
          <p className="mb-1">{user.nickname}</p>
          <div className="d-flex">
            <button 
              onClick={() => {
                removeMember(user.userId); 
                addMember(user, 'red'); 
                onAccept(user, 'red');
              }} 
              className="button-icon rotate-left"
              style={{ width: '50px', border: '1px solid red', marginBottom: '5px' }}>
              <span className="span-icon">أحمر</span>
            </button>
            <button 
              onClick={() => {
                removeMember(user.userId); 
                onReject(user);
              }}
              className="mx-2 button-icon"
              style={{ width: '50px' }}>
              <span className="span-icon">رفض</span>
            </button>
            <button 
              onClick={() => {
                removeMember(user.userId);
                addMember(user, 'blue'); 
                onAccept(user, 'blue');
              }} 
              className="button-icon rotate-right"
              style={{ width: '50px', border: '1px solid blue', marginBottom: '5px' }}>
              <span className="span-icon">أزرق</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamsJoinPopup;
