import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaHome, FaTimes, FaSignOutAlt, HiRefresh, FaUserShield, FaSackDollar } from '../../utils/icons';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../Main/LanguageSwitcher';


const API_URL = process.env.REACT_APP_API_URL;

const GameHeader = ({ handleLogout }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [connectionStatus, setConnectionStatus] = useState(localStorage.getItem('connectionStatus') || 'disconnected');
  const userId = localStorage.getItem('userId');
  const isAdmin = localStorage.getItem("is_admin") === "1";
  const username = localStorage.getItem('username');
  const lng = i18n.language === 'ar' ? 'ar' : 'en';

  const isAutoMode = new URLSearchParams(window.location.search).get('isAutoMode') === 'true';

  useEffect(() => {
    const updateStatus = () => {
      setConnectionStatus(localStorage.getItem('connectionStatus') || 'disconnected');
    };

    updateStatus(); // ✅ تحديث الحالة فورًا عند تحميل الصفحة

    window.addEventListener("tiktokStatusUpdate", updateStatus);

    // ✅ الاستماع إلى إعادة الاتصال التلقائي من الباك إند
    const handleReconnect = () => {
      console.log("🔄 تم إعادة الاتصال تلقائيًا! سيتم تحديث الصفحة...");
      setTimeout(() => window.location.reload(), 1000);
    };

    window.socket?.on("tiktokReconnected", handleReconnect);

    return () => {
      window.removeEventListener("tiktokStatusUpdate", updateStatus);
      window.socket?.off("tiktokReconnected", handleReconnect);
    };
  }, []);



  const handleConnect = async () => {
    if (connectionStatus === 'connected') {
      console.log("⚠️ المستخدم متصل بالفعل.");
      return;
    }

    if (!userId || !username) {
      console.error("❌ userId أو username غير موجود في localStorage!", { userId, username });
      return;
    }

    setConnectionStatus('connecting');

    try {
      console.log("🔍 محاولة الاتصال...", { userId, username });

      const response = await axios.post(`${API_URL}/api/tiktok/start`, { userId, username });

      console.log("✅ تم الاتصال بنجاح!", response.data);

      localStorage.setItem('top20Likers', JSON.stringify([]));
      localStorage.setItem('topGifters', JSON.stringify({}));
      localStorage.setItem('userGifters', JSON.stringify({}));
      localStorage.setItem('topLikers', JSON.stringify({}));
      localStorage.setItem('userLikes', JSON.stringify({}));

      // 3️⃣ جلب بيانات المستخدم بعد الاتصال
      const userResponse = await axios.get(`${API_URL}/api/tiktok/status/${userId}`);

      if (userResponse.data.ownerAvatarUrl) {
        const oldAvatar = localStorage.getItem('hostPic');

        if (oldAvatar !== userResponse.data.ownerAvatarUrl) {
          console.log("📸 تحديث الصورة الجديدة:", userResponse.data.ownerAvatarUrl);
          localStorage.setItem('hostPic', userResponse.data.ownerAvatarUrl);
          window.dispatchEvent(new Event("storagehostPic")); // 🔄 إرسال إشعار لتحديث المكونات
        }
      }

      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error("❌ خطأ أثناء الاتصال بـ TikTok:", error.response ? error.response.data : error);
      setConnectionStatus('error');
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleDisconnect = async () => {
    if (connectionStatus === 'disconnected') {
      console.log("⚠️ المستخدم غير متصل بالفعل.");
      return;
    }

    if (!userId) {
      console.error("❌ userId غير موجود في localStorage!");
      return;
    }

    try {
      console.log("🔴 محاولة قطع الاتصال...");
      await axios.post(`${API_URL}/api/tiktok/stop`, { userId });
      setConnectionStatus('disconnected');
    } catch (error) {
      console.error("❌ خطأ أثناء قطع الاتصال بـ TikTok:", error);
      setConnectionStatus('error');
    }
  };

  const handleHome = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAutoMode = urlParams.get('isAutoMode');
    if (isAutoMode === 'true') {
      urlParams.set('isAutoMode', 'false');
      window.location.search = urlParams.toString();
    }
    navigate(`/${userId}/dashboard`);
  };

  const handleAdminPanel = () => {
    if (isAdmin) {
      navigate("/admin");
    } else if (userId === "26") {
      navigate("/earn");
    }
  };


  return (
    <header className="sticky-top w-100">
      <nav className="navbar bgTransparent">
        <div className="container-fluid">
          {/* أزرار التنقل */}
          <div className="d-flex">
            <button
              onClick={handleHome}
              id="start"
              className={`button-icon rotate-left ${isAutoMode ? 'bg-success' : ''}`}
            >
              <span className="span-icon"><FaHome /></span>
            </button>
            <button onClick={handleLogout} className="button-icon rotate-left mx-2"><span className="span-icon"><FaSignOutAlt /></span></button>
            {/* زر الأدمن يظهر فقط للمستخدمين الإداريين */}
            {(isAdmin || userId === "26") && (
              <button
                onClick={handleAdminPanel}
                className="button-icon rotate-left ms-2 btn-warning"
                title="لوحة التحكم"
              >
                <span className="span-icon">{isAdmin ? <FaUserShield /> : <FaSackDollar />}</span>
              </button>
            )}

            <LanguageSwitcher lng={lng} />
          </div>

          {/* أزرار الاتصال */}
          <div className="d-flex">

            <button onClick={handleDisconnect} className="button-icon rotate-right">
              <span className="span-icon"><FaTimes /></span>
            </button>
            <button className="button-icon rotate-right mx-2" onClick={handleRefresh}>
              <span className="span-icon"><HiRefresh /></span>
            </button>
            <button
              onClick={connectionStatus === 'connected' ? handleDisconnect : handleConnect}
              className={`button-icon btn rotate-right ${connectionStatus === 'connected' ? 'btn-success disabled' :
                connectionStatus === 'error' ? 'bg-warning' :
                  connectionStatus === 'connecting' ? 'bg-info' : // ✅ اجعل `connecting` أصفر
                    'btn-danger'
                }`}
              disabled={connectionStatus === 'connecting'}
            >
              {connectionStatus === 'connected' && <span className="span-icon-connected fw-bold">✔</span>}
              {connectionStatus === 'disconnected' && <span className="span-icon-connected fw-bold">✘</span>}
              {connectionStatus === 'error' && <span className="span-icon-connected fw-bold">☢</span>}
              {connectionStatus === 'connecting' && <span className="span-icon-connected fw-bold">∞</span>}
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default GameHeader;
