import React, { useState, useEffect } from 'react';

// تحميل الألوان المحفوظة من `localStorage` أو استخدام الافتراضية
const defaultColors = {
  primary: '#4d259b',
  border: '#390990',
  fancyback: '#37155a',
  fancycolor: '#ffb900',
  fontcolor: '#ffffff',
};

const ColorPicker = () => {
  // ✅ تحميل الألوان مباشرة من `localStorage` عند التشغيل
  const [colors, setColors] = useState(() => {
    return JSON.parse(localStorage.getItem('themeColors')) || defaultColors;
  });

  // ✅ تحديث الألوان على مستوى CSS عند تغييرها
  useEffect(() => {
    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}-color`, value);
    });
  }, [colors]);

  // ✅ تحديث `localStorage` عند تغيير الألوان
  const saveColors = () => {
    localStorage.setItem('themeColors', JSON.stringify(colors));
  };

  // ✅ إعادة الألوان إلى القيم الافتراضية
  const resetColors = () => {
    setColors(defaultColors);
    localStorage.setItem('themeColors', JSON.stringify(defaultColors));
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        {Object.entries(colors).map(([key, value]) => (
          <div key={key} className="text-center">
            <input
              type="color"
              className="color-input"
              value={value}
              onChange={(e) => setColors((prevColors) => ({ ...prevColors, [key]: e.target.value }))}
            />
          </div>
        ))}

        {/* زر الحفظ */}
        <button className="button-icon me-2" onClick={saveColors}>
          <span className="span-icon">💾</span>
        </button>

        {/* زر إعادة التعيين */}
        <button className="button-icon" onClick={resetColors}>
          <span className="span-icon">🔄</span>
        </button>
      </div>
    </div>
  );
};

export default ColorPicker;
