import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';
import { useTeams } from '../contexts/TeamsContext';

const SocketGiftsContext = createContext({
  showGifters: true,
  setShowGifters: () => {},
  resetTopGifters: () => {}
});

export const useSocketGifts = () => {
  const context = useContext(SocketGiftsContext);
  if (!context) {
    console.warn("⚠️ useSocketGifts must be used within a SocketGiftsProvider");
    return {
      showGifters: true,
      setShowGifters: () => {},
      resetTopGifters: () => {}
    };
  }
  return context;
};

export const SocketGiftsProvider = ({ children }) => {
  const { socket } = useSocket();
  const { updateGiftPoints } = useTeams();
  const [showGifters, setShowGifters] = useState(() => JSON.parse(localStorage.getItem('showGifters')) ?? true);

  useEffect(() => {
    localStorage.setItem('showGifters', JSON.stringify(showGifters));
  }, [showGifters]);

  useEffect(() => {
    if (!socket) return;
    const processedGifts = new Set();

    socket.on('gift', (data) => {
      if (processedGifts.has(data.giftMsgId)) return;
      processedGifts.add(data.giftMsgId);

      updateGiftPoints(data.userGiftId, data.diamondCount * data.repeatCount * 2000);

      const storedGifters = JSON.parse(localStorage.getItem('userGifters')) || {};
      const previousTotal = storedGifters[data.uniqueId]?.totalGiftCount || 0;
      const newGiftCount = previousTotal + (data.diamondCount * data.repeatCount);

      storedGifters[data.uniqueId] = {
        nickname: data.nickname,
        profilePictureUrl: data.profilePictureUrl,
        totalGiftCount: newGiftCount,
        giftsSinceLastPopup: (storedGifters[data.uniqueId]?.giftsSinceLastPopup || 0) + (data.diamondCount * data.repeatCount),
      };

      localStorage.setItem('userGifters', JSON.stringify(storedGifters));

      const sortedGifters = Object.values(storedGifters).sort((a, b) => b.totalGiftCount - a.totalGiftCount);

      localStorage.setItem('topGifters', JSON.stringify(sortedGifters.slice(0, 10)));
      window.dispatchEvent(new Event("storageTopGifters"));

      // ✅ إشعار عند تجاوز عدد معين من الهدايا
      if (showGifters && storedGifters[data.uniqueId].giftsSinceLastPopup >= 1) {
        const notificationQueue = JSON.parse(localStorage.getItem('notificationQueue')) || [];
        notificationQueue.push({
          nickname: data.nickname,
          profilePictureUrl: data.profilePictureUrl,
          type: 'gift',
        });
        localStorage.setItem('notificationQueue', JSON.stringify(notificationQueue));
        window.dispatchEvent(new Event("storageNotificationQueue"));
        storedGifters[data.uniqueId].giftsSinceLastPopup = 0;
      }

      localStorage.setItem('userGifters', JSON.stringify(storedGifters));
    });

    return () => {
      socket.off('gift');
    };
  }, [socket, showGifters, updateGiftPoints]);

  const resetTopGifters = () => {
    localStorage.removeItem('userGifters');
    localStorage.removeItem('topGifters');
    console.log("🔄 Reset top gifters.");
  };

  return (
    <SocketGiftsContext.Provider value={{
      showGifters,
      setShowGifters,
      resetTopGifters
    }}>
      {children}
    </SocketGiftsContext.Provider>
  );
};

export default SocketGiftsContext;
