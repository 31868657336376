import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AskAudience from '../MillionChallenge/AskAudience';
import { useSound } from '../contexts/SoundContext';
import { BsFillTelephoneFill, FaPeopleGroup, HiOutlineSwitchHorizontal, FaPercent } from '../../utils/icons';


const QuestionMillion = () => {
  const { userId } = useParams();
  const lng = localStorage.getItem('language');
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [usedHelp, setUsedHelp] = useState({
    callFriend: false,
    askAudience: false,
    switchQuestion: false,
    fiftyFifty: false,
  });
  const [helpPopup, setHelpPopup] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showLostPopup, setShowLostPopup] = useState(false);
  const [showWinPopup, setShowWinPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const totalQuestions = 15;
  const earningsTable = [
    '100€', '200€', '300€', '500€', '1,000€',
    '2,000€', '4,000€', '8,000€', '16,000€', '32,000€',
    '64,000€', '125,000€', '250,000€', '500,000€', '1,000,000€'
  ];
  const [showAudienceHelp, setShowAudienceHelp] = useState(false);
  const correctSound = useRef(new Audio('/sounds/correct.mp3'));
  const wrongSound = useRef(new Audio('/sounds/wrong.mp3'));
  const winSound = useRef(new Audio('/sounds/cheering.mp3'));
  const { playSound } = useSound();


  // Fetch questions from localStorage
  useEffect(() => {
    const fetchQuestions = () => {


      const levels = {
        firstlevelUp: [],
        secondlevelUp: [],
        thirdlevelUp: [],
      };

      // Fetch and shuffle questions for each level
      Object.keys(levels).forEach((level) => {
        const levelQuestions = Object.values(JSON.parse(localStorage.getItem(level) || '{}'));
        levels[level] = levelQuestions.sort(() => Math.random() - 0.5);
      });

      // Flatten levels into a single array
      const allQuestions = [
        ...levels.firstlevelUp.slice(0, 5), // First 5 questions
        ...levels.secondlevelUp.slice(0, 5), // Next 5 questions
        ...levels.thirdlevelUp.slice(0, 5), // Last 5 questions
      ];

      // Check if we have enough questions
      if (allQuestions.length < totalQuestions) {
        console.warn(`Only ${allQuestions.length} questions available. Expected: ${totalQuestions}`);
      }

      setQuestions(allQuestions);
      setLoading(false);
    };

    fetchQuestions();
  }, []);


  useEffect(() => {
    // Set the volume levels
    correctSound.current.volume = 0.5; // Correct sound at 50% volume
    wrongSound.current.volume = 0.5;   // Wrong sound at 50% volume
    winSound.current.volume = 0.7;     // Win sound at 70% volume
  }, []);



  useEffect(() => {
    if (questions.length > 0 && questions[currentQuestionIndex]) {
      // console.log('Current question index:', currentQuestionIndex);
      // console.log('Current question:', questions[currentQuestionIndex].question);
      console.log('correct answer:', questions[currentQuestionIndex].correct)
    }
  }, [questions, currentQuestionIndex]);


  const handleAnswerSelection = (answer) => {
    setSelectedAnswer(answer);

    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = currentQuestion.correct === answer;

    if (loading || !currentQuestion) {
      return <p>Loading...</p>;
    }

    if (isCorrect) {
      correctSound.current.play();
      if (currentQuestionIndex + 1 >= questions.length) {
        winSound.current.play();
        setShowWinPopup(true); // Show "You Won!" popup directly
      } else {
        // If it's not the last question, show correct answer popup
        setTimeout(() => {
          setShowCorrectPopup(true); // Show success popup
        }, 3000);
      }
    } else {
      wrongSound.current.play();
      setTimeout(() => {
        setShowLostPopup(true); // Show "You Lost!" popup
      }, 5000);
    }

    // Remove the current question from localStorage
    const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
    levels.forEach((level) => {
      const levelQuestions = JSON.parse(localStorage.getItem(level) || '{}');

      // Filter out the current question by both id and question text
      const updatedQuestions = Object.fromEntries(
        Object.entries(levelQuestions).filter(
          ([, value]) =>
            value.id !== currentQuestion.id || value.question !== currentQuestion.question
        )
      );

      localStorage.setItem(level, JSON.stringify(updatedQuestions));
    });
  };


  const handleHelpClick = (helpType) => {
    if (usedHelp[helpType]) return;

    setUsedHelp((prev) => ({ ...prev, [helpType]: true }));

    if (helpType === 'askAudience') {
      setShowAudienceHelp(true);
    }


    if (helpType === 'callFriend') {
      const correctAnswer = questions[currentQuestionIndex].correct;

      // Map answer keys to numbers
      const answerKeyToNumber = {
        first: '١', // Arabic for 1
        second: '٢', // Arabic for 2
        third: '٣', // Arabic for 3
        fourth: '٤', // Arabic for 4
      };

      // Get all the wrong options
      const wrongOptions = ['first', 'second', 'third', 'fourth'].filter(
        (key) => questions[currentQuestionIndex][key] !== correctAnswer
      );

      // Randomly decide if the friend will give the correct or a wrong answer
      const isCorrect = Math.random() > 0.5;

      // Select the friend's answer key: correct or one of the wrong options
      const answerKey = isCorrect
        ? Object.keys(answerKeyToNumber).find(
          (key) => questions[currentQuestionIndex][key] === correctAnswer
        )
        : wrongOptions[Math.floor(Math.random() * wrongOptions.length)];

      // Randomly decide the certainty level
      const certainty = [100, 90, 80, 70, 60, 50][Math.floor(Math.random() * 6)];

      // Update the helpPopup state with plain text
      setHelpPopup({
        answerNumber: answerKeyToNumber[answerKey],
        certainty,
      });
    }


    if (helpType === 'fiftyFifty') {
      const correct = questions[currentQuestionIndex].correct;
      const wrongOptions = ['first', 'second', 'third', 'fourth'].filter(
        (key) => questions[currentQuestionIndex][key] !== correct
      );

      // Randomly select two wrong answers to remove
      const removedOptions = wrongOptions.sort(() => Math.random() - 0.5).slice(0, 2);

      // Update the questions state to disable the removed answers
      setQuestions((prev) =>
        prev.map((q, i) =>
          i === currentQuestionIndex
            ? {
              ...q,
              disabledOptions: removedOptions, // Store removed options for the current question
            }
            : q
        )
      );
    }


    if (helpType === 'switchQuestion') {
      setQuestions((prev) => prev.filter((_, i) => i !== currentQuestionIndex));
    }
  };

  const handleAudienceHelpFinish = (votes) => {
    // setAudienceVotes(votes); // Store the final votes
    setShowAudienceHelp(false);
  };


  const handleNextQuestion = () => {
    setShowCorrectPopup(false); // Close the popup
    setSelectedAnswer(null); // Reset selected answer

    if (currentQuestionIndex + 1 >= questions.length) {
      winSound.current.play();
      setShowCorrectPopup(false);
      setShowWinPopup(true);
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1); // Update the current question index
    }
  };

  const handleLostPopupClose = () => {
    setShowLostPopup(false);
    navigate(`/${userId}/millionchallenge/settingsmillion`);
  };

  const handleWinPopupClose = () => {
    setShowWinPopup(false);
    navigate(`/${userId}/millionchallenge/settingsmillion`);
  };

  const currentQuestion = questions[currentQuestionIndex];

  const questionOptions = currentQuestion
    ? ['first', 'second', 'third', 'fourth'].map((key) => currentQuestion[key])
    : [];

  useEffect(() => {
    if (questions.length > 0 && questions[currentQuestionIndex]) {
      setTimeout(() => {
        playSound('swing');
      }, 1000);
    }
  }, [questions, currentQuestionIndex, playSound]);




  if (loading || !currentQuestion) {
    return <p>Loading...</p>;
  }

  return (
    <div className="text-center ">
      <div className="d-flex justify-content-evenly max-width-300">
        <button onClick={() => handleHelpClick('callFriend')} disabled={usedHelp.callFriend} className="button-icon rotate-left">
          <span className="span-icon"><BsFillTelephoneFill /></span>
        </button>
        <button onClick={() => handleHelpClick('askAudience')} disabled={usedHelp.askAudience} className="button-icon rotate-left">
          <span className="span-icon"><FaPeopleGroup /></span>
        </button>
        <button onClick={() => handleHelpClick('switchQuestion')} disabled={usedHelp.switchQuestion} className="button-icon rotate-right">
          <span className="span-icon"><HiOutlineSwitchHorizontal /></span>
        </button>
        <button onClick={() => handleHelpClick('fiftyFifty')} disabled={usedHelp.fiftyFifty} className="button-icon rotate-right">
          <span className="span-icon"><FaPercent /></span>
        </button>
      </div>
      <div className=" py-2 rounded">
        <div>
          <div className="fancy ques" id="ques">
            {lng === 'en' ? (
              <h5 className="quesText">{currentQuestion.question}</h5>
            ) : (
              <h5 className="quesText">{currentQuestion.question}</h5>
            )}
          </div>
          <div className="ques-box">
            <div className="fancy million_earning d-flex justify-content-center">
              <span className="numbertime text-center">{earningsTable[currentQuestionIndex]}</span>
            </div>
            <div className="fancy quesnum fw-bold d-flex justify-content-center">
              <span className="numberQues text-center">{t('question')} : {currentQuestionIndex + 1} / {totalQuestions}</span>
            </div>
          </div>
        </div>
        <div className="answers mt-3">
          {['first', 'second', 'third', 'fourth'].map((key, index) => (
            <button
              key={`${currentQuestion.id}-${key}`} // Unique key ensures React identifies updates
              className={`btn answer mb-0 next-question-button ${selectedAnswer === currentQuestion[key]
                ? selectedAnswer === currentQuestion.correct
                  ? 'btn-success bg-success borderYellow'
                  : 'btn-danger borderYellow'
                : selectedAnswer && currentQuestion.correct === currentQuestion[key]
                  ? 'btn-success bg-success borderYellow'
                  : 'btn-secondary borderYellow'
                } ${index === 0 || index === 2 // Add fade-in for first and third
                  ? 'fade-in'
                  : 'fade-out' // Add fade-out for second and fourth
                }`}
              onClick={() => handleAnswerSelection(currentQuestion[key])}
              disabled={
                selectedAnswer !== null || // Disable all buttons after an answer is selected
                currentQuestion.disabledOptions?.includes(key) // Disable removed options from fiftyFifty
              }
            >
              <span className="row align-items-center justify-content-start">
                <span className="col-2">{index + 1} | </span> {currentQuestion[key]}
              </span>
            </button>
          ))}
        </div>

      </div>
      {helpPopup && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders">
            <div className="popup-header">
              <h2>مساعدة</h2>
            </div>
            <div className="popup-body">
              <p>
                صديقك يعتقد أن الإجابة هي <strong className='fontcolorYellow'>رقم {helpPopup.answerNumber}</strong>
                <br />
                واثق بنسبة {helpPopup.certainty}%.
              </p>
            </div>
            <div className="popup-footer">
              <button
                className="fancy button next-question-button"
                onClick={() => setHelpPopup(null)}
              >
                <h6 className='m-0 fontcolorWhite' style={{ position: 'relative', zIndex: 1 }}> X </h6>
              </button>
            </div>
          </div>
        </div>
      )}

      {showCorrectPopup && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders mt-5">
            <div className="popup-header">
              <h2>اجابة صحيحة</h2>
              <p><strong>رصيدك:</strong> {earningsTable[currentQuestionIndex]}</p>
            </div>
            <div className="popup-footer">
              <button
                className="fancy button next-question-button"
                onClick={handleNextQuestion}
              >
                <h6 className='m-0 fontcolorWhite' style={{ position: 'relative', zIndex: 1 }}>السؤال التالي </h6>
              </button>
            </div>
          </div>
        </div>
      )}
      {showLostPopup && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders">
            <div className="popup-header">
              <h2>لقد خسرت</h2>
            </div>
            <div className="popup-footer">
              <button
                className="fancy button next-question-button"
                onClick={handleLostPopupClose}
              >
                <h6 className='m-0 fontcolorWhite' style={{ position: 'relative', zIndex: 1 }}>جولة جديدة </h6>
              </button>
            </div>
          </div>
        </div>
      )}
      {showWinPopup && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders">
            <div className="popup-header">
              <h2>لقد فزت</h2>
              <h3 className="fontcolorWhite">انت الفائز بمبلغ</h3>
              <h1><strong></strong> {earningsTable[currentQuestionIndex]}</h1>
            </div>
            <div className="popup-footer">
              <button
                className="fancy button next-question-button"
                onClick={handleWinPopupClose}
              >
                <h6 className='m-0 fontcolorWhite' style={{ position: 'relative', zIndex: 1 }}>جولة جديدة </h6>
              </button>
            </div>
          </div>
        </div>
      )}
      {showAudienceHelp && (
        <AskAudience
          onFinish={handleAudienceHelpFinish}
          hashtag="جواب"
          userId={userId}
          questionOptions={questionOptions}
          currentQuestion={currentQuestion.question}
        />
      )}

    </div>
  );
};

export default QuestionMillion;
