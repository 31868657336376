import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = useCallback(() => {
    const currentLang = i18n.language;
    const newLang = currentLang === "ar" ? "en" : "ar";

    if (currentLang !== newLang) {
      i18n.changeLanguage(newLang); // تغيير اللغة
      localStorage.setItem("language", newLang); // تحديث localStorage

      // تحديث اتجاه النص بناءً على اللغة
    //   document.body.classList.remove("rtl", "ltr");
    //   document.body.classList.add(newLang === "ar" ? "rtl" : "ltr");
    }
  }, [i18n]);

  return (
    <button onClick={toggleLanguage} className="button-icon rotate-left">
      <span className="span-icon fw-bold">{i18n.language === "ar" ? "EN" : "AR"}</span>
    </button>
  );
};

export default LanguageSwitcher;
