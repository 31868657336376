import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSound } from '../contexts/SoundContext';
import { useSocket } from '../contexts/SocketContext';
const DifferenceQuestion = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'));
  const currentQuestion = parseInt(queryParams.get('currentQuestion')) || 0;
  const timer = parseInt(queryParams.get('timer'));
  const { playSound } = useSound();
  const { t } = useTranslation();
  const hashtag = queryParams.get('hashtag');
  const isAutoMode = queryParams.get('isAutoMode') === 'true';
  const [comments, setComments] = useState([]);
  const { socket } = useSocket();
  const [grid, setGrid] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [differentChar, setDifferentChar] = useState('');
  const [correctPosition, setCorrectPosition] = useState('');
  const [correctComment, setCorrectComment] = useState('');
  const timerIdRef = useRef(null);
  const insertOnceRef = useRef(false);
  const storageKey = `dif_${userId}`;

  const rows = 14;
  const cols = 14;

  // ✅ تحويل الأرقام العربية إلى إنجليزية
  const convertArabicToEnglishNumbers = (str) => {
    const arabicToEnglishMap = {
      '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
      '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
    };
    return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
  };

  // ✅ استقبال التعليقات من `socket`
  useEffect(() => {
    if (!socket) return;

    const handleChat = (data) => {
      if (data.data.uniqueId === "interactivetok.com") {
        console.log(data.data.nickname, '🎉 You got khaled a chat!', data.data.msgId, data.data.comment);
      }
      const msgId = data?.data?.msgId;
      if (!msgId) return;

      let commentText = data.data.comment?.toLowerCase().trim();
      commentText = convertArabicToEnglishNumbers(commentText.replace(/\s+/g, '')); // ✅ تصفية البيانات

      if (!commentText.includes(hashtag.toLowerCase())) return;

      const newComment = {
        comment: commentText,
        userId: data.data.userId,
        nickname: data.data.nickname,
        profilePictureUrl: data.data.profilePictureUrl || '',
        followRole: data.data.followRole || 0,
      };

      setComments((prev) => [...prev, newComment]); // ✅ تحديث `comments`
    };

    socket.on('chat', handleChat);
    return () => {
      socket.off('chat', handleChat); // ✅ إيقاف الاستماع عند إزالة المكون
    };
  }, [socket, hashtag]);

  // ✅ تحديد التعليق الصحيح وتحويله إلى **أحرف صغيرة** + إزالة الفراغات
  useEffect(() => {
    if (hashtag && correctPosition) {
      const formattedCorrectComment = `${hashtag}${correctPosition}`.toLowerCase().replace(/\s+/g, '');
      setCorrectComment(formattedCorrectComment);
      console.log("✅ Correct Comment Set:", formattedCorrectComment);
    }
  }, [hashtag, correctPosition]);

  // ✅ إدخال البيانات إلى `localStorage` مع فلترة صحيحة
  const insertIntoLocalStorage = useCallback(() => {
    if (insertOnceRef.current) return;
    insertOnceRef.current = true;

    if (!correctComment || !correctPosition) {
      console.error("🚨 Missing correct comment or position!");
      return;
    }

    const existingData = JSON.parse(localStorage.getItem(storageKey) || '{}');

    console.log(`🔍 Saving question ${currentQuestion} with correct comment: ${correctComment}`);

    // ✅ حفظ أول تعليق لكل مستخدم يحتوي على الهاشتاغ
    const commentsData = comments.reduce((acc, comment) => {
      let cleanedComment = convertArabicToEnglishNumbers(comment.comment.toLowerCase().replace(/\s+/g, ''));

      if (cleanedComment.includes(hashtag.toLowerCase()) && !acc[comment.userId]) {
        const isCorrect = cleanedComment === correctComment;

        acc[comment.userId] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: comment.nickname,
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
      }
      return acc;
    }, {});

    const newEntry = {
      userId,
      hashtag,
      grid,
      currentQuestion,
      differentChar,
      correctPosition,
      correctComment,
      comments: commentsData,
    };

    existingData[currentQuestion] = newEntry;
    localStorage.setItem(storageKey, JSON.stringify(existingData));

    console.log(`✅ Stored Question ${currentQuestion}:`, newEntry);
  }, [comments, correctPosition, differentChar, grid, hashtag, userId, storageKey, currentQuestion, correctComment]);


  // ✅ إنشاء الشبكة وتحديد الحرف المختلف
  const generateGrid = useCallback(() => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const mainChar = alphabet[Math.floor(Math.random() * alphabet.length)];
    let diffChar;
    do {
      diffChar = alphabet[Math.floor(Math.random() * alphabet.length)];
    } while (diffChar === mainChar);

    const randomIndex = Math.floor(Math.random() * rows * cols);
    setDifferentChar(diffChar);

    const columnLetter = String.fromCharCode(65 + (randomIndex % cols));
    const rowNumber = Math.floor(randomIndex / cols) + 1;
    setCorrectPosition(`${columnLetter}${rowNumber}`.toLowerCase());

    const newGrid = Array.from({ length: rows * cols }, (_, index) =>
      index === randomIndex ? diffChar : mainChar
    );
    setGrid(newGrid);
    setTimeLeft(timer);
  }, [rows, cols, timer]);

  // ✅ إنهاء السؤال بعد انتهاء المؤقت
  const handleQuestionEnd = useCallback(() => {
    if (!correctComment || !correctPosition) {
      console.error('🚨 Missing correct comment or position, skipping storage!');
      return;
    }

    insertIntoLocalStorage();

    setTimeout(() => {
      navigate(
        `/${userId}/differencechallenge/differenceresult?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=${currentQuestion}&timer=${timer}&isAutoMode=${isAutoMode}`
      );
    }, 5000);
  }, [hashtag, insertIntoLocalStorage, navigate, timer, totalQuestions, userId, currentQuestion, isAutoMode, correctComment, correctPosition]);

  // ✅ تشغيل المؤقت
  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        handleQuestionEnd();
      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, handleQuestionEnd]);

  useEffect(() => {
    generateGrid();
  }, [generateGrid]);

  useEffect(() => {
    if (timeLeft === 10) {
      playSound('timer');
    }
  }, [timeLeft, playSound]);

  useEffect(() => {
    if (timeLeft === timer) {
      playSound('swing');
    }
  }, [timeLeft, playSound, timer]);

  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
        <div className="ques-box">
          <div className="fancy questime d-flex justify-content-center" style={{ height: '25px' }}>
            <span className="numbertime text-center">{timeLeft}</span>
          </div>
          <div className="fancy quesnum fw-bold d-flex justify-content-center" style={{ height: '25px' }}>
            <span className="numberQues text-center">{t('question')} : {currentQuestion} / {totalQuestions}</span>
          </div>
        </div>
      </div>
      <div className="bgTransparent rounded-3 mt-1 mb-1 text-center">
        <h6 className="info">{t('add_hashtag')}</h6>
        <h6 className="info">
          {t('hashtag_today')} : <span className="fontcolorWhite">{hashtag}</span>, {t('example_q')} :
          <span className="fontcolorWhite">{hashtag} k6</span>
        </h6>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        <div className="d-flex me-4">
          {Array.from({ length: cols }).map((_, index) => (
            <div key={`col-${index}`} className="d-flex align-items-center justify-content-center fontcolorWhite" style={{ width: '20px', height: '20px', margin: '1px', fontSize: '13px', fontWeight: 'bold' }}>
              {String.fromCharCode(65 + index)}
            </div>
          ))}
        </div>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className="d-flex">
            <div className="d-flex align-items-center justify-content-center fontcolorWhite" style={{ width: '20px', height: '20px', margin: '1px', fontSize: '13px', fontWeight: 'bold' }}>
              {rowIndex + 1}
            </div>
            {grid.slice(rowIndex * cols, (rowIndex + 1) * cols).map((char, colIndex) => (
              <div key={`${rowIndex}-${colIndex}`}
                className={`border border-dark d-flex align-items-center justify-content-center fontcolorYellow ${char === differentChar && timeLeft === 0 ? 'bg-success' : ''}`}
                style={{ width: '20px', height: '20px', margin: '1px', fontWeight: 'bold' }}>
                {char}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DifferenceQuestion;
